/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/core'

import { useActivity } from 'contexts/Activity'
import { colors, flexCenteredSpaceBetweenRow, flexHorizontallyCentered, flexRowCentered, H1, Modal } from '@bonitour/components'
import { margin, marginTop } from 'assets/styles/global'
import { ActivitySelector } from 'containers/Activity/Selector/Selector'
import VacancyTable from './Table/VacancyTable'
import { BookingMapButtons } from './Structure/BookingMapButtons'

import { useOffsetHook } from './hooks/useOffsetHook'
import { useBookingMapDateTime } from './hooks/useBookingMapDateTime'
import { useDateColumns } from './hooks/useDateColumns'
import { useMapSubscriber } from './hooks/useMapSubscriber'
import { useMapDetailSubscriber } from './hooks/useMapDetailSubscriber'
import { identity } from '@bonitour/common-functions'
import { BookingLinkageGuide } from './Table/Guides/BookingLinkageGuide'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { COMBINED_EXPERIENCE_TYPE, LIMBER_EXPERIENCE_TYPE } from 'constants/activityTypes'
import { ActionBar as CombinedExperienceActionBar, ServiceCombinedExperienceList } from 'domains/CombinedExperience/components'
import { BookingMapGuide } from './Structure/BookingMapGuide'
import { useBookingMapInfo } from './contexts/useBookingMapInfo'
import { limberSectionWrapper } from './BookingMap.styles'
import { LimberSection } from 'containers/Booking/Form/LimberSection/LimberSection'
import { SafeJSONParse } from 'utils/object'
import { useFeatureFlag } from 'contexts/Feature'
import { DEFAULT_DATE_LIMIT, DEFAULT_LIMBER_DATE_LIMIT } from './BookingMap'

const noMargin = margin(0, 0, 0, 0)

const marginTop20 = marginTop(20)

const marginTopN25 = marginTop(-25)

const title = css`
  align-items: flex-end;
  margin-top: -10px;
`

const vacancyTableContainer = css`
  padding: 30px;
  position: relative;
  border-radius: 10px;
  border: 1px solid ${colors.gray11};
  background-color: ${colors.white};
`

const selectorPadding = css`
  padding-left: 15px;
`

export const BookingMapModal = ({
  date = '',
  isVisible = false,
  onModalClose = identity,
  reservationCode = ''
}) => {
  const { id: serviceId, activity = {} } = useActivity()
  const {
    experiences = []
  } = activity

  const {
    loadFeePrices,
    isEnabledReservationMode,
    totalTickets,
    totalPrice,
    getInvalidActivities,
    selectedActivities,
    resetBookingMapInfo,
    getIsAllServicesInSameDay,
    getExperienceQueryParams
  } = useBookingMapInfo()

  const {
    isCombinedExperience,
    isLimber
  } = useMemo(() => ({
    isCombinedExperience: activity?.type === COMBINED_EXPERIENCE_TYPE,
    isLimber: activity?.type === LIMBER_EXPERIENCE_TYPE
  }), [activity.type])

  const hasLimberService = useMemo(
    () => isCombinedExperience && experiences.some(({ type }) => type === LIMBER_EXPERIENCE_TYPE),
    [experiences, isCombinedExperience]
  )

  const onCloseClick = useCallback(() => {
    if (isCombinedExperience) {
      resetBookingMapInfo()
    }
    onModalClose()
  }, [isCombinedExperience, onModalClose, resetBookingMapInfo])

  const [pickupPlaceId, setPickupPlaceId] = useState('')
  const [pickupPlaceIdByService, setPickupPlaceIdByService] = useState({})
  const changePickupPlaceIds = useCallback((pickupPlaceId, serviceId) => {
    setPickupPlaceIdByService(
      old => {
        return {
          ...old,
          [serviceId]: pickupPlaceId
        }
      }
    )
  }, [])

  const pickupPlaceIdByServiceIndex = useMemo(
    () => Object.fromEntries(
      Object.entries(pickupPlaceIdByService)
        .map(([serviceId, pickupPlaceId]) => [
          experiences.findIndex(({ serviceId: id }) => id === serviceId),
          pickupPlaceId
        ])
    ),
    [experiences, pickupPlaceIdByService]
  )

  const limberData = useMemo(() => isLimber && activity.description ? SafeJSONParse(activity.description).limber_data : {}, [isLimber, activity.description])

  const [_enabled, { vacancyMapDateLimit }, isFlagReady, isFlagTimedOut] = useFeatureFlag('orb-limber-integration')
  const limberDateLimit = useMemo(
    () => (!isFlagReady && !isFlagTimedOut) ? 0 : (vacancyMapDateLimit || DEFAULT_LIMBER_DATE_LIMIT),
    [isFlagReady, isFlagTimedOut, vacancyMapDateLimit]
  )
  const [tableReference, offset] = useOffsetHook(true, (isLimber || hasLimberService) ? limberDateLimit : DEFAULT_DATE_LIMIT)
  const { selectedHour, referenceDate, changeHour, changeToPrevious, changeToNext } = useBookingMapDateTime(offset, date || '')
  const dateColumns = useDateColumns(referenceDate, offset)
  const { registries, prices, activitiesRegistryAndPrice, isLoading, activitiesColors, debouncedDates } = useMapSubscriber(dateColumns, true, pickupPlaceId, pickupPlaceIdByService)
  const tableDetailedData = useMapDetailSubscriber(activity, dateColumns, selectedHour)
  const GuideComponent = useMemo(() => BookingLinkageGuide(serviceId, reservationCode, pickupPlaceId), [serviceId, reservationCode, pickupPlaceId])

  useEffect(() => {
    loadFeePrices({ initialFeePrices: activitiesRegistryAndPrice })
  }, [activitiesRegistryAndPrice, loadFeePrices])

  return (
    <Modal isVisible={isVisible} onCloseClick={onCloseClick}>
      <div css={[flexCenteredSpaceBetweenRow, marginTopN25]}>
        <div css={[flexRowCentered, title]}>
          <H1 css={noMargin}>Vincular</H1>
          <ActivitySelector
            css={selectorPadding}
            subdomain={null}
            onChangeActivity={isCombinedExperience ? resetBookingMapInfo : identity}
            disableOffline
          />
          <H1 css={noMargin}>na reserva {reservationCode}</H1>
        </div>
        {isLimber &&
          <div css={[flexHorizontallyCentered, limberSectionWrapper]}>
            <LimberSection
              css={noMargin}
              limberData={limberData}
              pickupPlaceId={pickupPlaceId}
              onChange={setPickupPlaceId}
              bookingMapStyle
            />
          </div>
        }
      </div>

      {isCombinedExperience
        ? (
          <ServiceCombinedExperienceList
            experiences={experiences}
            customCss={[marginTop20]}
            canShowTotals={true}
            pickupPlaceIdByService={pickupPlaceIdByService}
            changePickupPlaceId={changePickupPlaceIds}
          />
        )
        : null}

      <div css={vacancyTableContainer} ref={tableReference}>
        <BookingMapButtons
          onClickPrevious={changeToPrevious}
          onNextClick={changeToNext}
          offset={offset}
        />
        <VacancyTable
          isExpandable={false}
          date={referenceDate}
          dateColumns={dateColumns}
          debouncedDateColumns={debouncedDates}
          tableData={registries}
          tablePrices={prices}
          tableDetailedData={tableDetailedData}
          currentExpandedRowLabel={selectedHour}
          VacancySummaryPopoverContent={GuideComponent}
          onExpand={changeHour}
          activitiesRegistryAndPrice={activitiesRegistryAndPrice}
          isCombinedExperience={isCombinedExperience}
          isLoading={isLoading}
        />
        {isCombinedExperience ? <BookingMapGuide activitiesColors={activitiesColors} isCombinedExperience={true} /> : null}
      </div>
      <CombinedExperienceActionBar
        getInvalidActivities={getInvalidActivities}
        isEnabledReservationMode={isEnabledReservationMode}
        selectedActivities={selectedActivities}
        totalPaxSelected={totalTickets}
        totalPrice={totalPrice}
        isStaticLayout
        isCommonBookingMap={false}
        getIsAllServicesInSameDay={getIsAllServicesInSameDay}
        getExperienceQueryParams={getExperienceQueryParams}
        reservationCode={reservationCode}
        pickupPlaceIdByServiceIndex={pickupPlaceIdByServiceIndex}
      />
    </Modal>
  )
}

import { BREAK_POINTS } from '@bonitour/components'
import { css } from '@emotion/core'

export const container = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media screen and (max-width: ${BREAK_POINTS.bigPhone}) {
    flex-direction: column;
  }
`

export const timeContainer = css`
  display: flex;
  flex-direction: column;
  text-align: right;
  align-items: center;
  .bold {
    font-weight: bolder;
  }

  @media screen and (max-width: ${BREAK_POINTS.bigPhone}) {
    flex-direction: row;
    gap: 0.25rem;
  }
`

export const durationContainer = css`
  display: flex;
  padding: 0.625rem;
  i {
    margin-right: 0.15rem;
  }
  @media screen and (max-width: ${BREAK_POINTS.bigPhone}) {
    gap: 0.25rem;
  }
`

import {
  CompanyDashboard,
  CreateFee,
  EditFee,
  CreateActivity,
  CreateTransport,
  EditActivity,
  EditTransport,
  EditOfflineExperience,
  CreatePartnershipGroup,
  EditPartnershipGroup,
  PartnershipInvitation,
  EditInvoiceReceive,
  EditInvoicePay,
  Sellers,
  Reservations,
  Reservation,
  Logout,
  EditVacancy,
  CreateVacancy,
  Providers,
  SellersInvite
} from 'app'
import { InvoicesPay } from 'domains/Invoice/Lists/InvoicesPay'
import { InvoicesReceive } from 'domains/Invoice/Lists/InvoicesReceive'
import { ViewPartnershipGroup } from 'domains/Partnership/Group/ViewPartnershipGroup'
import { NotficationList } from 'domains/Notifications/NotificationList'
import { TicketCheckin } from 'domains/Ticket/CheckIn/TicketCheckIn'
import { BankAccounts } from 'domains/BankAccounts/BankAccounts'
import { CreateBankAccount } from 'domains/BankAccounts/Create/CreateBankAccount'
import { CreateAcquirer, EditAcquirer, AcquirersList } from 'domains/Acquirers'
import { CreateNotices } from 'domains/Notice/Create/CreateNotices'
import { NoticesList } from 'domains/Notice/List/NoticesList'
import ActivitiesPage from 'domains/Activity/ActivitiesPage'
import { CompanyReports, SalesList, VouchersList, PendingTicketsList, RevenueList } from 'domains/CompanyReports'
import { CreateCashierHandling } from 'domains/CashierHandling'
import { CashierList, CashierDetails } from 'domains/Cashier'
import { CashierHandlingList } from 'domains/CashierHandling/List/CashierHandlingList'
import { TourGuidePayment } from 'domains/TourGuidePayment/TourGuidePayment'
import { MyDay } from 'domains/MyDay'
import { PartnershipAccounts, PartnershipTransactions } from 'domains/PartnershipAccount'
import { ReservationBalance } from 'domains/Reservation/Balance/ReservationBalance'
import { EditBankAccount } from 'domains/BankAccounts/Edit/EditBankAccount'
import { ExternalProviders } from 'domains/ExternalProviders/ExternalProviders'
import { EditExternalProvider } from 'domains/ExternalProviders/Edit/EditExternalProvider'
import { CreateExternalProvider } from 'domains/ExternalProviders/Create/CreateExternalProvider'
import { CreateOfflineExperience } from 'app/CreateOfflineExperience/CreateOfflineExperience'
import { TouristPaymentsList } from 'domains/CompanyReports/TouristPayment'
import { InvoicesBatch } from 'domains/InvoiceBatch/InvoicesBatch'
import { InvoicesBatchCreate } from 'domains/InvoiceBatch/Create/CreateBatch'
import { InvoicesBatchShowEdit } from 'domains/InvoiceBatch/ShowEdit/ShowEditBatch'
import { TouristRefundsList } from 'domains/CompanyReports/TouristRefund/List/TouristRefundsList'
import { ReservationsVisualizedList } from 'domains/CompanyReports/Reservations/Visualized/ReservationsVisualizedList'
import { RegisterActivityWithFullForm } from 'domains/Integrations/RegisterActivityWithFullForm/RegisterActivityWithFullForm'

export const companyRoutes = [
  {
    path: '/dashboard',
    component: CompanyDashboard
  },
  {
    path: '/sellers/invite/:companyId',
    component: SellersInvite
  },
  {
    path: '/sellers',
    component: Sellers
  },
  {
    path: '/providers',
    component: Providers
  },
  {
    path: '/invoices-receive/:invoiceId/edit',
    component: EditInvoiceReceive
  },
  {
    path: '/invoices-pay/:invoiceId/edit',
    component: EditInvoicePay
  },
  {
    path: '/invoices-pay',
    component: InvoicesPay
  },
  {
    path: '/invoices-batch/:type/create',
    component: InvoicesBatchCreate
  },
  {
    path: '/invoices-batch/:type/:id',
    component: InvoicesBatchShowEdit
  },
  {
    path: '/invoices-batch/:type',
    component: InvoicesBatch
  },
  {
    path: '/invoices-receive',
    component: InvoicesReceive
  },
  {
    path: '/reports/pending-tickets',
    component: PendingTicketsList
  },
  {
    path: '/reports/sales',
    component: SalesList
  },
  {
    path: '/reports/vouchers',
    component: VouchersList
  },
  {
    path: '/reports/revenue',
    component: RevenueList
  },
  {
    path: '/reports/tourist-refunds',
    component: TouristRefundsList
  },
  {
    path: '/reports/tourist-payments',
    component: TouristPaymentsList
  },
  {
    path: '/reports/reservations-visualized',
    component: ReservationsVisualizedList
  },
  {
    path: '/reports',
    component: CompanyReports
  },
  {
    path: '/my-day',
    component: MyDay
  },
  {
    path: '/acquirers/:acquirerId/edit',
    component: EditAcquirer
  },
  {
    path: '/acquirers/create',
    component: CreateAcquirer
  },
  {
    path: '/acquirers',
    component: AcquirersList
  },
  {
    path: '/bank-accounts/create',
    component: CreateBankAccount
  },
  {
    path: '/bank-accounts/:bankAccountId/edit',
    component: EditBankAccount
  },
  {
    path: '/bank-accounts',
    component: BankAccounts
  },
  {
    path: '/external-providers/create',
    component: CreateExternalProvider
  },
  {
    path: '/external-providers/:externalProviderId/edit',
    component: EditExternalProvider
  },
  {
    path: '/external-providers',
    component: ExternalProviders
  },
  {
    path: '/tour-guide-payment',
    component: TourGuidePayment
  },
  {
    path: '/partnership-accounts/:partnershipAccountId',
    component: PartnershipTransactions
  },
  {
    path: '/partnership-accounts',
    component: PartnershipAccounts
  },
  {
    path: '/cashier-handling/create',
    component: CreateCashierHandling
  },
  {
    path: '/cashier-handling',
    component: CashierHandlingList
  },
  {
    path: '/cashiers/:cashierId',
    component: CashierDetails
  },
  {
    path: '/cashiers',
    component: CashierList
  },
  {
    path: '/notices/create',
    component: CreateNotices
  },
  {
    path: '/notices',
    component: NoticesList
  },
  {
    path: '/reservations',
    component: Reservations
  },
  {
    path: '/reservation/:reservationIdentification',
    component: Reservation
  },
  {
    path: '/reservation-balance/:reservationId',
    component: ReservationBalance
  },
  {
    path: '/activity/:activityId/fee/:feeId/edit',
    component: EditFee
  },
  {
    path: '/transport/:activityId/fee/:feeId/edit',
    component: EditFee
  },
  {
    path: '/limber-experience/:activityId/fee/:feeId/edit',
    component: EditFee
  },
  {
    path: '/activity/:activityId/edit/fee/create',
    component: CreateFee
  },
  {
    path: '/transport/:activityId/edit/fee/create',
    component: CreateFee
  },
  {
    path: '/limber-experience/:activityId/edit/fee/create',
    component: CreateFee
  },
  {
    path: '/activity/:activityId/edit/vacancy/:vacancyId/edit',
    component: EditVacancy
  },
  {
    path: '/transport/:activityId/edit/vacancy/:vacancyId/edit',
    component: EditVacancy
  },
  {
    path: '/offline-experience/:activityId/edit/vacancy/:vacancyId/edit',
    component: EditVacancy
  },
  {
    path: '/limber-experience/:activityId/edit/vacancy/:vacancyId/edit',
    component: EditVacancy
  },
  {
    path: '/activity/:activityId/edit/vacancy/create',
    component: CreateVacancy
  },
  {
    path: '/transport/:activityId/edit/vacancy/create',
    component: CreateVacancy
  },
  {
    path: '/offline-experience/:activityId/edit/vacancy/create',
    component: CreateVacancy
  },
  {
    path: '/limber-experience/:activityId/edit/vacancy/create',
    component: CreateVacancy
  },
  {
    path: '/activity/:activityId/edit',
    component: EditActivity
  },
  {
    path: '/transport/:activityId/edit',
    component: EditTransport
  },
  {
    path: '/offline-experience/:activityId/edit',
    component: EditOfflineExperience
  },
  {
    path: '/limber-experience/:activityId/edit',
    component: EditActivity // TODO trocar pro formulario limber
  },
  {
    path: '/activity/create',
    component: CreateActivity
  },
  {
    path: '/transport/create',
    component: CreateTransport
  },
  {
    path: '/offline-experience/create',
    component: CreateOfflineExperience
  },
  {
    path: '/limber-experience/create',
    component: CreateActivity // TODO trocar pro formulario limber
  },
  {
    path: '/activities',
    component: ActivitiesPage
  },
  {
    path: '/group/create',
    component: CreatePartnershipGroup
  },
  {
    path: '/group/:groupId/edit',
    component: EditPartnershipGroup
  },
  {
    path: '/group/:groupId/view',
    component: ViewPartnershipGroup
  },
  {
    path: '/group/invitation/:inviteId',
    component: PartnershipInvitation
  },
  {
    path: '/notifications',
    component: NotficationList
  },
  {
    path: '/tickets/check-in',
    component: TicketCheckin
  },
  {
    path: '/integrations/:integrationId/:integrationName/register-with-full-form',
    component: RegisterActivityWithFullForm
  },
  {
    path: '/logout',
    component: Logout
  }
]

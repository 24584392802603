import { css } from '@emotion/core'
import { colors } from '@bonitour/components'

export const baseInfoStyle = css`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`

export const cardContrast = css`
  background-color: ${colors.gray13};
  border: none;
  padding: 10px 20px;
`

export const width110 = css`
  width: 110px;
  max-width: 110px;
`

export const noMargin = css`
  margin: 0;
`

export const activityPickupPlaceName = css`
  color: ${colors.gray5};
  margin: 5px 0px;
  font-size: 14px;
`

export const activityTotalStyle = css`
  font-size: 22px;
  font-weight: bold;
  color: ${colors.primary};
`

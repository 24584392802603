import { identity } from '@bonitour/common-functions'
import { COMBINED_EXPERIENCE_TYPE } from 'constants/activityTypes'
import { useActivity } from 'contexts/Activity'
import { useMapSubscriber } from 'domains/Booking/Map/hooks/useMapSubscriber'
import { useQuery } from 'hooks/useQuery'
import { useEffect, useMemo } from 'react'

import { SafeJSONParse, validateObjHash } from 'utils/object'
import { calculateTimeDuration } from 'utils/time'

export const useCombinedExperienceBookingStates = ({
  slotsQuery,
  slotsKey,
  returnToBooking = identity
}) => {
  const { activity, isLoadingActivityFetch } = useActivity()

  const {
    experiences = [],
    id: experienceId,
    type
  } = activity || {}

  const isCombinedExperience = useMemo(() => type === COMBINED_EXPERIENCE_TYPE, [type])

  const slots = useMemo(() => SafeJSONParse(slotsQuery, []), [slotsQuery])

  useEffect(() => {
    if (
      isCombinedExperience &&
        slots &&
        !isLoadingActivityFetch) {
      const isValidSlotsKey = validateObjHash(slots, slotsKey)
      if (!isValidSlotsKey) {
        returnToBooking()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slots])

  const [{ pickupPlaceIds }] = useQuery()

  const pickupPlaceIdByService = useMemo(() => {
    if (pickupPlaceIds && experiences?.length) {
      return Object.fromEntries(
        Object
          .entries(SafeJSONParse(pickupPlaceIds, {}))
          .map(([serviceIndex, pickupPlaceId]) => [
            experiences?.[Number(serviceIndex)]?.serviceId,
            pickupPlaceId
          ])
      )
    }
    return null
  }, [pickupPlaceIds, experiences])

  const sortedServiceIds = useMemo(() => experiences.map(({ serviceId }) => serviceId), [experiences])
  const preActivitiesInfo = useMemo(() => slots.reduce((acc, serviceSlots, index) => [
    ...acc,
    ...serviceSlots.flat().map(slot => {
      const [date, time, ...fee] = Object.keys(slot)?.[0]?.split('_')
      const feeName = fee.join('_')
      return {
        date,
        time,
        fee: feeName,
        slot,
        serviceId: sortedServiceIds[index],
        pickupPlaceId: pickupPlaceIdByService?.[sortedServiceIds[index]] || undefined,
        quantity: slot[`${date}_${time}_${feeName}`]
      }
    })
  ], []), [slots, sortedServiceIds, pickupPlaceIdByService])

  const allDates = useMemo(() => [...new Set(preActivitiesInfo.map((activityInfo) => activityInfo.date))], [preActivitiesInfo])

  const {
    activitiesRegistryAndPrice = []
  } = useMapSubscriber(allDates, true, undefined, pickupPlaceIdByService)

  const activitiesPrice = useMemo(() => {
    if (!activitiesRegistryAndPrice || !Array.isArray(activitiesRegistryAndPrice)) return {}
    return activitiesRegistryAndPrice.reduce((acc, curr) => {
      acc[curr?.id] = curr
      return acc
    }, {})
  }, [activitiesRegistryAndPrice])

  const fullActivitiesInfo = useMemo(() => {
    if (!Object.keys(activitiesPrice).length) return {}

    const parsedSlots = preActivitiesInfo.map((info) => {
      if (!info?.serviceId) return null
      const activity = experiences?.find(({ serviceId }) => serviceId === info.serviceId)

      const serviceId = activity?.serviceId
      const detailedInfo = activitiesPrice[serviceId]

      const priceData = detailedInfo?.prices?.[info.date]?.find(({ name }) => name === info.fee)
      const price = Number(priceData?.pricing || 0)

      const { id, serviceTitle: name, serviceImage: image, isOptional, limberData, type: serviceType, companyId } = activity
      const pickupPlaces = limberData?.locaisEmbarque?.map(
        ({ localEmbarque: value, nomeLocalEmbarque: label }) => ({ value, label })
      )
      const { color, duration } = detailedInfo
      const { endTime } = calculateTimeDuration(info.time, duration)
      const { feeId, feeTypeId } = priceData || {}

      return {
        ...info,
        price,
        id,
        serviceId,
        experienceId,
        name,
        image,
        isOptional,
        color,
        endTime,
        companyId,
        feeId,
        feeTypeId,
        pickupPlaces,
        serviceType,
        priceData
      }
    }).filter(Boolean)

    const parsedActivities = Object.values(
      parsedSlots.reduce((acc, activity) => {
        const uniqueKey = `${activity.date}_${activity.time}_${activity.serviceId}`
        return ({
          ...acc,
          [uniqueKey]: [
            ...(acc?.[uniqueKey] || []),
            activity
          ]
        })
      }, {})
    )

    return allDates.reduce((acc, date) => {
      const activitiesFromDate = parsedActivities.reduce((activityAcc, activitySlots) => {
        const activities = activitySlots
          .filter((activity) => activity && activity.date === date)
          .concat(activityAcc)

        return activities
      }, [])

      const orderedActivitiesByHour = activitiesFromDate.sort((a, b) => {
        const [hourA, minuteA] = a.time.split(':').map(Number)
        const [hourB, minuteB] = b.time.split(':').map(Number)

        if (hourA === hourB) {
          return minuteA - minuteB
        }
        return hourA - hourB
      })

      acc[date] = orderedActivitiesByHour

      return acc
    }, {})
  }, [activitiesPrice, preActivitiesInfo, allDates, experiences, experienceId])

  const totalPrice = useMemo(() => Object.keys(fullActivitiesInfo).reduce((acc, date) => {
    const totalDateAmount = fullActivitiesInfo?.[date].reduce((acc, activity) => (activity.quantity * activity.price) + acc, 0)

    return totalDateAmount + acc
  }, 0), [fullActivitiesInfo])

  return {
    experiences: fullActivitiesInfo,
    dates: allDates,
    totalPrice
  }
}

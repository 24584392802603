import { css } from '@emotion/core'

export const flexCentered = css`
  display: flex;
  place-items: center;
  h4 {
    margin-bottom: 0;
    margin-right: 1rem;
  }
`

export const serviceList = css`
  display: block;
  margin: 0 1.25rem;
  li {
    list-style-type: disc
  }
`

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Card, colors, useToast } from '@bonitour/components'
import { FormHeader } from 'components/FormHeader'
import { identity } from '@bonitour/common-functions'
import { useForm } from '@bonitour/app-functions'

import { Periods } from 'containers/Periods/Periods'
import { ActivityVacancyName } from './ActivityVacancyName'
import { activityVacancySchema } from './ActivityVacancy.schema.rules'
import { ActivityVacancyFormSchedules } from './VacancySchedules/ActivityVacancyFormSchedules'
import { useCallback } from 'react'
import { BookingMapOperationProgress } from 'components/BookingMap/BookingMapOperationProgress'
import { marginBottom, width } from 'assets/styles/global'
const container = css`
  color: ${colors.gray3};
`
const fullSizeStyle = width(100)
const marginBottom20 = marginBottom(20)

export const ActivityVacancyForm = ({
  title = 'Vagas e Horários',
  activityVacancy: resetActivityVacancy = {},
  onBackClick: emitClickEvent = identity,
  onSuccess: emitSuccessEvent = identity,
  onValidationError: emitValidationError = identity,
  disabled = false
}) => {
  const { add: addToast } = useToast()
  const {
    form,
    errors,
    onSubmit,
    utils: { onInputBlur, onInputChange, addItemOnArray, removeItemArray }
  } = useForm(resetActivityVacancy, activityVacancySchema)

  const onValidationError = useCallback((error) => {
    const { scheduleList } = error
    emitValidationError(error)
    if (typeof scheduleList === 'string') {
      addToast(scheduleList, 'error', 8000)
    }
    // eslint-disable-next-line
  }, [])

  const validateForm = onSubmit(emitSuccessEvent, onValidationError)

  return (
    <>
      <FormHeader title={title} ghostClick={emitClickEvent} ctaClick={validateForm} disabled={disabled} />
      <BookingMapOperationProgress
        customCss={[fullSizeStyle, marginBottom20]}
      />
      <Card css={container}>
        <ActivityVacancyName
          activityVacancy={form.activityVacancy}
          activityVacancyErrors={errors.activityVacancy}
          onInputBlur={onInputBlur}
          onChange={onInputChange}
        />
        <Periods
          label='Períodos da vaga'
          periodList={form.periodList}
          placeholder='Ex. Férias escolares'
          errors={errors.periodList}
          onBlur={onInputBlur}
          onChange={onInputChange}
          onItemAdd={addItemOnArray('periodList')}
          onItemRemove={removeItemArray('periodList')}
        />
        <ActivityVacancyFormSchedules
          schedules={form.scheduleList}
          errors={errors.scheduleList}
          onChange={onInputChange}
          onBlur={onInputBlur}
          onItemAdd={addItemOnArray('scheduleList')}
          onItemRemove={removeItemArray('scheduleList')}
        />
      </Card>
    </>
  )
}

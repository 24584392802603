/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useCallback } from 'react'
import { useForm } from '@bonitour/app-functions'
import { useAddressLocation, useCompanyAddress, useZipCode, useGeoCode } from 'containers/Address/Address.hooks'
import { activityInfoSchema } from 'containers/ActivityBaseForm/ActivityInfo.schema'

export const useActivityForm = ({ activityBase, addToast, onSuccess }) => {
  const { addressInfo: { country, state } = {} } = activityBase ?? {}
  const { countries, states, cities, updateAvailableStates, updateAvailableCities } = useAddressLocation(addToast, country, state)
  const [companyAddress, updateCompanyAddress] = useCompanyAddress(addToast)

  const [zipCodeData, onZipCodeUpdate] = useZipCode(addToast)
  const [latLong, onGeoCodeUpdate] = useGeoCode(addToast)
  const schema = useMemo(() => activityInfoSchema(states, cities), [states, cities])
  const {
    form,
    errors,
    setForm,
    onSubmit,
    utils: { onInputBlur, onInputChange, addItemOnArray, removeItemArray }
  } = useForm(activityBase, schema)

  const onValidationError = useCallback(() => addToast('Preencha corretamente o formulário'), [addToast])
  const handleSubmit = useMemo(() => onSubmit(onSuccess, onValidationError), [onSubmit, onSuccess, onValidationError])
  const onFormError = useCallback((message) => addToast(message), [addToast])

  useEffect(() => {
    onInputChange('addressInfo')(companyAddress)
    onCountryChange(companyAddress.country)
  }, [companyAddress])

  useEffect(() => {
    onInputChange('addressInfo')({ ...form.addressInfo, ...latLong })
  }, [latLong])

  const onZipCodeBlur = () => {
    onZipCodeUpdate(form.addressInfo?.zipCode)
    onInputBlur('addressInfo.zipCode')()
  }

  const onCountryChange = useCallback(
    (countryValue) => {
      const country = countries.find((country) => Object.values(country).includes(countryValue))
      if (country) {
        updateAvailableStates(country.value)
        onInputChange('addressInfo.country')(country.value)
      } else {
        updateAvailableStates(null)
        onInputChange('addressInfo.country')('')
        onInputChange('addressInfo.state')('')
        onInputChange('addressInfo.city')('')
      }
    },
    [countries, updateAvailableStates, onInputChange]
  )

  const onStateChange = useCallback(
    (stateValue) => {
      const state = states.find((state) => Object.values(state).includes(stateValue))
      if (state) {
        updateAvailableCities(form.addressInfo?.country, state.value)
        onInputChange('addressInfo.state')(state.value)
      } else {
        updateAvailableCities(null)
        onInputChange('addressInfo.state')('')
        onInputChange('addressInfo.city')('')
      }
    },
    [states, updateAvailableCities, form.addressInfo?.country, onInputChange]
  )

  const onCityChange = useCallback(
    (cityValue) => {
      const city = cities.find((city) => Object.values(city).includes(cityValue))
      if (city) {
        onInputChange('addressInfo.city')(city.value)
      } else {
        onInputChange('addressInfo.city')('')
      }
    },
    [cities, onInputChange]
  )
  const updateAddress = useCallback(() => {
    if (Object.keys(zipCodeData).length) {
      setForm((previousForm) => ({
        ...previousForm,
        addressInfo: {
          ...previousForm.addressInfo,
          street: zipCodeData.address,
          district: zipCodeData.district,
          state: zipCodeData.state,
          city: zipCodeData.city
        }
      }))
      onCountryChange('BR')
    }
  }, [setForm, onCountryChange, zipCodeData])

  useEffect(updateAddress, [zipCodeData])

  const updateCountries = useCallback(() => {
    if (countries?.length) {
      onCountryChange(form.addressInfo?.country)
    }
  }, [countries, onCountryChange, form.addressInfo?.country])

  useEffect(updateCountries, [countries])

  const updateStates = useCallback(() => {
    if (states?.length) {
      onStateChange(form.addressInfo?.state)
    }
  }, [states, onStateChange, form.addressInfo?.state])

  useEffect(updateStates, [states])

  const updateCities = useCallback(() => {
    if (cities?.length) {
      onCityChange(form.addressInfo?.city)
    }
  }, [cities, onCityChange, form.addressInfo?.city])

  useEffect(updateCities, [cities])

  return {
    form,
    errors,
    countries,
    states,
    cities,
    onInputChange,
    addItemOnArray,
    removeItemArray,
    onInputBlur,
    onZipCodeBlur,
    onCountryChange,
    onStateChange,
    updateCompanyAddress,
    onLatLongBlur: onGeoCodeUpdate,
    onError: onFormError,
    onSubmit: handleSubmit

  }
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useCombinedExperienceForm } from '../Config/hooks/useCombinedExperienceForm'
import { useQuery } from 'hooks/useQuery'
import { validateObjHash } from 'utils/object'

const BookingMapInfoCtx = createContext()

// The isCommonBookingMap prop has created to turn off the experience params
export const BookingMapInfoProvider = ({ children, isCommonBookingMap = true }) => {
  const [feePrices, setFeePrices] = useState(null)
  const [{ slots, slotsKey }, { removeQueriesParam }] = useQuery()

  const parsedSlots = useMemo(() => {
    if (slots) {
      try {
        return JSON.parse(slots)
      } catch (_error) {
        return {}
      }
    }
    return {}
  }, [slots])

  const {
    onSelectActivity,
    resetForm,
    values: {
      selectedActivities
    },
    toggleReservationMode,
    isEnabledReservationMode,
    onSubmit,
    setValue,
    getExperienceQueryParams
  } = useCombinedExperienceForm({
    isCommonBookingMap
  })

  const [slotsOccupiedOnDates, slotsOccupiedOnHourlies] = useMemo(() => {
    const occupiedDatetime = selectedActivities.reduce((acc, { date, serviceId, quantity, hour }) => {
      if (!acc[0][date]) {
        acc[0][date] = {}
      }

      if (!acc[1][date]) {
        acc[1][date] = {}
      }

      if (!acc[1][date][serviceId]) {
        acc[1][date][serviceId] = {}
      }

      const quantityByDate = (acc[0][date][serviceId] || 0) + quantity

      acc[0][date][serviceId] = quantityByDate

      const quantityByHour = (acc[1][date][serviceId][hour] || 0) + quantity

      acc[1][date][serviceId][hour] = quantityByHour

      return acc
    }, [{}, {}])

    return occupiedDatetime
  }, [selectedActivities])

  const calculatedActivitiesFeesPrices = useMemo(() => {
    if (!Array.isArray(feePrices) || !Array.isArray(selectedActivities)) return {}

    const feePricesMap = new Map(feePrices.map(({ id, prices }) => [id, prices]))

    const experiencesIds = selectedActivities.map(({ serviceId }) => serviceId)

    const processedIds = new Set()

    return experiencesIds.reduce((acc, id) => {
      if (processedIds.has(id)) return acc

      const { total, quantity } = selectedActivities
        .filter(({ serviceId }) => serviceId === id)
        .reduce((acc, curr) => {
          const { feeName, quantity, date } = curr

          const feeDates = feePricesMap.get(id)

          if (!feeDates) return acc

          const feeData = feeDates?.[date]?.find(({ name }) => name === feeName)

          if (!feeData) return acc

          acc.total += Number(feeData.pricing) * quantity
          acc.quantity += quantity

          return acc
        }, { total: 0, quantity: 0 })

      acc[id] = {
        quantity,
        total
      }

      processedIds.add(id)

      return acc
    }, {})
  }, [feePrices, selectedActivities])

  const selectedDate = useMemo(() => {
    if (!Array.isArray(selectedActivities) || !selectedActivities.length) return null
    return selectedActivities[0]?.date
  }, [selectedActivities])

  const biggestPaxQuantityOfDates = useMemo(() => {
    if (!Array.isArray(selectedActivities)) return {}

    const allDates = Array.from(new Set(selectedActivities.map(({ date }) => date)))

    if (!allDates.length) return {}

    return allDates.reduce((acc, date) => {
      const activitiesFromDate = selectedActivities
        .filter(({ date: currDate }) => currDate === date)

      const activitiesWithQuantity = activitiesFromDate.reduce((acc, { quantity, serviceId }) => {
        if (!acc[serviceId]) {
          acc[serviceId] = 0
        }

        acc[serviceId] = quantity + acc[serviceId]

        return acc
      }, {})

      acc[date] = Object.keys(activitiesWithQuantity).reduce((acc, serviceId) => {
        if (activitiesWithQuantity[serviceId] > acc) {
          return activitiesWithQuantity[serviceId]
        }
        return acc
      }, 0)

      return acc
    }, {})
  }, [selectedActivities])

  const {
    totalTickets = 0,
    totalPrice = 0
  } = useMemo(() => {
    return Object.values(calculatedActivitiesFeesPrices)
      .reduce((acc, curr) => {
        acc.totalTickets = acc.totalTickets + curr.quantity
        acc.totalPrice = acc.totalPrice + curr.total
        return acc
      }, { totalTickets: 0, totalPrice: 0 })
  }, [calculatedActivitiesFeesPrices])

  const getInvalidActivities = useCallback(({ selectedActivities: selectedActivitiesData = [] }) => {
    if (!Array.isArray(feePrices) || !Array.isArray(selectedActivitiesData)) return []

    if (!selectedActivitiesData.length) {
      return feePrices.reduce((acc, { id, isOptional, color, name }) => {
        if (!isOptional) {
          acc.push({
            requiredTotalTickets: 'Ao menos 1 ingresso',
            ticketsAdded: 0,
            color,
            serviceName: name,
            serviceId: id
          })
        }
        return acc
      }, [])
    }

    const allDates = Array.from(new Set(selectedActivitiesData.map(({ date }) => date)))

    const processedServiceIds = new Set()
    const allServiceIds = new Set()

    const preProcessedInvalidData = feePrices.reduce((acc, { id, isOptional, color, name }) => {
      allServiceIds.add(id)

      if (isOptional) {
        return acc
      }

      const activitySelectionData = selectedActivitiesData.filter(({ serviceId }) => serviceId === id)

      for (const date of allDates) {
        for (const _activity of activitySelectionData) {
          if (processedServiceIds.has(id)) continue

          processedServiceIds.add(id)

          const quantity = activitySelectionData
            .filter(({ serviceId }) => serviceId === id)
            .reduce((acc, { quantity }) => quantity + acc, 0)

          if (quantity !== biggestPaxQuantityOfDates[date] || quantity === 0) {
            acc.push({
              requiredTotalTickets: biggestPaxQuantityOfDates[date] === 0 ? 'Ao menos 1 ingresso' : biggestPaxQuantityOfDates[date],
              ticketsAdded: quantity,
              color,
              serviceName: name,
              serviceId: id
            })
          }
        }
      }

      return acc
    }, [])

    const remainingServiceIds = Array.from(allServiceIds).filter((id) => !processedServiceIds.has(id))

    const processedInvalidData = remainingServiceIds.reduce((acc, id) => {
      const { isOptional, color, name } = feePrices.find(({ id: currId }) => currId === id)

      if (isOptional) return acc

      acc.push({
        requiredTotalTickets: biggestPaxQuantityOfDates?.[allDates?.[0]] || 'Ao menos 1 ingresso',
        ticketsAdded: 0,
        color,
        serviceName: name,
        serviceId: id
      })

      return acc
    }, []).concat(preProcessedInvalidData)

    return processedInvalidData
  }, [biggestPaxQuantityOfDates, feePrices])

  const getIsAllServicesInSameDay = useCallback(() => {
    let allServicesInSameDay = true
    for (const { date } of selectedActivities) {
      if (date !== selectedActivities[0]?.date) {
        allServicesInSameDay = false
      }
    }
    return allServicesInSameDay
  }, [selectedActivities])

  const loadFeePrices = useCallback(({ initialFeePrices = null }) => {
    if (!initialFeePrices || !Array.isArray(initialFeePrices)) return
    setFeePrices(initialFeePrices)
  }, [])

  const getSelectedPaxData = useCallback(({
    activityId,
    hour,
    date
  }) => {
    const filteredSelectedPax = selectedActivities
      .filter((curr) => curr.serviceId === activityId && curr.date === date && curr.hour === hour) || []
    return filteredSelectedPax
      .reduce((acc, curr) => {
        acc[curr.feeName] = curr.quantity
        return acc
      }, {})
  }, [selectedActivities])

  const getSelectedTotals = useCallback(({
    activityId,
    hour,
    date
  }) => {
    const quantityTotals = selectedActivities
      .reduce((acc, curr) => (curr.serviceId === activityId && curr.date === date && curr.hour === hour) ? acc + curr.quantity : acc, 0)

    return quantityTotals
  }, [selectedActivities])

  const resetBookingMapInfo = useCallback(() => {
    resetForm()
    toggleReservationMode(false)
  }, [resetForm, toggleReservationMode])

  const getSelectedActivitiesInfoBySlots = useCallback((slotsData) => {
    const sortedServiceIds = feePrices.map(({ id }) => id)
    return slotsData.reduce((acc, serviceSlots, index) => [
      ...acc,
      ...serviceSlots.flat().map(slot => {
        const [date, hour, ...fee] = Object.keys(slot)?.[0]?.split('_')
        const feeName = fee.join('_')
        return {
          date,
          hour,
          feeName,
          serviceId: sortedServiceIds[index],
          quantity: slot[`${date}_${hour}_${feeName}`]
        }
      })
    ], [])
  }, [feePrices])

  useEffect(() => {
    if (isCommonBookingMap) {
      const hasSlotParam = Object.keys(parsedSlots).length > 0

      const isCorrectSlotsKey = hasSlotParam ? validateObjHash(parsedSlots, slotsKey) : false

      if (hasSlotParam && !isCorrectSlotsKey) {
        removeQueriesParam(['slots', 'slotsKey'])
      }

      if (hasSlotParam && feePrices && !selectedActivities.length && isCorrectSlotsKey) {
        const defaultFormData = getSelectedActivitiesInfoBySlots(parsedSlots)
        if (defaultFormData.length) {
          toggleReservationMode(true)
          setValue('selectedActivities', defaultFormData)
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parsedSlots, feePrices])

  const value = useMemo(() => ({
    onSelectActivity,
    getSelectedTotals,
    getSelectedPaxData,
    activitiesFeesPrices: calculatedActivitiesFeesPrices,
    loadFeePrices,
    toggleReservationMode,
    isEnabledReservationMode,
    totalTickets,
    totalPrice,
    getInvalidActivities,
    selectedActivities,
    slotsOccupiedOnDates,
    resetBookingMapInfo,
    getIsAllServicesInSameDay,
    onSubmit,
    selectedDate,
    slotsOccupiedOnHourlies,
    getExperienceQueryParams
  }), [
    onSelectActivity,
    getSelectedTotals,
    getSelectedPaxData,
    calculatedActivitiesFeesPrices,
    loadFeePrices,
    toggleReservationMode,
    isEnabledReservationMode,
    totalTickets,
    totalPrice,
    getInvalidActivities,
    selectedActivities,
    slotsOccupiedOnDates,
    resetBookingMapInfo,
    getIsAllServicesInSameDay,
    onSubmit,
    selectedDate,
    slotsOccupiedOnHourlies,
    getExperienceQueryParams
  ])

  return <BookingMapInfoCtx.Provider value={value}>{children}</BookingMapInfoCtx.Provider>
}

export const useBookingMapInfo = () => {
  const context = useContext(BookingMapInfoCtx)

  if (!context) {
    console.warn('useBookingMapInfo must be used within a BookingMapInfoProvider')
  }

  return context || {}
}

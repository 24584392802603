import { useToast } from '@bonitour/components'
import { TicketsService } from 'core/services/Tickets'
import { useQuery } from 'hooks/useQuery'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { identity } from '@bonitour/common-functions'
import { usePermissions } from 'hooks/usePermissions'
import { CodesCondenser } from '../CodeCondenserProvider'

const expandCodeIfInvalid = (code) => {
  if (!CodesCondenser.checkIfCodeIsValid(code)) {
    return CodesCondenser.expandCode(code)
  } else {
    return code
  }
}

const decode = (code) => decodeURIComponent(code || '')
  .split(/[;\s]+/)
  .filter(Boolean)
  .map(expandCodeIfInvalid)

export const useTicketsCheckin = () => {
  const { add: addToast } = useToast()
  const { handlePermission } = usePermissions()
  const [{ code }, { changeQueryParam }] = useQuery()
  const [ticketList, setTicketList] = useState([])
  const [isTicketsLoading, setTicketsLoadingState] = useState(0)
  const [isModalVisible, setModalVisibility] = useState(0)
  const [selectedCode, setSelectedCode] = useState()
  const [loading, setLoading] = useState(0)

  const getTicketList = useCallback(() => {
    if (code) {
      const codes = decode(code)
      setTicketList([])

      codes.forEach((code) => {
        setTicketsLoadingState(state => state + 1)
        setLoading(state => state + 1)
        TicketsService.getByPartialCode(code)
          .then((tickets) => {
            setTicketsLoadingState(state => state - 1)
            if (!tickets.length) {
              addToast('Não foram encontrados tickets', 'warning', 10000)
            }
            setTicketList(state => [...state, ...tickets])
          })
          .catch((error) => {
            const hasPermission = handlePermission(error, 'Você não possui permissão de acesso aos ingressos')
            hasPermission && addToast('Ocorreu um erro ao realizar a busca')
            setTicketsLoadingState(state => state - 1)
          })
          .finally(() => setLoading(state => state - 1))
      })
    }
    // eslint-disable-next-line
  }, [code, addToast])

  const ticketForm = useMemo(() => ({ code: decode(code).join(';') }), [code])

  const onSearch = useCallback(({ code }) => {
    changeQueryParam('code', code)
  }, [changeQueryParam])

  const onValidateClick = useCallback(({ code }) => {
    setSelectedCode(code)
    setModalVisibility(true)
  }, [])

  const validateManyCodes = useCallback((codes, optionalFunctionCall = identity) => {
    TicketsService.validateManyTickets(codes)
      .then(() => {
        addToast('Tickets validados com sucesso', 'success')
        setModalVisibility(false)
        getTicketList()
        if (typeof optionalFunctionCall === 'function') {
          optionalFunctionCall()
        }
      })
      .catch(() => {
        addToast('Ocorreu um erro ao validar o ingresso')
        setModalVisibility(false)
      })
  }, [addToast, getTicketList])

  const validateCode = useCallback((optionalFunctionCall = identity) => {
    if (selectedCode) {
      validateManyCodes([{ ticketCode: selectedCode }], optionalFunctionCall)
    }
  }, [selectedCode, validateManyCodes])

  const onModalClose = useCallback(() => {
    setModalVisibility(false)
    setSelectedCode()
  }, [])

  useEffect(() => {
    getTicketList()
  }, [getTicketList])

  return {
    isTicketsLoading,
    isModalVisible,
    selectedCode,
    ticketForm,
    ticketList,
    onValidateClick,
    validateCode,
    validateManyCodes,
    onModalClose,
    onSearch,
    loading
  }
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { BREAK_POINTS, Button, Checkbox, Counter, Dialog, EditInfoIcon, PrinterIcon, colors, useToast } from '@bonitour/components'
import { jsx, css } from '@emotion/core'
import { Integrations } from 'constants/integrations'
import { useCompany } from 'contexts/Company'
import { CompanyService } from 'core/services/Company'
import { counterButtons, counterWrapper } from 'domains/CombinedExperience/components/ActivityModalParts/ActivityFees.style'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { BinamikPrinter } from 'services/BinamikPrinter/Service'
import { ReservationService } from 'services/Reservations/Service'

const printReservationStyle = css`
  position: absolute;
  right: 0;
  bottom: 0.125rem;

  button {
    font-size: 1.5rem;
    padding: 0.375rem 0.5rem;
    margin: 0.5rem 0.25rem;
    background-color: transparent;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    color: ${colors.gray1};

    &:hover {
      background-color: ${colors.gray8};
      color: ${colors.primary};
      -webkit-text-stroke: 0.5px;
    }

    i {
      transform: translateY(2px);
    }
  }

  @media screen and (max-width: ${BREAK_POINTS.smallTablet}) {
    bottom: 2.5rem;
    button {
      font-size: 1.25rem;
    }
  }
`

const printDialogStyle = css`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding: 0.5rem 0;
  color: ${colors.gray1};

  .printer_name {
    border-radius: 0.5rem;
    border: 1px solid ${colors.gray7};
    background-color: ${colors.white};
    color: ${colors.gray1};
    font-weight: 500;
    margin: 0 0.25rem;
    font-size: 14px;
    padding: 0.25rem 0.5rem;
    font-family: "Mulish", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    min-width: 14rem;
    
    &:not(input) {
      background-color: ${colors.gray13};
      color: ${colors.gray3};
      margin: 0 0.25rem;
      line-height: 2;
      min-width: unset;
    }
  }

  .input_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.25rem;
  }
  
  .edit_btn {
    background-color: transparent;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    
    &:hover {
      background-color: ${colors.gray8};
      color: ${colors.primary};
      i {
        -webkit-text-stroke: 0.5px;
      }
    }
  }

  .counter {
    height: 1.75rem;
    border-radius: 0.5rem;
    width: 4.5rem;
    button {
      height: 1.625rem;
    }
  }

  input[type='checkbox'] {
    cursor: pointer;
    transform: scale(0.85);
    margin: 0;
    &:disabled {
      cursor: not-allowed;
    }
  }

  label {
    cursor: pointer;
    &[disabled] {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }

  > button:last-of-type {
    margin-top: 1rem;
    margin-bottom: -1rem;
  }
`

export const PrintReservation = ({ reservationId }) => {
  const {
    data: printerServiceAbout
  } = useQuery(
    ['printerServiceAbout'],
    () => BinamikPrinter().about().catch(() => null),
    {
      retry: false,
      refetchInterval: 20000
    }
  )

  const hasPrinterService = useMemo(
    () => printerServiceAbout?.name === 'Binamik Printer Service',
    [printerServiceAbout]
  )

  const [isDialogVisible, setIsDialogVisible] = useState(false)
  const openDialog = useCallback(() => setIsDialogVisible(true), [])
  const closeDialog = useCallback(() => setIsDialogVisible(false), [])

  const [printerName, setPrinterName] = useState(
    localStorage.getItem('@BinamikOrbPrinterName') || 'ELGIN i9(USB)'
  )
  const [isEditingPrinterName, setIsEditingPrinterName] = useState(false)
  const toggleEditingPrinterName = useCallback(() => setIsEditingPrinterName(v => !v), [])
  const onPrinterNameChange = useCallback(
    (event) => {
      localStorage.setItem('@BinamikOrbPrinterName', event.target.value)
      return setPrinterName(event.target.value)
    }, []
  )

  const {
    data: printerServiceHealth
  } = useQuery(
    ['printerServiceHealth', printerName],
    () => BinamikPrinter().health({ printerName }).catch(() => null),
    {
      enabled: hasPrinterService && isDialogVisible,
      retry: false,
      refetchInterval: 10000
    }
  )

  const [copiesQty, setCopiesQty] = useState(Number(localStorage.getItem('@BinamikOrbPrinterCopies')) || 1)
  const onCopiesQtyChange = useCallback(
    (value) => {
      localStorage.setItem('@BinamikOrbPrinterCopies', value)
      return setCopiesQty(value)
    }, []
  )

  const [printConfirmedTickets, setPrintConfirmedTickets] = useState(true)
  const onChangeConfirmedTickets = useCallback(
    (event) => setPrintConfirmedTickets(event.target.checked), []
  )
  const [printReservedTickets, setPrintReservedTickets] = useState(false)
  const onChangeReservedTickets = useCallback(
    (event) => setPrintReservedTickets(event.target.checked), []
  )

  const [isPrinting, setIsPrinting] = useState(false)

  const printerReady = useMemo(() => printerServiceHealth?.status === 'IDLE_READY', [printerServiceHealth])

  const { id: companyId } = useCompany()
  const {
    data: companyInfo
  } = useQuery(
    ['companyInfo', companyId],
    () => CompanyService.get(companyId).catch(() => null),
    {
      retry: false
    }
  )

  const { add: addToast } = useToast()

  const printReservation = useCallback(async (copy = 0) => {
    if (!companyInfo) {
      addToast('Erro ao obter informações da empresa', 'error')
      return
    }
    setIsPrinting(true)

    const ticketsData = await ReservationService.getReservationTickets(reservationId)
    const tickets = ticketsData
      .map(([_date, service]) => service.map(({ tickets, ...service }) => tickets.map(ticket => ({ ...ticket, service }))))
      .flat(2)
      .filter(
        ({ state }) => !state || ![
          'canceled',
          'used',
          ...(printReservedTickets ? [] : ['reserved']),
          ...(printConfirmedTickets ? [] : ['confirmed', 'checked-in', 'no-show'])
        ].includes(state)
      )
      .map(({ ticketCode, service, slot, type: feeName, externalIntegrations, price }) => {
        const limberIntegration = externalIntegrations.find(
          ({ integrationName }) => integrationName === Integrations.Limber
        )
        return ({
          code: limberIntegration?.extraData?.qr_code_exp || ticketCode,
          numberLocator: limberIntegration?.externalTicketId || undefined,
          pickUpPlace: limberIntegration?.extraData?.nome_local_embarque || undefined,
          dateTime: new Date(slot.replace(/Z$/, '')).toLocaleString('pt-BR').replace(/,|:\d\d$/g, ''),
          name: service?.title,
          companyName: service?.vendorName,
          feeName,
          price: Math.round(Number(price) * 100) / 100
        })
      })

    if (!tickets.length) {
      addToast('Nenhum ingresso para imprimir', 'info')
      setIsPrinting(false)
      return
    }

    BinamikPrinter().print.reservation({
      payload: {
        company: {
          name: companyInfo.name,
          document: companyInfo.document,
          phone: companyInfo.phones?.[0]?.number,
          email: companyInfo.main_email
        },
        reservationPrice: tickets
          .reduce((acc, { price }) => acc + price, 0)
          .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
          .replace(/\s/g, ' '),
        dateTime: new Date().toLocaleString('pt-BR').replace(/,/g, '')
      },
      printerName
    }).then(() => {
      BinamikPrinter().print.tickets({
        payload: { tickets },
        printerName
      }).then(() => {
        if (copy + 1 < copiesQty) {
          setTimeout(() => printReservation(copy + 1), 500)
          return
        }
        addToast(`${tickets.length} ingresso(s) enviados para fila de impressão (${copiesQty} cópia(s))`, 'success')
        closeDialog()
        setIsPrinting(false)
      }).catch(() => {
        addToast(`Erro ao imprimir ingressos - Cópia ${copy + 1}`, 'error')
        setIsPrinting(false)
      })
    }).catch(() => {
      addToast(`Erro ao imprimir reserva - Cópia ${copy + 1}`, 'error')
      setIsPrinting(false)
    })
  }, [addToast, companyInfo, printerName, copiesQty, reservationId, printConfirmedTickets, printReservedTickets, closeDialog])

  useEffect(() => {
    if (!isDialogVisible) {
      setIsEditingPrinterName(false)
    }
  }, [isDialogVisible])

  if (!hasPrinterService) {
    return null
  }

  return (
    <div css={printReservationStyle}>
      <button type='button' title='Imprimir' onClick={openDialog}>
        <PrinterIcon />
      </button>
      <Dialog
        title='Imprimir Reserva'
        isVisible={isDialogVisible}
        onClose={closeDialog}
      >
        <div css={printDialogStyle}>
          <div>
            <label htmlFor='printerName'>Impressora:</label>
            {!isEditingPrinterName
              ? (
                <>
                  <span className='printer_name'>
                    {printerName}
                  </span>
                  <button className='edit_btn' type='button' title='Editar' onClick={toggleEditingPrinterName}>
                    <EditInfoIcon />
                  </button>
                </>
              )
              : (
                <input
                  className='printer_name'
                  type='text'
                  id='printerName'
                  value={printerName}
                  onChange={onPrinterNameChange}
                />
              )}
          </div>
          <div className='input_row'>
            <label htmlFor='copies'>Cópias:</label>
            <div css={counterWrapper} className='counter'>
              <Counter
                css={counterButtons}
                id='copies'
                value={copiesQty}
                min={1}
                max={4}
                onChange={onCopiesQtyChange}
              />
            </div>
          </div>
          <div>
            <div className='input_row'>
              <Checkbox
                id='printConfirmedTickets'
                name='printConfirmedTickets'
                checked={printConfirmedTickets}
                onChange={onChangeConfirmedTickets}
              />
              <label htmlFor='printConfirmedTickets'>Imprimir ingressos confirmados</label>
            </div>
            <div className='input_row'>
              <Checkbox
                id='printReservedTickets'
                name='printReservedTickets'
                checked={printReservedTickets}
                onChange={onChangeReservedTickets}
              />
              <label htmlFor='printReservedTickets'>Imprimir ingressos reservados</label>
            </div>
            <div className='input_row'>
              <Checkbox id='printCancelledTickets' name='printCancelledTickets' disabled />
              <label disabled htmlFor='printCancelledTickets'>Imprimir ingressos cancelados</label>
            </div>
            <div className='input_row'>
              <Checkbox id='printUsedTickets' name='printUsedTickets' disabled />
              <label disabled htmlFor='printUsedTickets'>Imprimir ingressos usados</label>
            </div>
          </div>
          <div className='input_row'>
            Status da impressora: {printerReady ? '✅ Pronta' : '❌ Offline'}
          </div>

          <Button type='button' disabled={!printerReady || isPrinting} onClick={() => printReservation()}>
            Imprimir Ingressos
          </Button>
        </div>
      </Dialog>
    </div>
  )
}

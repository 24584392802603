import { string, object } from 'yup'

const MIN_MAX_LENGTH_LABEL = 'O código da reserva deve ter no mínimo e máximo 12 caracteres.'

const REQUIRED_LABEL = 'O código da reserva deve ser preenchido'

export const LinkReservationSchema = object().shape({
  reservationCode: string()
    .typeError(REQUIRED_LABEL)
    .required(REQUIRED_LABEL)
    .min(12, MIN_MAX_LENGTH_LABEL)
    .max(12, MIN_MAX_LENGTH_LABEL)
})

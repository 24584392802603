import { css } from '@emotion/core'

import { BREAK_POINTS, colors } from '@bonitour/components'

import { marginBottom, marginTop } from 'assets/styles/global'

export const marginTop20 = marginTop(20)
export const marginBottom10 = marginBottom(10)

export const customCommissionContainer = css`
  --addbtn-clr: ${colors.primaryVariant};
`

export const customCommissionsList = css`
  display: grid;

  grid-template-columns: 1fr;
  gap: 8px;
`

export const customCommissionItem = css`
  display: grid;
  grid-template-columns: auto auto auto 1fr;
  gap: 1rem;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5rem;

  border: 1px dashed ${colors.gray9};
  border-radius: 1rem;

  .item__input {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
  }

  .delete__container {
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    button {
      background: none;
      border: 0;
      padding: 0.5rem;
      font-size: 1.125rem;
      color: ${colors.gray3};
      cursor: pointer;
      margin-left: auto;

      &:disabled {
        opacity: 0.25;
        cursor: default;
      }
    }
  }

  .input__label {
    font-size: 0.813rem;
    color: ${colors.gray2};
    margin-bottom: 4px;
    font-weight: 600;
  }

  .select__service {
    input::placeholder {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .amount_info__container {
    display: flex;
    flex-direction: row;
    gap: 5px;
  }

  @media (max-width: ${BREAK_POINTS.smallPhone}) {
    grid-template-columns: auto auto;

    .amount_info__container {
      div:first-child {
        flex-direction: row;
      }
    }
  }
`

export const width100prc = css`
  width: 100%;
`

export const hiddenTooltip = css`
  ${width100prc}
  .tooltip-on-hover {
    display: none !important;
  }
`

export const tooltip = css`
  ${width100prc}
  .tooltip-on-hover {
    margin-bottom: 0.75rem;
    width: calc(100% - 2rem);
    line-height: 1.15rem;
    word-break: break-word;
  }
`

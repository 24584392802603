/* eslint-disable camelcase */
import { removeEmptyAttribute, replaceCommaToDot } from '@bonitour/common-functions'
import { COMBINED_EXPERIENCE_TYPE } from 'constants/activityTypes'
import moment from 'moment'

export const reservationSearchParamsModel = (filterParams = {}) => {
  const {
    code = '',
    email = '',
    cpf: document = '',
    name = '',
    initialDate,
    finalDate,
    status = '',
    responsible = '',
    sellingChannel: selling_channel
  } = filterParams
  return {
    reservation_code: code,
    commercial_partner_id: responsible,
    full_name: name,
    initial_date: initialDate && moment(initialDate).format('YYYY-MM-DD'),
    final_date: finalDate && moment(finalDate).format('YYYY-MM-DD'),
    email,
    document: document.replace(/\D/g, ''),
    status,
    selling_channel
  }
}

const buildPassengerArray = (tickets) => tickets.reduce((acc, item) => {
  const { price, fee_type_id: feeTypeId, fee_id: feeId, quantity, pickupPlaceId } = item
  let tickets = []
  for (let index = 0; index < quantity; index++) {
    tickets = tickets.concat({
      state: 'reserved',
      price: Number(price),
      fee_type_id: feeTypeId,
      fee_id: feeId,
      pickup_place_id: (pickupPlaceId && pickupPlaceId !== 'undefined') ? pickupPlaceId : undefined
    })
  }
  return acc.concat(tickets)
}, [])

const buildCombinedExperiencePassengerArray = (selectedSlots = [], pickupPlaceId) => {
  return selectedSlots.map(({ price, quantity, feeTypeId, feeId }) => {
    const slots = Array.from({ length: quantity }).fill({
      state: 'reserved',
      price,
      fee_type_id: feeTypeId,
      fee_id: feeId,
      pickup_place_id: pickupPlaceId
    })
    return slots
  }).flat()
}

const buildTickets = (tickets, companyId, serviceId, date, hour, type) => {
  const isCombinedExperience = type === COMBINED_EXPERIENCE_TYPE

  if (isCombinedExperience) {
    const processedActivities = new Set()

    const combinedExperienceTickets = Object.keys(tickets).reduce((acc, date) => {
      const activities = tickets[date]

      const parsedActivities = activities.map(({ time, serviceId, companyId, experienceId, pickupPlaceId, serviceType }) => {
        const key = `${serviceId}${experienceId}${time}${date}`

        if (processedActivities.has(key)) return null

        processedActivities.add(key)

        const allSelectedSlots = activities.filter(({ serviceId: currServiceId, time: currTime, date: currDate, experienceId: currExperienceId }) => currServiceId === serviceId && currTime === time && currDate === date && currExperienceId === experienceId)

        return {
          slot: `${date} ${time}`,
          experience_id: experienceId,
          company_id: companyId,
          service_id: serviceId,
          type: serviceType,
          passengers: buildCombinedExperiencePassengerArray(allSelectedSlots, pickupPlaceId)
        }
      })

      acc.push(parsedActivities)

      return acc
    }, []).flat()

    return combinedExperienceTickets
  }

  const passengers = buildPassengerArray(tickets)
  return [
    {
      company_id: companyId,
      service_id: serviceId,
      slot: `${date} ${hour}`,
      type,
      passengers
    }
  ]
}

export const reservationInfoModel = ({ comments }) => {
  return {
    comment: comments
  }
}

export const payerInfoModel = ({ payerId, name, email, identificationDocument, birthDate, number, comments, country, state, city, zipCode, street, supplement, district, emergencyTelephone, emergencyName, weight, height }) => {
  if (payerId) return ({ id: payerId })

  const address = {
    country_id: country,
    state_id: state,
    city_id: city,
    post_code: zipCode,
    street,
    supplement,
    region: district
  }

  return {
    full_name: name,
    email,
    birth_date: birthDate && moment(birthDate).format('YYYY-MM-DD'),
    document: identificationDocument,
    telephone: number,
    comment: comments,
    address: country ? address : undefined,
    additional_information: {
      emergency_telephone: emergencyTelephone,
      emergency_name: emergencyName,
      weight: weight && replaceCommaToDot(weight),
      height: height && Number(height.replace(',', ''))
    }
  }
}

export const reservationCreationModel = (payload = {}, companyId, serviceId, type) => {
  const {
    reservationHeader: { hour, date, partner, agent } = {},
    tickets = [],
    reservationType: { id, name, email, identificationDocument, birthDate, number, country, state, city, zipCode, street, supplement, district } = {},
    comments = '',
    affiliateCode = ''
  } = payload

  const ticketsArray = buildTickets(tickets, companyId, serviceId, date, hour, type)
  const payerParams = payerInfoModel(payload.reservationType)
  const anyPayerParamsIsFilled = id || name || email || birthDate || identificationDocument || number || country || state || city || zipCode || street || supplement || district

  const parsedValues = {
    comment: comments,
    affiliate_code: affiliateCode,
    status: 'reserved',
    commercial_partner_id: partner,
    tickets: ticketsArray,
    payer_params: anyPayerParamsIsFilled ? payerParams : undefined,
    user_id: agent || undefined
  }

  return removeEmptyAttribute(parsedValues)
}

export const ReservationModel = {
  onlyTickets (payload = {}, companyId, serviceId, type) {
    const {
      reservationHeader: { hour, date, agent } = {},
      tickets = []
    } = payload

    const ticketsArray = buildTickets(tickets, companyId, serviceId, date, hour, type)

    return {
      user_id: agent || undefined,
      tickets: ticketsArray
    }
  }
}

export const reservationConfirmEmailModel = (emailData = {}) => {
  const {
    reservationId: reservation_id,
    payerName: payer_name,
    email
  } = emailData

  return {
    reservation_id,
    payer_name,
    email
  }
}

export const ekoLocatorModel = (ekoLocator = '') => ({
  extra_infos: { eko_locator: ekoLocator }
})

export const reservationsVisualizedModel = ({ initialDate, finalDate, isAppViewed, reservationStatuses, page, perPage }) => removeEmptyAttribute({
  initial_date: initialDate,
  final_date: finalDate,
  visualized_in_ally: isAppViewed,
  reservation_statuses: reservationStatuses,
  page,
  per_page: perPage
})

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Button } from '@bonitour/components'
import { modalText, quantityStyle, ticketPhraseStyle } from './SelectedTicketsModal.style'
import { identity } from '@bonitour/common-functions'
import { TicketCountModal } from 'components/TicketCountModal/TicketCountModal'

export const SelectedTicketsModal = ({ quantity, onValidateSubmit = identity }) => {
  const selectedTicketPhrase = quantity === 1 ? 'Item selecionado' : 'Itens selecionados'
  return (
    <TicketCountModal quantity={quantity}>
      <div css={modalText}>
        <span css={quantityStyle}>{quantity}</span>
        <p css={ticketPhraseStyle}>{selectedTicketPhrase}</p>
      </div>
      <Button onClick={onValidateSubmit}>Utilizar ingressos</Button>
    </TicketCountModal>

  )
}

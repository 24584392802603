/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { BREAK_POINTS, colors, flexRow, Input, InputFormGroup, LightWarningAlert, LoadingAnimation } from '@bonitour/components'
import { marginRight, marginBottom, marginLeft, loadingContainerOverlay } from 'assets/styles/global'
import { identity, head } from '@bonitour/common-functions'

const container = css`
  ${flexRow};
  ${marginBottom(20)};
  flex-wrap: wrap;

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    justify-content: center;
  }
`

const inputGroup = css`
  width: 100%;
  ${marginRight(20)};

  &:last-child {
    ${marginRight(0)};
  }

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    ${marginRight(0)};

    &:last-child {
      ${marginRight(0)};
    }
  }
`

const littleInput = css`
  width: 170px;
`

const ageRange = css`
  ${marginLeft(5)};
  ${marginBottom(5)};
  font-size: 12px;
  font-weight: normal;
  color: ${colors.gray3};
`

const capacityInfo = css`
  align-self: flex-end;
  padding-bottom: 10px;
  font-size: 14px;
  font-weight: normal;
  color: ${colors.gray3};
`

const totalCapacity = css`
  color: ${colors.primary};
`

const bold = css`
  font-weight: bold;
`

const OfflineExperiencePaxSection = ({
  form = {},
  errors = {},
  emitChangeEvent = identity,
  emitBlurEvent = identity,
  disabled = false
}) => {
  return (
    <div css={[inputGroup, littleInput]}>
      <InputFormGroup
        label='Offline'
        errorMessage={errors.count}
      >
        <span css={ageRange}>Quantidade de ingressos</span>
        <Input
          type='number'
          min='0'
          value={form.count}
          css={littleInput}
          onChange={emitChangeEvent('count')}
          onBlur={emitBlurEvent('count')}
          disabled={disabled}
        />
      </InputFormGroup>
    </div>
  )
}

export const PaxSectionBody = ({
  tickets = [],
  ticketsErrors = [],
  offlineReservation = {},
  offlineReservationErrors = {},
  onChange: emitChangeEvent = identity,
  onBlur: emitBlurEvent = identity,
  isOfflineExperience = false,
  disabled = false,
  isLoadingTickets = false,
  ...other
}) => {
  const hasPrivateFee = head(tickets)?.name === 'Privativo'
  const ticketsList = hasPrivateFee ? [head(tickets)] : tickets
  return (
    <div css={container} {...other}>
      {isLoadingTickets && (
        <LoadingAnimation css={loadingContainerOverlay} />
      )}
      {!isOfflineExperience && !isLoadingTickets && !ticketsList.length && (
        <LightWarningAlert>Não há tarifários disponíveis</LightWarningAlert>
      )}
      {isOfflineExperience
        ? (
          <OfflineExperiencePaxSection
            emitChangeEvent={emitChangeEvent}
            emitBlurEvent={emitBlurEvent}
            form={offlineReservation}
            errors={offlineReservationErrors}
            disabled={disabled}
          />
        )
        : ticketsList.map(({ fee_type_id: feeTypeId, quantity, name, minAge, maxAge, description: { capacity } }, index) => (
          <>
            <div css={[inputGroup, littleInput]}>
              <InputFormGroup
                id={feeTypeId}
                label={isOfflineExperience ? 'Offline' : name}
                errorMessage={ticketsErrors[index] && ticketsErrors[index].quantity}
              >
                <span css={ageRange}>{`${minAge} a ${maxAge} anos`}</span>
                <Input
                  type='number'
                  min='0'
                  value={quantity}
                  css={littleInput}
                  onChange={emitChangeEvent(`tickets.${index}.quantity`)}
                  onBlur={emitBlurEvent(`tickets.${index}.quantity`)}
                  disabled={disabled}
                />
              </InputFormGroup>
            </div>
            {name === 'Privativo' && <span css={capacityInfo}>Cada ingresso disponibiliza a capacidade de até <span css={bold}>{capacity}</span> pessoas, totalizando as vagas para até <span css={[totalCapacity, bold]}>{quantity * capacity}</span> pessoas.</span>}
          </>
        ))}
    </div>
  )
}

import { useToast } from '@bonitour/components'
import { useCallback, useState } from 'react'
import { ReservationService } from 'services/Reservations/Service'
import { SafeDate } from '@binamik/js-providers'
import { useQuery } from 'react-query'
import { usePermissions } from 'hooks/usePermissions'
import moment from 'moment'
import { StatusEnum } from 'constants/reservationsStatus'

const DEFAULT_FILTERS = {
  initialDate: moment(new SafeDate().isoDate, 'YYYY-MM-DD'),
  finalDate: moment(new SafeDate().isoDate, 'YYYY-MM-DD'),
  isAppViewed: null,
  reservationStatuses: [StatusEnum.confirmed]
}

const DEFAULT_PAGINATION = { currentPage: 1, perPage: 10 }

const STALE_TIME = 1000 * 60 * 10

export const useListReservationsVisualized = () => {
  const { add: addToast } = useToast()
  const { handlePermission } = usePermissions()

  const [pagination, setPagination] = useState(DEFAULT_PAGINATION)
  const [filters, setFilters] = useState(DEFAULT_FILTERS)

  const onChangePagination = useCallback((newPagination = {}) => setPagination(oldPagination => {
    const isChangePerPage = Boolean(newPagination?.perPage)
    if (isChangePerPage) {
      return { ...newPagination, currentPage: 1 }
    }
    return { ...oldPagination, ...newPagination }
  }), [])

  const onError = useCallback((error) => {
    const hasPermission = handlePermission(
      error,
      'Você não possui permissão de acesso ao relatório de check-in pendente'
    )
    hasPermission && addToast('Ocorreu um erro ao realizar a busca')
  }, [addToast, handlePermission])

  const {
    data: visualizedReservationsData,
    isLoading,
    isFetching
  } = useQuery([
    'visualizedReservations',
    JSON.stringify({
      filters,
      pagination
    })
  ],
  async () => ReservationService.getVisualizedReservations({
    page: pagination.currentPage,
    perPage: pagination.perPage,
    ...filters
  }),
  {
    staleTime: STALE_TIME,
    onError,
    retry: 2
  })

  const onFilterChange = useCallback((newFilters = {}) => setFilters(oldFilters => ({ ...oldFilters, ...newFilters })), [])

  return {
    isLoading: isLoading || isFetching,
    onChangePagination,
    visualizedReservations: visualizedReservationsData?.reservations || [],
    meta: {
      ...(visualizedReservationsData?.meta || {}),
      perPage: pagination.perPage
    },
    filters,
    onFilterChange
  }
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'

import { useCallback, memo } from 'react'

import { CombinedExperienceSummaryCellGroup } from '../CombinedExperienceSummaryCellGroup'
import { BlockedSummaryCell } from '../../../BodyCell/BlockedSummaryCell'

import { flexColumn, increasedWidth, padding0, w100 } from './combinedExperienceSummary.styles'

const ExperienceSummary = ({
  activitiesData = [],
  dateColumns = [],
  date = '',
  activitiesFees = []
}) => {
  const getCanSetStaticWidth = useCallback((currentActivityId) => {
    if (activitiesData.length === 1) return true
    for (const activityData of activitiesData) {
      if (activityData.activityId !== currentActivityId && Boolean(activityData.slots?.[date])) {
        return false
      }
    }
    return true
  }, [activitiesData, date])

  const getBlockedSummaryAttrs = useCallback((activityData) => {
    const canShowBlockedSummary = !activityData || !activityData?.vacancies?.isAvailable

    const isEnabledStaticSummaryWidth = canShowBlockedSummary && getCanSetStaticWidth(activityData?.activityId)

    return [canShowBlockedSummary, isEnabledStaticSummaryWidth]
  }, [getCanSetStaticWidth])

  const activityFees = useCallback((activityId) => {
    const fees = activitiesFees.find(({ id }) => id === activityId)
    if (fees) {
      return fees
    }
    const activity = activitiesData.find(({ activityId: id }) => id === activityId)
    return {
      id: activity.activityId,
      fees: [],
      color: activity.color,
      serviceTitle: activity.name,
      companyName: activity.companyName,
      serviceImage: activity.image,
      isOptional: activity.isOptional
    }
  }, [activitiesFees, activitiesData])

  return (
    <div css={[flexColumn, increasedWidth]}>
      {activitiesData.map((activityData, activityIndex) => (
        <CombinedExperienceSummaryCellGroup
          key={`experience-summary-${activityData.activityId}-${activityIndex}-${activityData.hour}`}
          getBlockedSummaryAttrs={getBlockedSummaryAttrs}
          dateColumns={dateColumns}
          date={date}
          activityData={activityData}
          detailedActivityData={activityFees(activityData.activityId)}
          limitOfDay={activityData.limitOfDay}
        >
          <BlockedSummaryCell
            key={`vacancy-unavailable-${activityData.activityId}-${activityIndex}-${activityData.hour}`}
            canMinify={true}
            getBlockedSummaryAttrs={getBlockedSummaryAttrs}
            customCss={[padding0, w100]}
          />
        </CombinedExperienceSummaryCellGroup>
      ))}
    </div>
  )
}

export const CombinedExperienceSummary = memo(ExperienceSummary)

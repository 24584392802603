/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/react'
import { useCallback, useMemo } from 'react'

import { useBookingMapInfo } from 'domains/Booking/Map/contexts/useBookingMapInfo'

import { ServiceCombinedExperienceCardInfo, ServiceCombinedExperienceHeader } from './components'
import { LimberSection } from 'containers/Booking/Form/LimberSection/LimberSection'
import OptionalIndication from '../OptionalIndication'

import { LIMBER_EXPERIENCE_TYPE } from 'constants/activityTypes'

import {
  serviceCombinedExperienceCard,
  ServiceCombinedExperienceListContainer
} from './ServiceCombinedExperienceList.style'

/**
* ServiceCombinedExperienceList component renders a list of combined experiences.
*
* @param {Object} props - The component props.
* @param {Array} props.experiences - The list of experiences.
* @param {Array} props.customCss - Custom CSS styles.
* @param {string} props.color - The color theme.
* @param {boolean} props.canShowTotals - Flag to show totals.
* @returns {JSX.Element} The rendered component.
*/
export const ServiceCombinedExperienceList = ({
  experiences = [],
  customCss = [],
  color = '',
  canShowTotals = false,
  pickupPlaceIdByService = null,
  changePickupPlaceId
}) => {
  const {
    activitiesFeesPrices = {}
  } = useBookingMapInfo()

  const hasSomeActivitySelected = useMemo(() => Object.keys(activitiesFeesPrices).length, [activitiesFeesPrices])

  const getHasPrice = useCallback(({ serviceId }) => {
    const activityFeeData = activitiesFeesPrices?.[serviceId]
    const hasActivityOnFees = activityFeeData
    const hasQuantity = activityFeeData?.total > 0
    return canShowTotals && hasActivityOnFees && hasQuantity
  }, [activitiesFeesPrices, canShowTotals])

  const changeSpecificPickupPlaceId = useCallback((serviceId) => (newPickupPlaceId) => {
    changePickupPlaceId(newPickupPlaceId, serviceId)
  }, [changePickupPlaceId])

  return (
    <ServiceCombinedExperienceListContainer css={customCss}>
      {experiences.map((experience, index) => (
        <div
          css={serviceCombinedExperienceCard}
          key={`combined-experience-card-${experience.id}-${index}`}
          className={canShowTotals && !(getHasPrice({ serviceId: experience.serviceId }) && hasSomeActivitySelected) ? 'fake-has-price-size' : ''}
        >
          <OptionalIndication isOptional={experience?.isOptional} index={experience.index || index}/>
          <ServiceCombinedExperienceHeader
            color={color}
            experienceInfo={experience}
            index={index}
          />
          {getHasPrice({ serviceId: experience.serviceId })
            ? (
              <ServiceCombinedExperienceCardInfo
                experiencePaxInfo={activitiesFeesPrices[experience.serviceId]}
                index={index}
              />
            )
            : null}
          {experience.type === LIMBER_EXPERIENCE_TYPE && pickupPlaceIdByService &&
          <div>
            <LimberSection
              limberData={experience.limberData}
              pickupPlaceId={pickupPlaceIdByService[experience.serviceId]}
              onChange={changeSpecificPickupPlaceId(experience.serviceId)}
              serviceId={experience.serviceId}
              bookingMapStyle
              combinedStyle
            />
          </div>
          }
        </div>
      ))}
    </ServiceCombinedExperienceListContainer>
  )
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Card, H2, InputFormGroup, Input, Row, CircleThumbnail, flexColumn, colors, BREAK_POINTS } from '@bonitour/components'
import { hidden, marginBottom, marginRight } from 'assets/styles/global'
import { Tickets } from 'containers/Reservation/Tickets'
import { cardContrast, width110, noMargin, activityPickupPlaceName, activityTotalStyle, baseInfoStyle } from './TicketActivitySummary.style'
import { identity, formatMoney, head, tail } from '@bonitour/common-functions'
import { formatISOTime } from 'utils/time'
import { useMemo } from 'react'

const { REACT_APP_API_HOST } = process.env

const marginBottom20 = marginBottom(20)
const marginRight20 = marginRight(20)

const capacityInfo = css`
  align-self: flex-end;
  padding-bottom: 15px;
  font-size: 14px;
  font-weight: normal;
  color: ${colors.gray3};
  
  @media (max-width: ${BREAK_POINTS.tablet}) {
    padding: 15px 0;
  }
`

const totalCapacity = css`
  color: ${colors.primary};
`

const bold = css`
  font-weight: bold;
`

export const activityHeader = css`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    width: initial;
    flex-direction: column;
    justify-content: flex-start;
  }
`

const activityImage = css`
  min-width: 60px;
  min-height: 60px;
  margin-right: 20px;
`

export const TicketActivitySummary = ({
  serviceTypeFilters,
  reservation = [],
  activityData = [],
  pickupTickets = [],
  activityDate = '',
  reservationId = '',
  companyId = '',
  deleteTicketLoading = false,
  ticketConfirmLoading = false,
  linkPassengerLoading = false,
  pickupLocalLoading = false,
  observationLoading = false,
  onEdit = identity,
  onTicketDelete = identity,
  onTicketPrint = identity,
  onTicketPrintVoucherTuris = identity,
  onTicketConfirm = identity,
  onLinkPassenger = identity,
  onUnlinkPassenger = identity,
  onEditPassenger = identity,
  onLinkPickup = identity,
  onUnlinkPickup = identity,
  onEditClickPickup = identity,
  onOpenObservationModal = identity,
  clearObservation = identity,
  onTicketUpdate = identity
}) => {
  const isActivityVisible = activityType => serviceTypeFilters.includes(activityType) || !serviceTypeFilters.length
  const hasFiscalDuty = useMemo(() => Boolean(reservation?.fiscalDuty), [reservation?.fiscalDuty])

  const hasActivityType = useMemo(() => {
    const activityTypeFiltered = Object.entries(activityData).filter(activity => isActivityVisible(tail(activity)?.type))
    return activityTypeFiltered.length > 0
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityData, serviceTypeFilters])

  return (
    <>
      {hasActivityType && <H2>{formatISOTime(activityDate)}</H2>}
      {Object.entries(activityData).map(activity => (
        <Card css={[marginBottom20, !isActivityVisible(tail(activity)?.type) && hidden]} key={head(activity)}>
          <div css={baseInfoStyle}>
            <CircleThumbnail size={60} image={`${REACT_APP_API_HOST}${tail(activity).image}`} css={activityImage} />
            <div css={activityHeader}>
              <div css={flexColumn}>
                <H2 css={noMargin}>{tail(activity).title}</H2>
                <H2 css={noMargin}>{tail(activity).vendorName}</H2>
                {tail(activity).pickupPlace?.name && (
                  <span css={activityPickupPlaceName}>Embarque: {tail(activity).pickupPlace?.name}</span>
                )}
              </div>
              <span css={activityTotalStyle}>{formatMoney(tail(activity).totalAmount)}</span>
            </div>
          </div>

          <Card css={cardContrast}>
            <Row>
              {
                Object.entries(tail(activity).pax).map(([ticketName, ticketValue]) => (
                  <>
                    <InputFormGroup css={[width110, marginRight20]} label={ticketName} key={ticketName}>
                      <Input readOnly value={ticketValue} />
                    </InputFormGroup>
                    {ticketName === 'Privativo' &&
                      <span css={capacityInfo}>
                        {'Cada ingresso disponibiliza a capacidade de até '}
                        <span css={bold}>{tail(activity).tickets[0].capacity}</span>
                        {' pessoas, totalizando as vagas para até '}
                        <span css={[totalCapacity, bold]}>{ticketValue * tail(activity).tickets[0].capacity}</span>
                        {' pessoas.'}
                      </span>}
                  </>
                ))
              }
            </Row>
          </Card>
          <Tickets
            activityType={tail(activity)?.type}
            tickets={tail(activity)?.tickets}
            serviceId={tail(activity)?.serviceId}
            pickupTickets={pickupTickets}
            reservationId={reservationId}
            onTicketEdit={onEdit}
            onTicketDelete={onTicketDelete}
            deleteTicketLoading={deleteTicketLoading}
            onTicketPrint={onTicketPrint}
            onTicketPrintVoucherTuris={onTicketPrintVoucherTuris}
            reservation={reservation}
            companyId={companyId}
            onTicketConfirm={onTicketConfirm}
            onLinkPassenger={onLinkPassenger}
            onUnlinkPassenger={onUnlinkPassenger}
            onEditPassenger={onEditPassenger}
            onLinkPickup={onLinkPickup}
            onUnlinkPickup={onUnlinkPickup}
            onEditClickPickup={onEditClickPickup}
            ticketConfirmLoading={ticketConfirmLoading}
            linkPassengerLoading={linkPassengerLoading}
            pickupLocalLoading={pickupLocalLoading}
            onOpenObservationModal={onOpenObservationModal}
            clearObservation={clearObservation}
            observationLoading={observationLoading}
            onTicketUpdate={onTicketUpdate}
            hasFiscalDuty={hasFiscalDuty}
          />
        </Card>
      ))}
    </>
  )
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { useCallback, useEffect } from 'react'
import { jsx, css } from '@emotion/core'
import { Card, colors, LoadingAnimation, useToast } from '@bonitour/components'
import { identity, unformatMoney } from '@bonitour/common-functions'

import { FormHeader } from 'components/FormHeader'
import { FeePaxType } from 'containers/ActivityFeeForm/FeePaxType/FeePaxType'
import { FeeBasicForm } from 'containers/ActivityFeeForm/FeeBasic/FeeBasicForm'
import { useForm } from 'react-hook-form'
import { FeeByDemandForm } from 'containers/ActivityFeeForm/FeeByDemand/FeeByDemandForm'

import { feeSchema, validateFeeTypes } from './ActivityFeeForm.schema'
import { yupResolver } from '@hookform/resolvers/yup'
import { TarifPeriods } from 'containers/Periods/TarifPeriods'
import { feeLoading } from 'app/Fee/EditFee.styles'

const container = css`
  color: ${colors.gray3};
`

export const ActivityFeeForm = ({
  title = 'Criar tarifário',
  activityFee,
  activityMinAge = 0,
  onSuccess: emitSuccessEvent = identity,
  onBackClick: emitBackClickEvent = identity,
  onValidationError: emitValidationError = identity,
  disabled = false
}) => {
  const calculateFeePrices = useCallback(
    (form = {}, newBasePrice) => {
      if (!activityFee) return false

      const feePaxTypes = form?.feePaxTypes ?? activityFee?.feePaxTypes
      const basePrice = Number((newBasePrice ?? activityFee?.feeBasic?.price) || 0)

      const feePaxTypesMap = feePaxTypes?.reduce((acc, current) => {
        const safePrice = Math.round(Number(current?.feeModificator || 0) * basePrice) / 100
        const safeGuidePrice = Math.round(Number(current?.taxes?.guide || 0) * basePrice) / 100
        const calculatedPrice = (safePrice + (newBasePrice === undefined ? safeGuidePrice : 0))
        const totalFeeModificator = Number(current?.feeModificator) + (
          newBasePrice === undefined
            ? Number(current?.taxes?.guide || 0)
            : 0
        )

        return [
          ...acc, {
            ...current,
            price: safePrice,
            guidePrice: safeGuidePrice,
            calculatedPrice,
            feeModificator: totalFeeModificator
          }
        ]
      }, [])

      const feesByDemands = form?.feesByDemands ?? activityFee?.feesByDemands

      const feesByDemandsWithFinalValue = feesByDemands?.map(
        (fee) => {
          const valueModificator = fee?.valueModificator / 100
          const multiplier = fee?.operator === 'decrease' ? 1 - (valueModificator) : 1 + (valueModificator)
          const finalValue = basePrice * multiplier

          return {
            limit: fee?.limit,
            operator: fee?.operator,
            valueModificator: fee?.valueModificator,
            finalValue
          }
        }
      )

      return {
        ...activityFee,
        feeBasic: {
          price: basePrice,
          name: form?.feeBasic?.name || activityFee?.feeBasic?.name
        },
        feesByDemands: feesByDemandsWithFinalValue,
        feePaxTypes: feePaxTypesMap
      }
    }
    , [activityFee]
  )

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
    getValues
  } = useForm({
    resolver: yupResolver(feeSchema(activityMinAge)),
    mode: 'onBlur',
    defaultValues: {}
  })

  const feeBasic = watch('feeBasic')
  const feePaxTypes = watch('feePaxTypes')

  useEffect(
    () => {
      if (!feeBasic) {
        reset(calculateFeePrices(getValues()))
      }
    }
    , [calculateFeePrices, feeBasic, getValues, reset]
  )

  const { add: addToast } = useToast()

  const formValidate = useCallback(data => {
    const { feePaxTypes } = data
    const { minAgeError, conflictError, gapError } = validateFeeTypes(
      activityMinAge, feePaxTypes
    ) || {}

    if (minAgeError) {
      return addToast(`Não há tipificação para idade mínima definida na experiência (${activityMinAge} anos)`)
    }

    if (conflictError) {
      return addToast('A tipificação de PAX está com idades conflitantes')
    }

    if (gapError) {
      return addToast('A configuração de PAX está com intervalos vagos entre idades')
    }

    emitSuccessEvent(data)
  }, [activityMinAge, addToast, emitSuccessEvent])

  const validateForm = handleSubmit(formValidate, emitValidationError)

  const onChangeBasePrice = useCallback(
    (money) => {
      const safeValue = unformatMoney(money)

      reset({ ...calculateFeePrices(getValues(), safeValue) })
    }
    , [calculateFeePrices, getValues, reset]
  )

  if (!feeBasic) {
    return (
      <LoadingAnimation css={[feeLoading]} />
    )
  }

  return (
    <>
      <FormHeader title={title} ghostClick={emitBackClickEvent} ctaClick={validateForm} disabled={disabled}/>

      <Card css={container}>
        <FeeBasicForm
          feeBasicErrors={errors?.feeBasic}
          register={register}
          control={control}
          onChangeBasePrice={onChangeBasePrice}
        />
        <TarifPeriods
          label='Períodos da tarifa'
          placeholder='Ex. Baixa Temporada'
          errors={errors?.periodList}
          register={register}
          control={control}
        />
        <FeeByDemandForm
          feePrice={feeBasic?.price}
          feesByDemandErrors={errors?.feesByDemands}
          control={control}
          reset={reset}
        />
        <FeePaxType
          activityMinAge={activityMinAge}
          feePrice={feeBasic?.price}
          feePaxTypesErrors={errors?.feePaxTypes}
          control={control}
          register={register}
          feePaxTypes={feePaxTypes}
        />
      </Card>
    </>
  )
}

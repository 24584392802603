/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/react'

import { formatMoney } from '@bonitour/common-functions'

import { Label } from '@bonitour/components'

import { actionBarTotalizerContainer, actionBarTotalsContainer } from './ActionBar.style'

const ActionBarTotals = ({ totalPaxSelected = 0, totalPrice = 0 }) => {
  return (
    <span css={actionBarTotalsContainer}>
      <label>{totalPaxSelected}</label>
      <label>{formatMoney(totalPrice)}</label>
    </span>
  )
}

export const ActionBarTotalizer = ({
  totalPaxSelected = 0,
  totalPrice = 0
}) => {
  return (
    <div css={actionBarTotalizerContainer}>
      <Label>
        Total de ingressos combinados:
      </Label>
      <ActionBarTotals
        totalPaxSelected={totalPaxSelected}
        totalPrice={totalPrice}
      />
    </div>
  )
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { TicketActivity } from 'containers/Reservation/TicketActivity'
import { identity } from '@bonitour/common-functions'
import { useCallback, useEffect, useState } from 'react'
import { BookingMapModal } from 'domains/Booking/Map/BookingMapModal'
import { TicketSummary } from './Tickets/TicketSummary'
import { BookingEditionMapModal } from 'domains/Booking/Map/BookingEditionMapModal'
import { useTicketEdit } from './Tickets/context/TicketEditContext'
import { usePassenger } from '../Passenger/hooks/usePassenger'
import { LinkPassengerSelection } from '../Passenger/LinkPassengerSelection'
import { PassengerFormModal } from '../Passenger/PassengerFormModal'
import { usePickup } from '../Pickup/hooks/usePickup'
import { PickupModal } from '../Pickup/PickupModal'
import { useToast } from '@bonitour/components'
import { ObservationModal } from '../Observation/ObeservationModal'
import { useObservation } from '../Observation/hooks/useObservation'
import { BookingMapInfoProvider } from 'domains/Booking/Map/contexts/useBookingMapInfo'

export const ReservationTicketApp = ({
  tickets = {},
  reservationTickets = [],
  reservation = [],
  reservationPassengers = [],
  total = 0,
  reservationId = '',
  reservationCode = '',
  companyId = '',
  deleteTicketLoading = false,
  ticketConfirmLoading = false,
  reservationPassengersLoading = false,
  reservationTicketLoading = false,
  onTicketDelete = identity,
  onTicketPrint = identity,
  onTicketPrintVoucherTuris = identity,
  onTicketConfirm = identity,
  fetchReservationPassengers = identity,
  fetchReservationTickets = identity
}) => {
  const { add: addToast } = useToast()
  const [isModalVisible, setModalVisibility] = useState(false)
  const toggleModalVisibility = () => setModalVisibility((previous) => !previous)
  const onModalClose = () => setModalVisibility(false)
  const {
    isTicketEditModalVisible,
    ticketList,
    editServiceId,
    openEditionModal,
    closeEditionModal,
    onEditionSubmitted
  } = useTicketEdit()
  const {
    passenger,
    passengersFound,
    onPassengerSelection,
    isLinkPassengerModalVisible,
    isPassengerFormVisible,
    isEditPassenger,
    searchPassengerLoading,
    linkPassengerLoading,
    onCloseLinkPassengerModal,
    onClickNewPassenger,
    onClickNewDependent,
    onClosePassengerForm,
    onBackPassengerForm,
    onCreatePassenger,
    onEditPassenger,
    onLinkPassenger,
    onSearchPassengers,
    onUnlinkPassenger,
    onClickEditPassenger
  } = usePassenger(reservation, fetchReservationTickets)
  const {
    isPickupModalVisible,
    onClosePickupModal,
    onClickPickup,
    onUnlinkPickup,
    editPickup,
    getPickup,
    pickupTickets,
    loading: pickupLocalLoading,
    onEditClickPickup,
    pickup,
    createPickup,
    isPickupCreationOrEdition
  } = usePickup(fetchReservationTickets)

  const {
    isObservatioModalVisible,
    onCloseObservationModal,
    onOpenObservationModal,
    updateObservation,
    observation,
    loading: observationLoading
  } = useObservation(reservationTickets, fetchReservationTickets)

  const onSuccess = async (data) => {
    try {
      onClosePickupModal()
      isPickupCreationOrEdition === 'create' ? await createPickup(data) : await editPickup(data)
      fetchPickupDropoff()
      addToast('Local definido com sucesso', 'success')
    } catch {
      addToast('Ocorreu um erro na definição do local')
    }
  }

  const clearObservation = useCallback((id) => updateObservation('', id), [updateObservation])

  const onUpdateObservation = useCallback((form) => updateObservation(form.observation), [updateObservation])

  useEffect(() => {
    fetchReservationTickets()
  }, [fetchReservationTickets])

  useEffect(() => {
    reservationId && fetchReservationPassengers(reservationId)
  }, [fetchReservationPassengers, reservationId, isLinkPassengerModalVisible])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchPickupDropoff = useCallback(() => getPickup(), [])

  useEffect(() => {
    fetchPickupDropoff()
  }, [fetchPickupDropoff])

  return (
    <>
      <TicketSummary
        total={total}
        reservation={reservation}
        onActivitySelect={toggleModalVisibility}
        onTicketUpdate={fetchReservationTickets}
      />
      <TicketActivity
        onTicketDelete={onTicketDelete}
        onTicketUpdate={fetchReservationTickets}
        deleteTicketLoading={deleteTicketLoading}
        reservationTicketLoading={reservationTicketLoading}
        onEdit={openEditionModal}
        reservationTickets={reservationTickets}
        onTicketPrint={onTicketPrint}
        onTicketPrintVoucherTuris={onTicketPrintVoucherTuris}
        reservation={reservation}
        companyId={companyId}
        onTicketConfirm={onTicketConfirm}
        onLinkPassenger={onPassengerSelection}
        onUnlinkPassenger={onUnlinkPassenger}
        onEditPassenger={onClickEditPassenger}
        onLinkPickup={onClickPickup}
        onUnlinkPickup={onUnlinkPickup}
        onEditClickPickup={onEditClickPickup}
        ticketConfirmLoading={ticketConfirmLoading}
        linkPassengerLoading={linkPassengerLoading}
        pickupLocalLoading={pickupLocalLoading}
        reservationId={reservationId}
        pickupTickets={pickupTickets}
        onOpenObservationModal={onOpenObservationModal}
        clearObservation={clearObservation}
        observationLoading={observationLoading}
      />
      <BookingMapInfoProvider isCommonBookingMap={false}>
        <BookingMapModal
          date={tickets.firstDate}
          onModalClose={onModalClose}
          reservationCode={reservationCode}
          isVisible={isModalVisible}
        />
      </BookingMapInfoProvider>
      <BookingEditionMapModal
        date={tickets.firstDate}
        isVisible={isTicketEditModalVisible}
        onModalClose={closeEditionModal}
        serviceId={editServiceId}
        ticketsIds={ticketList}
        reservationCode={reservationCode}
        onEditionSubmitted={onEditionSubmitted}
        reservationTickets={reservationTickets}
        selectedTicketsIds={ticketList}
      />
      <LinkPassengerSelection
        reservation={reservation}
        reservationPassengers={reservationPassengers}
        reservationPassengersLoading={reservationPassengersLoading}
        isVisible={isLinkPassengerModalVisible}
        onClickNewPassenger={onClickNewPassenger}
        onClickNewDependent={onClickNewDependent}
        onClose={onCloseLinkPassengerModal}
        onLinkPassenger={onLinkPassenger}
        onSearchPassengers={onSearchPassengers}
        passengersFound={passengersFound?.passengers}
        searchPassengerLoading={searchPassengerLoading}
      />
      <PassengerFormModal
        passenger={passenger}
        isVisible={isPassengerFormVisible}
        isPersonalFieldsDisabled={isEditPassenger}
        onClose={onClosePassengerForm}
        onBack={isEditPassenger ? onClosePassengerForm : onBackPassengerForm}
        onSubmit={isEditPassenger ? onEditPassenger : onCreatePassenger}
      />
      <PickupModal
        isPickupModalVisible={isPickupModalVisible}
        onClose={onClosePickupModal}
        onSuccess={onSuccess}
        pickup={pickup}
      />
      <ObservationModal
        isObservationModalVisible={isObservatioModalVisible}
        onClose={onCloseObservationModal}
        onSuccess={onUpdateObservation}
        observation={observation}
      />
    </>
  )
}

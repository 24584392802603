/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import {
  Card,
  flex,
  flexColumnCentered,
  H3,
  HorizontalInputFormGroup,
  Input,
  InputFormGroup,
  InputMoneyMask,
  InputWithSuffix,
  P,
  Select,
  ToggleInputGroup
} from '@bonitour/components'
import { identity, unformatMoney } from '@bonitour/common-functions'
import { SwatchImage } from 'components/SwatchImage'
import { useCallback, useMemo, useState } from 'react'
import {
  activeHeight,
  agePermissionTitle,
  ageValuesContainer,
  disabledHeight,
  feePaxTypeCard,
  finalValueGuide,
  guideContainer,
  guidePercentual,
  guideValue,
  horinzontalMargin10,
  inputLabel,
  inputStyle,
  marginBottom10,
  marginTop10,
  marginTop15,
  modificator,
  toolTipHeight,
  width80,
  guideAnimation,
  privateHeight,
  fontSize28,
  activeTooltipHeight
} from './FeePaxTypeRow.style'
import { guideTaxesDestinationOptions } from 'constants/guideTaxesDestination'
import { width100 } from 'assets/styles/global'
import { Controller, useFieldArray } from 'react-hook-form'
import { ADITIONAL_PAX, FEE_TYPES, PAX_TYPE_NEED_VALIDATION, PAX_TYPE_WHEN_IS_PRIVATE } from 'constants/fees'

export const FeePaxTypeRow = ({
  feePrice = 0,
  feePaxTypeErrors = [],
  indexName = '',
  tooltip = '',
  register,
  control,
  feePaxTypes
}) => {
  const { append, remove, update, replace: replaceFeePaxTypes } = useFieldArray({
    control,
    name: 'feePaxTypes'
  })

  const feePaxTypeIndex = useMemo(() => feePaxTypes.findIndex(({ name }) => name === indexName), [feePaxTypes, indexName])

  const feePaxType = useMemo(() => feePaxTypes[feePaxTypeIndex], [feePaxTypeIndex, feePaxTypes])
  const isPrivate = useMemo(() => indexName === 'Privativo', [indexName])

  const {
    feeModificator = 100,
    minAge = 18,
    maxAge = 60,
    taxes = {},
    capacity = 1,
    calculatedPrice = feePrice,
    guidePrice = 0
  } = feePaxType || {}

  const { guide = 0, bearer = '' } = taxes || {}
  const { taxes: taxesErrors = {}, guidePrice: guidePriceErrors } = feePaxTypeErrors[feePaxTypeIndex] || {}

  const isActive = useMemo(() => Boolean(feePaxType), [feePaxType])
  const feeDefault = useMemo(
    () => ({ name: indexName, price: feePrice, feeModificator, minAge, maxAge })
    , [feeModificator, feePrice, indexName, maxAge, minAge]
  )

  const [hasGuideTax, setHasGuideTax] = useState(parseInt(guide) !== 0)

  const handleAddFeePaxType = useCallback(
    () => {
      if (indexName === FEE_TYPES?.PRIVATE) {
        const updatedFeePaxTypes = feePaxTypes?.filter(
          ({ name }) => ![...PAX_TYPE_WHEN_IS_PRIVATE, FEE_TYPES?.SINGLE].includes(name)
        )
        replaceFeePaxTypes(updatedFeePaxTypes)
      }

      if (PAX_TYPE_NEED_VALIDATION.includes(indexName)) {
        const updatedFeePaxTypes = feePaxTypes?.filter(
          ({ name }) => ![FEE_TYPES?.PRIVATE, FEE_TYPES?.SINGLE].includes(name)
        )
        replaceFeePaxTypes(updatedFeePaxTypes)
      }

      if (indexName === FEE_TYPES?.SINGLE) {
        const updatedFeePaxTypes = feePaxTypes?.filter(
          ({ name }) => ![...PAX_TYPE_NEED_VALIDATION, FEE_TYPES?.PRIVATE].includes(name)
        )
        replaceFeePaxTypes(updatedFeePaxTypes)
      }

      if (ADITIONAL_PAX.includes(indexName)) {
        const updatedFeePaxTypes = feePaxTypes?.filter(
          ({ name }) => FEE_TYPES?.PRIVATE !== name
        )
        replaceFeePaxTypes(updatedFeePaxTypes)
      }

      append(feeDefault)
    },
    [append, feeDefault, feePaxTypes, indexName, replaceFeePaxTypes]
  )

  const handleRemoveFeePaxType = useCallback(
    () => {
      setHasGuideTax(false)
      remove(feePaxTypeIndex)
    },
    [feePaxTypeIndex, remove]
  )

  const updateFeePaxTypeField = useCallback(
    (updates) => {
      const currentFeePaxType = feePaxTypes[feePaxTypeIndex]

      const deepMerge = (target, source) => {
        const result = { ...target }
        Object.keys(source).forEach((key) => {
          result[key] =
            typeof source[key] === 'object' && !Array.isArray(source[key])
              ? deepMerge(target[key] || {}, source[key])
              : source[key]
        })
        return result
      }

      const updatedFeePaxType = deepMerge(currentFeePaxType, updates)

      update(feePaxTypeIndex, updatedFeePaxType)
    },
    [feePaxTypes, feePaxTypeIndex, update]
  )

  const onClick = useCallback(
    () => (isActive ? handleRemoveFeePaxType() : handleAddFeePaxType()),
    [handleAddFeePaxType, handleRemoveFeePaxType, isActive]
  )

  const calcPercentage = useCallback((value) => {
    const percent = ((unformatMoney(value) * 100) / (feePrice))
    const isInfinity = !Number.isFinite(percent) || percent === 'Infinity' // Division by zero is Infinity
    if (isInfinity) { return 0 }

    return percent.toFixed(3)
  }, [feePrice])

  const onGuidePriceChange = useCallback((value) => {
    const safeGuidePrice = unformatMoney(value)

    updateFeePaxTypeField({
      taxes: {
        guide: calcPercentage(value)
      },
      guidePrice: safeGuidePrice
    })
  }, [calcPercentage, updateFeePaxTypeField])

  const onPriceChange = useCallback((value) => {
    const safePrice = unformatMoney(value)

    updateFeePaxTypeField({
      feeModificator: calcPercentage(value),
      calculatedPrice: safePrice,
      price: safePrice
    })
  }, [calcPercentage, updateFeePaxTypeField])

  const onFeeModificator = useCallback((value) => {
    const safeFeeModificator = Number(value || 0)
    const safeGuidePrice = unformatMoney(guidePrice)

    const priceCalculated = (Math.round(Number(safeFeeModificator || 0) * feePrice) / 100) - safeGuidePrice

    updateFeePaxTypeField({
      feeModificator: safeFeeModificator,
      calculatedPrice: priceCalculated + guidePrice,
      price: priceCalculated
    })
  }, [feePrice, guidePrice, updateFeePaxTypeField])

  const onGuidePercentageChange = useCallback((value) => {
    const safeGuidePercentage = Number(value || 0)
    const guidePriceCalculated = Math.round(Number(safeGuidePercentage || 0) * feePrice) / 100

    updateFeePaxTypeField({
      taxes: {
        guide: safeGuidePercentage
      },
      guidePrice: guidePriceCalculated
    })
  }, [feePrice, updateFeePaxTypeField])

  const onHandleToggle = useCallback(() => {
    setHasGuideTax(hasGuide => {
      updateFeePaxTypeField({
        taxes: {
          guide: 0
        },
        guidePrice: 0
      })

      return !hasGuide
    })
  }, [updateFeePaxTypeField]
  )

  const indexNameLowerCase = useMemo(() => indexName?.toLowerCase(), [indexName])

  return (
    <>
      <Card
        css={[
          feePaxTypeCard,
          isActive && activeHeight,
          !isActive && disabledHeight,
          tooltip && toolTipHeight,
          isActive && tooltip && activeTooltipHeight,
          isPrivate && isActive && privateHeight
        ]}
      >
        <SwatchImage css={fontSize28} size={80} alternativeText={indexName} />
        <H3 css={marginTop10}>{indexName}</H3>
        {tooltip && <P>{tooltip}</P>}
        <div css={flex}>
          <ToggleInputGroup id={indexNameLowerCase} checked={isActive} onChange={identity} onClick={onClick} />
        </div>
        {isActive
          ? (
            <div css={guideAnimation} className={'open'}>
              {isPrivate && (
                <div css={flexColumnCentered}>
                  <P css={[marginTop15, marginBottom10]}>Capacidade por ingresso</P>
                  <HorizontalInputFormGroup css={[inputLabel, width80]} errorMessage={feePaxTypeErrors[feePaxTypeIndex]?.capacity?.message} label="">
                    <Controller
                      control={control}
                      name={`feePaxTypes.${feePaxTypeIndex}.capacity`}
                      render={({ field: { onBlur } }) => (
                        <InputWithSuffix
                          css={[inputStyle, width100]}
                          type="number"
                          min="0"
                          max="100"
                          value={capacity}
                          onBlur={onBlur}
                          onChange={(value) => updateFeePaxTypeField({ capacity: value })}
                        >
                          <span>Pessoas</span>
                        </InputWithSuffix>
                      )}
                    />
                  </HorizontalInputFormGroup>
                </div>
              )}
              <P css={agePermissionTitle}>Idade permitida</P>
              <div css={ageValuesContainer}>
                <InputFormGroup
                  horizontal
                  css={[inputLabel]}
                  errorMessage={feePaxTypeErrors[feePaxTypeIndex]?.minAge?.message}
                  label="">
                  <Input
                    css={modificator}
                    type="number"
                    min={0}
                    value={minAge}
                    onChangeWholeEvent={true}
                    {...register(`feePaxTypes.${feePaxTypeIndex}.minAge`)}
                    onChange={(event) => {
                      updateFeePaxTypeField({ minAge: event?.target?.value })
                    }}
                  />
                </InputFormGroup>
                <p css={horinzontalMargin10}>Até</p>
                <HorizontalInputFormGroup css={inputLabel} errorMessage={feePaxTypeErrors[feePaxTypeIndex]?.maxAge?.message} label="">
                  <Input
                    css={modificator}
                    type="number"
                    min={0}
                    onChangeWholeEvent={true}
                    {...register(`feePaxTypes.${feePaxTypeIndex}.maxAge`)}
                    onChange={(event) => {
                      updateFeePaxTypeField({ maxAge: event?.target?.value })
                    }}
                  />
                </HorizontalInputFormGroup>
              </div>
              <div css={flexColumnCentered}>
                <P css={[marginTop15, marginBottom10]}>Percentual da tarifa base</P>
                <HorizontalInputFormGroup
                  css={[inputLabel, width80]}
                  errorMessage={feePaxTypeErrors?.feeModificator?.message}
                  label=""
                >
                  <InputWithSuffix
                    css={[inputStyle, width100]}
                    type="number"
                    min="0"
                    value={feeModificator}
                    onChange={onFeeModificator}
                  >
                    <span>%</span>
                  </InputWithSuffix>
                </HorizontalInputFormGroup>
              </div>
              <div css={flexColumnCentered}>
                <P css={[marginTop15, marginBottom10]}>Valor final</P>
                <HorizontalInputFormGroup
                  css={[inputLabel, width80]}
                  label=""
                >
                  <InputMoneyMask
                    value={calculatedPrice}
                    onChange={onPriceChange}
                  />
                </HorizontalInputFormGroup>
              </div>

              <div css={guideContainer}>
                <ToggleInputGroup
                  id={`guide-${indexNameLowerCase}`}
                  checked={hasGuideTax}
                  onChange={identity}
                  onClick={onHandleToggle}
                >
                  <P css={[marginTop15]}>Percentual - Guia</P>
                </ToggleInputGroup>
              </div>
              <div css={guideAnimation} className={hasGuideTax && 'open'}>
                <HorizontalInputFormGroup css={[inputLabel, guidePercentual, width80]} label="" errorMessage={taxesErrors?.guide?.message}>
                  <Controller
                    control={control}
                    name={`feePaxTypes.${feePaxTypeIndex}.taxes.guide`}
                    render={({ field: { onBlur } }) => (
                      <InputWithSuffix
                        css={[inputStyle, width100]}
                        type="number"
                        min="0"
                        value={guide}
                        onChange={onGuidePercentageChange}
                        onBlur={onBlur}
                      >
                        <span>%</span>
                      </InputWithSuffix>
                    )}
                  />
                </HorizontalInputFormGroup>
                <P css={[marginTop15, marginBottom10, finalValueGuide]}>Valor final - Guia</P>
                <HorizontalInputFormGroup css={[guideValue, marginTop10, width80]} label="" errorMessage={guidePriceErrors?.message}>
                  <Controller
                    control={control}
                    name={`feePaxTypes.${feePaxTypeIndex}.guidePrice`}
                    render={({ field: { onBlur } }) => (
                      <InputMoneyMask
                        value={guidePrice}
                        onChange={onGuidePriceChange}
                        onBlur={onBlur}
                      />
                    )}
                  />
                </HorizontalInputFormGroup>
                <div css={[flexColumnCentered]} className={hasGuideTax && 'open'}>
                  <P css={[marginTop15, marginBottom10]}>Destino da taxa do guia</P>
                  <HorizontalInputFormGroup label="" errorMessage={feePaxTypeErrors[feePaxTypeIndex]?.bearer?.message} css={[inputLabel, width80]}>
                    <Select
                      placeholder='Selecione'
                      options={guideTaxesDestinationOptions}
                      value={bearer}
                      onChange={(value) => updateFeePaxTypeField({ taxes: { bearer: value } })}
                    />
                  </HorizontalInputFormGroup>
                </div>
              </div>
            </div>
          )
          : null
        }

      </Card>
    </>
  )
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useState, useMemo } from 'react'
import { useToast } from '@bonitour/components'
import { useHistory } from 'react-router-dom'

import { useQuery } from 'hooks/useQuery'
import { ReservationService } from 'services/Reservations/Service'
import { BookingForm } from 'containers/Booking/CreationForm/BookingForm'

import { useCompany } from 'contexts/Company'
import { useActivity } from 'contexts/Activity'
import { usePartnerList } from 'hooks/domains/usePartnerList'
import { useBookingTickets } from 'hooks/domains/useBookingTickets'
import { formHasTickets } from './Booking.utils'
import { identity } from '@bonitour/common-functions'
import { useUser } from 'contexts/User'
import { SafeJSONParse } from 'utils/object'

export const CreateLimberBooking = () => {
  const history = useHistory()
  const { add } = useToast()
  const {
    id: activityId,
    activity: { companyId: activityCompanyId, isPartner = false, type, description }
  } = useActivity()
  const [{ hour, date = '', email = '', pickupPlaceId = '' }] = useQuery()
  const { id: companyId } = useCompany()
  const { user } = useUser()

  const limberData = description ? SafeJSONParse(description).limber_data : {}

  const { ticketsList, isLoading } = useBookingTickets(activityCompanyId, activityId, date, hour, add, type, pickupPlaceId)
  const partnerList = usePartnerList(add)
  const [reservationBase] = useState(() => ({
    reservationType: {
      paymentState: 'responsible'
    },
    reservationHeader: {
      hour,
      date,
      partner: companyId,
      agent: user.id
    },
    initialEmail: email,
    pickupPlaceId
  }))

  const basePath = useMemo(() => {
    return `/limber-experience/${activityId}`
  }, [activityId])

  if (!(hour || date)) {
    history.push(`${basePath}/bookings`)
  }

  const returnToBooking = () => history.push(`${basePath}/bookings?date=${date}`)

  const [isSubmitting, setIsSubmitting] = useState(false)
  const createRedirect = (redirectTo = identity) => async data => {
    const isFormValid = formHasTickets(data)
    if (isFormValid) {
      try {
        setIsSubmitting(true)
        const { id } = await ReservationService.create(activityId, activityCompanyId, data, type)
        history.push(redirectTo(id))
      } catch (error) {
        setIsSubmitting(false)
        const { parsedErrors = [] } = error
        parsedErrors.length ? parsedErrors.forEach(errorMessage => add(errorMessage, 'error', 10000)) : add('Houve um erro no cadastro da sua reserva')
      }
    } else {
      add('Adicione ingressos na reserva')
    }
  }

  const onFinish = createRedirect((reservationId) => `/reservation/${reservationId}?selectedNav=financeiro`)
  const onContinue = createRedirect((reservationId) => `/reservation/${reservationId}?selectedNav=ingressos`)

  return (
    <BookingForm
      isPartner={isPartner}
      reservationBase={reservationBase}
      ticketsList={ticketsList}
      partnerList={partnerList}
      onFinish={onFinish}
      onContinue={onContinue}
      onBackClick={returnToBooking}
      limberData={limberData}
      serviceType={type}
      isSubmitting={isSubmitting}
      isLoadingTickets={isLoading}
    />
  )
}

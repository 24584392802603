export const stringifyObject = (obj, parentKey = '', result = {}) => {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const newKey = parentKey ? `${parentKey}.${key}` : key

      if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
        stringifyObject(obj[key], newKey, result)
      } else {
        result[newKey] = obj[key]
      }
    }
  }

  return result
}

export const pick = (obj, arr) =>
  Object.fromEntries(Object.entries(obj).filter(([k]) => arr.includes(k)))

export const getMinHash = (obj = {}) => {
  const objHash = btoa(JSON.stringify(obj))

  const hashLen = objHash.length

  const lastCharsOfHash = hashLen > 4 ? objHash.slice(hashLen - 4, hashLen) : objHash

  const firstCharsOfHash = hashLen > 4 ? objHash.slice(0, 4) : objHash

  return (lastCharsOfHash + firstCharsOfHash).replaceAll('=', '-')
}

export const validateObjHash = (obj = {}, minHash = '') => {
  const minHashOfObj = getMinHash(obj)

  return minHashOfObj === minHash
}

export const SafeJSONParse = (jsonString, safeValue = {}) => {
  try {
    return JSON.parse(jsonString)
  } catch (error) {
    console.error('Error parsing JSON', error)
    return safeValue
  }
}

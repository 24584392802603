/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'

import { OrderedServiceCombinedExperienceList } from 'domains/CombinedExperience/components/ServiceCombinedExperienceList'

const mockedServices = [
  {
    id: '909c6170-fd7e-4e23-b782-85e294269cbe',
    serviceId: '67ab5c63-8b19-4beb-b18d-f1197b67a7cc',
    serviceImage: 'https://orb.api.staging.binamik.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaEpJaWt4TW1Zd01UVTFZUzAxT0RNNUxUUTBaV010T0dObU55MW1OamcwWVdZeE5EWTFaVFFHT2daRlZBPT0iLCJleHAiOm51bGwsInB1ciI6ImJsb2JfaWQifX0=--d04b3a9a0dffeca6541f2f53c7754af748db9483/wallhaven-nmk6zm.jpg',
    serviceTitle: 'W H I T E S P A C E A C T I V I T Y',
    startTime: '12:00',
    endTime: '14:00',
    color: '#C48686',
    feeList: [
      {
        name: 'Adulto',
        quantity: 2
      },
      {
        name: 'Criança',
        quantity: 1
      }
    ]
  },
  {
    id: 'fd59b410-a909-48f5-9d88-475cc65f85c5',
    serviceId: '8da4ed01-d7b3-4426-b16a-8729dac39c8d',
    serviceImage: 'https://orb.api.staging.binamik.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaEpJaWxsWm1WbE5HSTBNaTFrWkdRekxUUTBOemN0WVRJMk9DMWxNR0ptTkRKaVpUUXdNVEVHT2daRlZBPT0iLCJleHAiOm51bGwsInB1ciI6ImJsb2JfaWQifX0=--a02576f30ef92fe762c66e2eb2dc06158f038d42/b0cf3e2f75149e06ae0a147e04acf5ef.jpg',
    serviceTitle: 'biigggeesstnameeewithoutspaceeeeeeeeeeeeeeeeeeeeee',
    startTime: '13:37',
    endTime: '17:00',
    color: '#B69B89',
    feeList: [
      {
        name: 'Não pagante',
        quantity: 5
      },
      {
        name: 'Idoso',
        quantity: 1
      }
    ]
  },
  {
    id: '4aa79825-d707-490a-a199-1a3c2a43ea84',
    serviceId: '3de410dc-15e7-4738-ac04-8e23ec5a131b',
    serviceImage: 'https://orb.api.staging.binamik.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaEpJaWsxWXpjMU1EZG1PQzFoTjJJNExUUTNNalF0T0dZM015MHlOekZpT0dZek16QXlPVGtHT2daRlZBPT0iLCJleHAiOm51bGwsInB1ciI6ImJsb2JfaWQifX0=--c9b78539d32cad5acbf89cd183be5dde3568dd7b/a2a728f456e386cf054629c722d310f8.jpg',
    serviceTitle: 'ACTIVITY WITH BIGGEST NAME FOR TEST',
    startTime: '11:25',
    endTime: '13:38',
    color: '#59C9A5',
    feeList: [
      {
        name: 'Adulto',
        quantity: 1
      }
    ]
  },
  {
    id: '134914b0-fb7d-49c7-95c2-02533e4800bf',
    serviceId: '44525864-bb0e-437f-8b78-ce24a5815cac',
    serviceImage: 'https://orb.api.staging.binamik.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaEpJaWszWW1KbFpXVTBOQzA1TjJFMkxUUTVORGN0T0RSaE1pMHdNbUZtWVRBNE9EaG1NamdHT2daRlZBPT0iLCJleHAiOm51bGwsInB1ciI6ImJsb2JfaWQifX0=--767204124f107249d3014dc01dc904c6a65b9da2/bondinho%20pao%20de%20a%C3%A7ucar.PNG',
    serviceTitle: 'Bondin',
    startTime: '12:30',
    endTime: '15:00',
    color: '#FFC164',
    feeList: [
      {
        name: 'Adulto',
        quantity: 1
      }
    ]
  },
  {
    id: 'ff0d85f0-49e4-4200-a4ee-3e0a47223ee2',
    serviceId: 'a80c9c45-0e5b-4f77-bd93-6d0ad4fd08b4',
    serviceImage: 'https://orb.api.staging.binamik.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaEpJaWxsTmpneU1HTXdaaTB6TmpJMExUUTRPR1l0WVRSa05DMWpNalZoWlRNME1qWXlOR1lHT2daRlZBPT0iLCJleHAiOm51bGwsInB1ciI6ImJsb2JfaWQifX0=--773ef5d38e12082f4c81141b329a8b4fa70bb0b8/qual-a-importancia-do-dia-da-arvore.jpg',
    serviceTitle: 'A arvore',
    startTime: '10:00',
    endTime: '11:00',
    color: '#7E7EFC',
    feeList: [
      {
        name: 'Adulto',
        quantity: 1
      }
    ]
  }
]

export const TestCombinedExperienceServiceListPaxTotalizer = () => {
  return <div>
    <OrderedServiceCombinedExperienceList
      experiences={mockedServices}
    />
  </div>
}

import { string } from 'yup'

const validateDuration = duration => Boolean(duration.match(/^\d\d:\d\d$/))

export const activityAboutSchema = (requireCategory = false) => ({
  duration: string()
    .required()
    .test('test-validateDuration', 'A duração deve ser válida', validateDuration),
  workDuration: string()
    .required()
    .test('test-validateDuration', 'A duração deve ser válida', validateDuration),
  category: requireCategory
    ? string().required('Tipo é obrigatório')
    : string().optional().nullable()
})

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { BlockedSummaryCell } from '../BodyCell/BlockedSummaryCell'
import { VacancyTableDetailedCell } from './VacancyTableDetailedCell'
import { VacancyTableSummaryCell } from './VacancyTableSummaryCell'
import { useMemo, memo } from 'react'
import { isTodayOrAfter } from 'utils/date'
import { identity } from '@bonitour/common-functions'

const VacancyTableCell = ({
  getFeesByDate = identity,
  dateColumns = [],
  date,
  vacancies = {},
  onExpand,
  isExpandable,
  isExpanded,
  rowHour,
  hasExpandedData,
  detailedReservations,
  reservations,
  VacancySummaryPopoverContent,
  ticketTariff,
  activitiesData = [],
  isCombinedExperience = false,
  pickupPlaceId
}) => {
  const { isAvailable = false } = vacancies
  const isAllowedBookingDate = useMemo(() => isTodayOrAfter(date)
    , [date])

  if (isCombinedExperience) {
    return (
      <VacancyTableSummaryCell
        key={`date-row-hour-${rowHour}-date-${date}-combined`}
        activitiesData={activitiesData}
        dateColumns={dateColumns}
        rowHour={rowHour}
        date={date}
        isGroupedCell={isCombinedExperience}
        getFeesByDate={getFeesByDate}
      />
    )
  }

  if (!isAvailable) {
    return <BlockedSummaryCell key={`date-row-hour-${rowHour}-date-${date}-not-available`} />
  }

  if (isExpanded && hasExpandedData) {
    return (
      <VacancyTableDetailedCell
        key={`date-row-hour-${rowHour}-date-${date}-detailed`}
        rowHour={rowHour}
        date={date}
        vacancies={vacancies}
        reservations={detailedReservations}
        isAllowedBookingDate={isAllowedBookingDate}
      />
    )
  }

  return (
    <VacancyTableSummaryCell
      key={`date-row-hour-${rowHour}-date-${date}-closed`}
      isExpandable={isExpandable}
      onExpand={onExpand}
      VacancySummaryPopoverContent={VacancySummaryPopoverContent}
      rowHour={rowHour}
      date={date}
      pickupPlaceId={pickupPlaceId}
      vacancies={vacancies}
      reservations={reservations}
      isAllowedBookingDate={isAllowedBookingDate}
      ticketTariff={ticketTariff}
    />
  )
}

export const MemoizedVacancyTableCell = memo(VacancyTableCell, (prevProps, nextProps) => JSON.stringify(prevProps) === JSON.stringify(nextProps))

export default VacancyTableCell

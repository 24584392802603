import { ActivityDashboard, ReservationMap, CreateBooking, LinkBooking, CreateLimberBooking } from 'app'
import { CreateOfflineExperienceBooking } from 'app/Booking/CreateOfflineExperienceBooking'
import { ActivityDailyMonitoring } from 'domains/Activity/ActivityDailyMonitoring'
import { BookingMapConfig } from 'domains/Booking/Map/Config/BookingMapConfig'
import { companyRoutes } from './companyRoutes'
import { reservationRoutes } from './testingRoutes'
import { CreateCombinedExperience } from 'domains/CombinedExperience/CreateCombinedExperience'
import { EditCombinedExperience } from 'domains/CombinedExperience/EditCombinedExperience'
import { ActionBarCombinedExperienceTest } from 'domains/CombinedExperience/ActionBarCombinedExperienceTest'
import { TestCombinedExperienceServiceListPaxTotalizer } from 'domains/CombinedExperience/TestCombinedExperienceServiceListPaxTotalizer'

const exclusiveActivityRoutes = [
  {
    path: '/transport/:activityId/dashboard',
    component: ActivityDashboard
  },
  {
    path: '/transport/:activityId/bookings/create',
    component: CreateBooking
  },
  {
    path: '/transport/:activityId/daily-monitoring',
    component: ActivityDailyMonitoring
  },
  {
    path: '/transport/:activityId/bookings/link/:reservationCode',
    component: LinkBooking
  },
  {
    path: '/transport/:activityId/bookings',
    component: ReservationMap
  },
  {
    path: '/transport/:activityId/bookings-day-config',
    component: BookingMapConfig
  },
  {
    path: '/activity/:activityId/dashboard',
    component: ActivityDashboard
  },
  {
    path: '/activity/:activityId/bookings/create',
    component: CreateBooking
  },
  {
    path: '/activity/:activityId/daily-monitoring',
    component: ActivityDailyMonitoring
  },
  {
    path: '/activity/:activityId/bookings/link/:reservationCode',
    component: LinkBooking
  },
  {
    path: '/activity/:activityId/bookings',
    component: ReservationMap
  },
  {
    path: '/activity/:activityId/bookings-day-config',
    component: BookingMapConfig
  },
  {
    path: '/offline-experience/:activityId/dashboard',
    component: ActivityDashboard
  },
  {
    path: '/offline-experience/:activityId/bookings/create',
    component: CreateOfflineExperienceBooking
  },
  {
    path: '/offline-experience/:activityId/bookings',
    component: ReservationMap
  },
  {
    path: '/offline-experience/:activityId/daily-monitoring',
    component: ActivityDailyMonitoring
  },
  {
    path: '/combined-experience/create',
    component: CreateCombinedExperience
  },
  {
    path: '/combined-experience/:activityId/dashboard',
    component: ActivityDashboard
  },
  {
    path: '/combined-experience/:combinedExperienceId/edit',
    component: EditCombinedExperience
  },
  {
    path: '/combined-experience/:activityId/bookings/create',
    component: CreateBooking
  },
  {
    path: '/combined-experience/:activityId/bookings/link/:reservationCode',
    component: LinkBooking
  },
  {
    path: '/combined-experience/:activityId/bookings',
    component: ReservationMap
  },
  {
    path: '/limber-experience/:activityId/dashboard',
    component: ActivityDashboard
  },
  {
    path: '/limber-experience/:activityId/bookings/create',
    component: CreateLimberBooking
  },
  {
    path: '/limber-experience/:activityId/bookings/link/:reservationCode',
    component: LinkBooking
  },
  {
    path: '/limber-experience/:activityId/bookings',
    component: ReservationMap
  },
  {
    path: '/limber-experience/:activityId/daily-monitoring',
    component: ActivityDailyMonitoring
  },
  { // TODO: Remove route and component
    path: '/test-component/action-bar-booking-map',
    component: ActionBarCombinedExperienceTest
  },
  {
    path: '/test-component/pax-totalizer-service-list',
    component: TestCombinedExperienceServiceListPaxTotalizer
  }
].concat(reservationRoutes)

export const activityRoutes = companyRoutes.concat(exclusiveActivityRoutes)

import { number, object, string } from 'yup'

export const feePaxTypeSchema = (activityMinAge = 0) => ({
  feeModificator: number()
    .typeError('Deve ser um número')
    .min(0, 'A porcentagem do valor base deve ser maior do que 0'),
  minAge: number()
    .typeError('Deve ser um número')
    .min(activityMinAge, `A idade mínima deve ser igual ou maior que ${activityMinAge} (definida no serviço)`),
  maxAge: number()
    .typeError('Deve ser um número')
    .min(activityMinAge || 1, 'A idade máxima fora dos limites'),
  capacity: number()
    .typeError('Deve ser um número')
    .min(1, 'A capacidade máxima deve ser maior que zero'),
  taxes: object({
    guide: number()
      .typeError('Informe a taxa do guia')
      .min(0, 'A porcentagem da taxa do guia deve estar entre 0 e 100')
      .test('maxGuidePercentage', 'A porcentagem do guia não pode ser maior que a do valor final', function (guidePercentage = 0) {
        const { value = {} } = this.from[1]
        const finalPercentage = value?.feeModificator

        return finalPercentage >= guidePercentage
      }),
    bearer: string().nullable()
  })
})

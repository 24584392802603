import { COMBINED_EXPERIENCE_TYPE } from 'constants/activityTypes'
import { string, object } from 'yup'
import { bookingTicketsSchema } from '../CreationForm/BookingForm.schema'

export const linkBookingSchema = (activityType) => {
  const isCombinedExperience = activityType === COMBINED_EXPERIENCE_TYPE

  return {
    reservationHeader: object({
      partner: string().required(),
      agent: string().optional()
    }).required(),
    tickets: bookingTicketsSchema(isCombinedExperience)
  }
}

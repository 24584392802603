/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { identity } from '@bonitour/common-functions'
import { Button, Card, Column, DatePicker, InputFormGroup, MultiSelectDropdown, Row, Select, useToast } from '@bonitour/components'
import { SafeDate } from '@binamik/js-providers'
import { useCallback, useMemo } from 'react'
import { useForm } from '@bonitour/app-functions'
import { date, ref, array, string, boolean } from 'yup'
import { width100 } from 'assets/styles/global'
import { buttonStyle, cardContrast } from './ReservationVisualizedTableFilters.style'
import { mainReservationsStatusOptions, StatusEnum } from 'constants/reservationsStatus'

const ReservationVisualizedTableFiltersSchema = {
  initialDate: date().max(ref('finalDate'), 'A data inicial deve ser menor ou igual a data final').typeError('Deve ser uma data'),
  finalDate: date().min(ref('initialDate'), 'A data final deve ser maior ou igual a data inicial').typeError('Deve ser uma data'),
  isAppViewed: boolean().nullable(),
  reservationStatuses: array(string().oneOf(Object.values(StatusEnum))).nullable()
}

/**
 * Renders a filter for the visualized reservations table.
 * @component
 * @param {Function} onFilterChange - A function to handle the filter change.
 * @param {Object} filters - The filters to be applied.
 * @returns {JSX.Element} - The rendered ReservationVisualizedTableFilters component.
**/
export const ReservationVisualizedTableFilters = ({ onFilterChange = identity, filters }) => {
  const {
    form: { initialDate, finalDate, isAppViewed, reservationStatuses },
    utils: { onInputChange, onInputBlur },
    onSubmit,
    errors
  } = useForm(filters, ReservationVisualizedTableFiltersSchema)

  const { add: addToast } = useToast()

  const onError = useCallback(() => {
    addToast('Houve um erro ao filtrar as reservas visualizadas')
  }, [addToast])

  const handleSubmit = onSubmit(() => onFilterChange({ initialDate, finalDate, isAppViewed, reservationStatuses }), onError)

  const isAppViewedOptions = useMemo(() => [
    {
      label: 'Todos',
      value: 'all'
    },
    {
      label: 'Sim',
      value: 'true'
    },
    {
      label: 'Não',
      value: 'false'
    }
  ], [])

  const reservationStatusOptions = useMemo(() => mainReservationsStatusOptions.filter(statusOption =>
    [StatusEnum.confirmed, StatusEnum.checked_in].includes(statusOption.value)
  ), [])

  const onChangeIsAppViwed = useCallback((value) => {
    if ((value) === 'all') {
      return onInputChange('isAppViewed')(null)
    }

    return onInputChange('isAppViewed')(value)
  }, [onInputChange])

  return (
    <Card css={cardContrast}>
      <Row>
        <Column phone={12} desktop={2}>
          <InputFormGroup label='Data inicial' errorMessage={errors.initialDate}>
            <DatePicker
              value={initialDate && new SafeDate(initialDate).jsDate}
              onChange={onInputChange('initialDate')}
              onBlur={onInputBlur('initialDate')}
              allowsEmpty
              placeholder='__/__/____'
              css={width100}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={2}>
          <InputFormGroup label='Data final' errorMessage={errors.finalDate}>
            <DatePicker
              value={finalDate && new SafeDate(finalDate).jsDate}
              onChange={onInputChange('finalDate')}
              onBlur={onInputBlur('finalDate')}
              allowsEmpty
              placeholder='__/__/____'
              css={width100}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={2}>
          <InputFormGroup label="Visualizou no app" errorMessage={errors.states}>
            <Select
              onChange={onChangeIsAppViwed}
              onBlur={onInputBlur('isAppViewed')}
              options={isAppViewedOptions}
              value={isAppViewed}
              placeholder={'Todos'}
              isDisableSelectAll
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={3}>
          <InputFormGroup label="Estado" errorMessage={errors.states}>
            <MultiSelectDropdown
              onChange={onInputChange('reservationStatuses')}
              onBlur={onInputBlur('reservationStatuses')}
              options={reservationStatusOptions}
              selected={reservationStatuses}
              placeholder={'Todos'}
              isDisableSelectAll
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={3}>
          <Button onClick={handleSubmit} css={buttonStyle}>Pesquisar</Button>
        </Column>
      </Row>
    </Card>
  )
}

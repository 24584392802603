export const parseDecimal = (value) => {
  let parsedValue = value

  if (isNaN(value)) {
    parsedValue = 0
  }

  return (Math.round(parsedValue * 100) / 100).toString()
}

export const sliceDecimal = (value, sliceIndex = 2) => {
  const parts = value.split('.')

  let formattedValue = parts[0]

  if (parts.length > 1) {
    formattedValue += '.' + parts[1].slice(0, sliceIndex)
  }

  return formattedValue
}

export const ACTIVITY_TYPE = 'Experiences::Activity'
export const TRANSPORT_TYPE = 'Experiences::Transport'
export const COMBINED_EXPERIENCE_TYPE = 'Experiences::Experience'
export const OFFLINE_EXPERIENCE_TYPE = 'Experiences::OfflineExperience'
export const LIMBER_EXPERIENCE_TYPE = 'Experiences::LimberExperience'

export const INTERNAL_EXPERIENCE_TYPES = [
  ACTIVITY_TYPE,
  TRANSPORT_TYPE,
  COMBINED_EXPERIENCE_TYPE
]

export const INTEGRATION_EXPERIENCE_TYPES = [
  LIMBER_EXPERIENCE_TYPE
]

export const EXTERNAL_EXPERIENCE_TYPES = [
  OFFLINE_EXPERIENCE_TYPE,
  ...INTEGRATION_EXPERIENCE_TYPES
]

export const ALL_ACTIVITY_TYPES = [
  ...INTERNAL_EXPERIENCE_TYPES,
  ...EXTERNAL_EXPERIENCE_TYPES
]

import { css } from '@emotion/core'
import { colors } from '@bonitour/components'

export const buttonStyle = css`
  margin-top: 1rem;
  width: 100%;
`

export const cardContrast = css`
  margin-bottom: 20px;
  padding: 15px;
  background-color: ${colors.gray13};
  border: none;
`

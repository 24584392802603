/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'

import { useCallback, useMemo } from 'react'

import { EditInfoIcon, GhostPrimaryButton, H4, LightWarningAlert, Row } from '@bonitour/components'
import { OrderedServiceCombinedExperienceList } from 'domains/CombinedExperience/components/ServiceCombinedExperienceList'

import { formatDate, identity } from '@bonitour/common-functions'

import { flexCentered, serviceList } from './ExperienceSlots.style'

export const ExperienceSlots = ({
  dates = [],
  experiences = {},
  onEditClick: emitEditClickEvent = identity
}) => {
  const parseExperienceList = useCallback((experienceList) => {
    if (!experienceList) return []

    const processedKeys = new Set()

    const parsed = experienceList
      .map((experience) => {
        const key = `${experience.serviceId}#${experience.time}`

        if (processedKeys.has(key)) return null

        processedKeys.add(key)

        const allFeesFromStartTime = experienceList
          .filter((activity) => activity.serviceId === experience.serviceId && activity.time === experience.time)

        const feeList = allFeesFromStartTime.map(({ fee, quantity }) => ({
          name: fee,
          quantity
        }))

        const {
          serviceId: id,
          image,
          name,
          time,
          endTime,
          color,
          pickupPlaceId,
          pickupPlaces
        } = experience
        const pickupPlaceName = pickupPlaces?.find(({ value }) => String(value) === pickupPlaceId)?.label

        return {
          id,
          serviceId: id,
          serviceImage: image,
          serviceTitle: name,
          startTime: time,
          endTime,
          color,
          feeList,
          pickupPlaceId,
          pickupPlaceName
        }
      })
      .filter(Boolean)

    return parsed
  }, [])

  const experiencesWithoutPriceData = useMemo(
    () => Object.values(experiences)
      .flat()
      .filter(({ priceData }) => !priceData)
      .map(({ name }) => name),
    [experiences]
  )

  return (
    <>
      {dates.map((date, index) => {
        const experienceList = parseExperienceList(experiences[date])

        if (!experienceList.length) return null

        return (
          <div key={`experiences-${date}#${index}`}>
            <Row css={flexCentered}>
              <H4>
              Vagas - {formatDate(date)}
              </H4>
              <GhostPrimaryButton onClick={emitEditClickEvent}>
                <EditInfoIcon/>
                Editar
              </GhostPrimaryButton>
            </Row>
            <OrderedServiceCombinedExperienceList
              experiences={experienceList}
            />
          </div>
        )
      })}
      {experiencesWithoutPriceData.length
        ? (
          <>
            <LightWarningAlert>
              <p>Não há tarifários disponíveis:</p>
              <ul css={serviceList}>
                {experiencesWithoutPriceData.map((name) => (
                  <li key={name}>{name}</li>
                ))}
              </ul>
            </LightWarningAlert>
          </>
        )
        : null
      }
    </>
  )
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'

import { memo, useMemo } from 'react'

import { flex, flexRow } from '@bonitour/components'

import { EXPERIENCE_VACANCY_TYPE_OPACITY } from 'constants/bookingMap'

import { hidden, positionRelative } from 'assets/styles/global'
import { ActivityRow, MinifiedCombinedExperienceCell, gap } from './combinedExperienceSummaryCellGroup.styles'
import { calculateTimeDuration, minutesToText, timeToMinutes } from 'utils/time'
import { identity } from '@bonitour/common-functions'
import { useBookingMapInfo } from 'domains/Booking/Map/contexts/useBookingMapInfo'
import { CombinedExperienceReservationCellModal } from 'domains/CombinedExperience/components/CombinedExperienceReservationCellModal'

const ExperienceSummaryCellGroup = ({
  getBlockedSummaryAttrs = identity,
  activityData = {},
  detailedActivityData = {},
  dateColumns = [],
  date,
  limitOfDay = 0,
  children
}) => {
  const {
    onSelectActivity = identity,
    toggleReservationMode = identity,
    getSelectedTotals = identity,
    getSelectedPaxData = identity,
    isEnabledReservationMode = false,
    slotsOccupiedOnDates = {},
    slotsOccupiedOnHourlies = {},
    selectedDate = null
  } = useBookingMapInfo()

  const {
    vacancies = {},
    reservations = {},
    activityId = '',
    color = '',
    hour = 'without-hour',
    duration = '',
    slots,
    index: activityIndex
  } = activityData || {}

  const {
    total: totalAvailable = 0
  } = vacancies || {}

  const { total: reservationsTotal } = reservations

  const fullInfoDetailedActivityData = useMemo(() => ({
    ...detailedActivityData,
    reservationsTotal: slots?.[date]?.reservationsTotals || 0,
    slotsAvailable: totalAvailable
  }), [detailedActivityData, slots, date, totalAvailable])

  const {
    selectedTotals,
    isFirstDateOfHour
  } = useMemo(() => ({
    selectedTotals: getSelectedTotals({
      activityId,
      hour,
      date
    }),
    isFirstDateOfHour: dateColumns[0] === date
  }), [activityId, date, dateColumns, getSelectedTotals, hour])

  const {
    startTime,
    endTime
  } = useMemo(() => calculateTimeDuration(hour, duration), [hour, duration])

  const [isBlockedSummary] = useMemo(() => getBlockedSummaryAttrs(activityData), [activityData, getBlockedSummaryAttrs])

  return (
    <ActivityRow>
      {isFirstDateOfHour
        ? (<>
          <MinifiedCombinedExperienceCell
            className='color-as-bg-color absolute-on-left text-bold'
            bgColor={color}
          >
            {minutesToText(timeToMinutes(duration))}
          </MinifiedCombinedExperienceCell>
        </>)
        : null}
      <div
        css={[isBlockedSummary && hidden]}>
        <CombinedExperienceReservationCellModal
          reservationsDetails={reservations?.details}
          experience={fullInfoDetailedActivityData}
          color={color}
          startTime={startTime}
          endTime={endTime}
          onStartReservation={() => toggleReservationMode(true)}
          isEnabledReservationMode={isEnabledReservationMode}
          limitOfDay={limitOfDay}
          onSelectActivity={onSelectActivity}
          activityIndex={activityIndex}
          date={date}
          getSelectedPaxData={getSelectedPaxData}
          slotsOccupiedOnDate={slotsOccupiedOnDates?.[date]?.[detailedActivityData?.id] || 0}
          slotsOccupiedOnHour={slotsOccupiedOnHourlies?.[date]?.[detailedActivityData?.id]?.[hour] || 0}
          selectedDate={selectedDate}
          customCss={[flex, flexRow, positionRelative, gap]}
        >
          <>
            <MinifiedCombinedExperienceCell
              bgColor={color}
              className={'draw-text-shadow-light can-margin'}
              css={[isBlockedSummary && hidden]}
              bgOpacity={EXPERIENCE_VACANCY_TYPE_OPACITY.totalAvailable}
            >
              <span className='left'>{totalAvailable}</span>
            </MinifiedCombinedExperienceCell>
            {selectedTotals
              ? (
                <MinifiedCombinedExperienceCell
                  bgColor={color}
                  className='color-as-bg-color border-as-bg-color absolute-on-middle'
                  css={[isBlockedSummary && hidden]}
                >
                  <div>{selectedTotals}</div>
                </MinifiedCombinedExperienceCell>
              )
              : null}
            <MinifiedCombinedExperienceCell
              bgColor={color}
              className={'draw-text-shadow hoverable can-margin clickable'}
              css={[isBlockedSummary && hidden]}
              bgOpacity={EXPERIENCE_VACANCY_TYPE_OPACITY.reservationsTotal}
            >
              <span className='right'>{reservationsTotal}</span>
            </MinifiedCombinedExperienceCell>
          </>
        </CombinedExperienceReservationCellModal>
      </div>
      {isBlockedSummary ? children : ''}
    </ActivityRow>
  )
}

export const CombinedExperienceSummaryCellGroup = memo(ExperienceSummaryCellGroup)

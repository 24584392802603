/* eslint-disable react/jsx-closing-tag-location */
/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core'
import { identity, formatMoney, head, unformatMoney } from '@bonitour/common-functions'
import { TableContainer, TableHeader, TableHeaderCell, Checkbox, TableHeaderRow, TableBodyRow, TableBodyCell, TableBody, SortRightIcon, ArrowRightIcon, flexHorizontallyCentered, Label } from '@bonitour/components'
import { popoverPositionStyle, canceledTicketRow, lineThrough, detail, paymentTicketsBalanceLabel, collapsableIcon, collapsableIconOpen, paymentIconRow, withoutTicketsLabel } from './TicketList.style'
import { TicketStatus } from 'containers/Reservation/TicketStatus'
import { TicketDetail } from 'containers/Reservation/TicketDetail/TicketDetail'
import { useCallback, useState } from 'react'

const TicketTransferedDetail = ({ transferredTickets = [] }) => {
  return (
    <>
      <div css={detail}>
        <tr>
          <td colSpan='100%' css={paymentTicketsBalanceLabel}>Pagamentos realizados com saldo</td>
        </tr>
        {transferredTickets.map(({ id, company, activity, value, status, code, passagerName, payerName, type }) => (
          <tr key={id}>
            <TableBodyCell css={flexHorizontallyCentered}>
              <ArrowRightIcon css={paymentIconRow} />
              <span>{code}</span>
            </TableBodyCell>
            <TableBodyCell>{type}</TableBodyCell>
            <TableBodyCell>{passagerName || payerName}</TableBodyCell>
            <TableBodyCell>{company}</TableBodyCell>
            <TableBodyCell>{activity}</TableBodyCell>
            <TableBodyCell>{formatMoney(value)}</TableBodyCell>
            <TableBodyCell>{status && TicketStatus(status)}</TableBodyCell>
          </tr>
        ))}
      </div>
    </>
  )
}

const TicketListHeader = ({
  isSelectable = true,
  areTicketsSelected = false,
  onSelectAll = identity,
  hasStatus = false
}) => {
  return (
    <TableHeader>
      <TableHeaderRow>
        {isSelectable &&
          <TableHeaderCell>
            <Checkbox checked={areTicketsSelected} onChange={identity} onClick={onSelectAll} />
          </TableHeaderCell>}
        <TableHeaderCell>Código</TableHeaderCell>
        <TableHeaderCell>Tipo</TableHeaderCell>
        <TableHeaderCell>Viajante</TableHeaderCell>
        <TableHeaderCell>Empresa</TableHeaderCell>
        <TableHeaderCell>Experiência</TableHeaderCell>
        <TableHeaderCell>Valor</TableHeaderCell>
        {hasStatus && <TableHeaderCell>Status</TableHeaderCell>}
      </TableHeaderRow>
    </TableHeader>
  )
}

const TicketListRow = ({
  ticketsIds = [],
  onTicketChange = identity,
  ticket,
  isSelectable = true,
  hasStatus = false,
  isRefund = false,
  isProvider = false,
  isPaymentLink = false,
  isPaymentShow = false,
  isEditing = false
}) => {
  const { id, company, activity, value, status, code, passagerName, payerName, type, ticketPaymentBalance, transferredTickets } = ticket || {}
  const isSelected = ticketsIds.map(ticket => ticket.id).indexOf(id) !== -1
  const isCanceledStatus = status === 'canceled'
  const historic = head(ticketPaymentBalance)?.historic
  const ticketPrice = head(ticketPaymentBalance)?.ticketPrice
  const alreadyPaidTicketValue = head(ticketPaymentBalance)?.alreadyPaidTicketValue
  const remainingTicketValue = head(ticketPaymentBalance)?.remainingTicketValue
  const refundableTicketValue = head(ticketPaymentBalance)?.refundableTicketValue
  const hasAlreadyPaidTicketValue = parseFloat(alreadyPaidTicketValue) > 0
  const hasTransferredTickets = transferredTickets?.length > 0
  const isTicketTransferedDetail = isCanceledStatus && hasTransferredTickets
  const [canceledTicketDetail, setCanceledTicketDetail] = useState(false)

  const onCheckboxClick = () => {
    if (isSelected) {
      onTicketChange(ticketsIds.filter(ticket => ticket.id !== id))
    } else {
      const remaining = typeof remainingTicketValue === 'number' ? remainingTicketValue : unformatMoney(remainingTicketValue)
      const refundable = typeof refundableTicketValue === 'number' ? refundableTicketValue : unformatMoney(refundableTicketValue)
      const amount = isRefund ? refundable : remaining
      onTicketChange(ticketsIds.concat({ ...ticket, amount }))
    }
  }

  const onClickCanceledTicket = () => isCanceledStatus && setCanceledTicketDetail(!canceledTicketDetail)

  return (
    <>
      <TableBodyRow css={isTicketTransferedDetail && canceledTicketRow} onClick={onClickCanceledTicket}>
        {isSelectable &&
          <TableBodyCell>
            <Checkbox
              checked={isSelected}
              onChange={identity}
              onClick={onCheckboxClick}
            />
          </TableBodyCell>}
        <TableBodyCell>
          {isTicketTransferedDetail && <SortRightIcon css={[collapsableIcon, canceledTicketDetail && collapsableIconOpen]} />}
          <span css={isTicketTransferedDetail && lineThrough}>{code}</span>
        </TableBodyCell>
        <TableBodyCell css={isTicketTransferedDetail && lineThrough}>{type}</TableBodyCell>
        <TableBodyCell css={isTicketTransferedDetail && lineThrough}>{passagerName || payerName}</TableBodyCell>
        <TableBodyCell css={isTicketTransferedDetail && lineThrough}>{company}</TableBodyCell>
        <TableBodyCell css={isTicketTransferedDetail && lineThrough}>{activity}</TableBodyCell>
        <TableBodyCell css={isTicketTransferedDetail && lineThrough}>
          {isEditing || isPaymentShow
            ? formatMoney(value)
            : hasAlreadyPaidTicketValue
              ? (
                <TicketDetail
                  value={value}
                  ticketPrice={ticketPrice}
                  alreadyPaidTicketValue={alreadyPaidTicketValue}
                  remainingTicketValue={remainingTicketValue}
                  historic={historic}
                  isRefund={isRefund}
                  isPaymentShow={isPaymentShow}
                  isEditing={isEditing}
                  popoverPositionStyle={popoverPositionStyle}
                />)
              : formatMoney(isProvider || isPaymentLink || isRefund ? value : hasAlreadyPaidTicketValue ? ticketPrice : remainingTicketValue)
          }
        </TableBodyCell>
        {hasStatus && <TableBodyCell>{TicketStatus(status)}</TableBodyCell>}
      </TableBodyRow>
      {isTicketTransferedDetail && canceledTicketDetail && <TicketTransferedDetail transferredTickets={transferredTickets} />}
    </>
  )
}

export const TicketList = ({
  isSelectable = true,
  isRefund = false,
  loading = false,
  isPayment = false,
  isProvider = false,
  isPaymentLink = false,
  isPaymentShow = false,
  isEditing = false,
  tickets = [],
  ticketsIds = [],
  onTicketIdsChange = identity
}) => {
  const areAllTicketsSelected = tickets.length && ticketsIds.length === tickets.length

  const hasStatus = Boolean(tickets.length) && Boolean(tickets[0].status)

  const onSelectAll = useCallback(() => {
    if (areAllTicketsSelected) {
      onTicketIdsChange([])
    } else {
      onTicketIdsChange(tickets.map(ticket => ({
        ...ticket,
        amount: isRefund
          ? head(ticket.ticketPaymentBalance)?.refundableTicketValue
          : head(ticket.ticketPaymentBalance)?.remainingTicketValue
      })))
    }
  }, [areAllTicketsSelected, onTicketIdsChange, tickets, isRefund])

  if (!tickets.length) {
    return (
      <Label css={withoutTicketsLabel}>
      Nenhum ingresso encontrado
      </Label>
    )
  }

  return (
    <TableContainer>
      <TicketListHeader
        isSelectable={isSelectable}
        areTicketsSelected={areAllTicketsSelected}
        onSelectAll={onSelectAll}
        hasStatus={hasStatus}
        isRefund={isRefund}
      />
      <TableBody loading={loading}>
        {tickets.map(ticket => (
          <TicketListRow
            isSelectable={isSelectable}
            key={ticket.id}
            ticket={ticket}
            ticketsIds={ticketsIds}
            onTicketChange={onTicketIdsChange}
            hasStatus={hasStatus}
            isRefund={isRefund}
            isPayment={isPayment}
            isProvider={isProvider}
            isPaymentLink={isPaymentLink}
            isPaymentShow={isPaymentShow}
            isEditing={isEditing}
          />))}
      </TableBody>
    </TableContainer>
  )
}

import { css } from '@emotion/core'
import { colors } from '@bonitour/components'
import { colorCycle } from 'constants/combinedExperience'

const getColorByIndex = (index) => colorCycle[index % colorCycle.length]

export const customTooltip = css`
  margin-bottom: -0.25rem;
  margin-right: 0.15rem;
  text-transform: uppercase;
  font-size: 0.5rem;
  min-height: unset;
  min-width: unset;
  padding: 0.375rem;
  font-weight: 600;
`

export const optionalIndication = (index = 0) => css`
  z-index: 1;
  width: 1rem;
  height: 1rem;
  font-size: 0.875rem;
  position: absolute;
  right: -0.25rem;
  top: -0.25rem;
  padding: 0.05rem;
  background-color: ${colors.gray14}99;
  backdrop-filter: blur(10px);
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  color: ${colors.gray2};
  cursor: default;
  transition: transform 300ms;
  font-weight: 300;
  -webkit-text-stroke: 0.375px ${colors.gray9};
  &:hover {
    transform: scale(1.1);
  }
  &.required {
    color: ${colors.gray1};
  }
  .tooltip-on-hover {
    background-color: ${getColorByIndex(index)};
  }
  [class*='TooltipArrow']::before {
    border-color: transparent ${getColorByIndex(index)} transparent transparent;
    transform: translateY(2px);
  }
`

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useEffect, useMemo, useState } from 'react'
import { ActivityAboutForm } from 'containers/ActivityAboutForm/ActivityAboutForm'
import { useToast } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'
import { ActivityService } from 'services/Activity/Service'
import { ActivityAdditionInformationService } from 'services/Activity/ActivityAdditionInformation/Service'

export const ActivityAbout = ({
  activityId = '',
  activityAbout = {},
  categories = {},
  onActivityUpdated = identity,
  onBackStep = identity,
  onNextStep = identity,
  isImporting = false,
  addToast: onAddToast,
  disabled = false
}) => {
  const [infrastructureList, setInfrastructureList] = useState([])
  const { add } = useToast()
  const addToast = useMemo(() => onAddToast || add, [add, onAddToast])

  const onValidationError = () => {
    addToast('Preencha corretamente o formulário')
  }

  const onSuccess = async data => {
    try {
      if (!isImporting) {
        await ActivityService.updateAbout(data, activityId)
      }
      onActivityUpdated(data)
      onNextStep()
    } catch (error) {
      addToast('Ocorreu um erro ao adicionar as informações')
    }
  }

  useEffect(() => {
    ActivityAdditionInformationService.list({ type: 'activity' }).then(({ infrastructureList }) => infrastructureList && setInfrastructureList(infrastructureList))
  }, [])

  return (
    <ActivityAboutForm
      categories={categories}
      activityAbout={activityAbout}
      infrastructureList={infrastructureList}
      onBackClick={onBackStep}
      onSuccess={onSuccess}
      onValidationError={onValidationError}
      isImporting={isImporting}
      disabled={disabled}
    />
  )
}

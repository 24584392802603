/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import { identity } from '@bonitour/common-functions'
import { Card, CheckboxInputGroup, H3, Label, WarningIcon } from '@bonitour/components'
import { serviceListStyle, checkboxStyle, combinedExperiencePartnerWarning } from './CombinedExperienceForm.styles'
import { CombinedExperienceFormServiceCard, AddServiceButton } from './CombinedExperienceFormServiceCard'
import { useCompany } from 'contexts/Company'
import { useFeatureFlag } from 'contexts/Feature'

const EMPTY_SERVICE_CARD = 'empty-service-card'

export const CombinedExperienceFormServiceList = ({
  onExperienceEdit = identity,
  onExperienceRemove = identity,
  onAddService = identity,
  getServiceData = identity,
  toggleSameDayServices = identity,
  servicesAdded = [],
  isCheckedSameDayServices = true,
  isLoadingServiceOptions = true,
  isEmptyServicesAdded = false,
  isEmptyExperiencesOptions = false,
  isLoading = false
}) => {
  const { company: currentCompany } = useCompany()
  const [
    _multiCompanyEnabled,
    { multiCompanyWarning, sameCompanyWarning }
  ] = useFeatureFlag('orb-multi-company-combined-exp')

  const hasPartnerServices = servicesAdded.some(({ companyId }) => companyId !== currentCompany.id)

  return <>
    <H3>Serviços inclusos</H3>
    <div css={serviceListStyle} className={isEmptyServicesAdded && 'empty-list'}>
      {isEmptyServicesAdded && (
        <Label>Nenhum serviço foi incluso</Label>
      )}
      {servicesAdded.map(({ id, currentError, isOptional, discountPercentage }) => {
        const serviceData = {
          id,
          isOptional,
          discountPercentage,
          ...getServiceData(id)
        }

        return (
          <CombinedExperienceFormServiceCard
            key={id || EMPTY_SERVICE_CARD}
            formValues={serviceData}
            onExperienceSubmit={(data) => onExperienceEdit({ ...data, lastId: id })}
            onExperienceRemove={onExperienceRemove}
            isLoadingServiceOptions={isLoadingServiceOptions}
            isEmptyCard={!id}
            isLoading={isLoading}
            error={currentError}
            isSkeleton={isLoadingServiceOptions}
          />
        )
      })}

      {!isEmptyServicesAdded && !isLoadingServiceOptions && (
        <AddServiceButton
          onAddService={onAddService}
          isEnabledOnBottom={true}
          isEmptyExperiencesOptions={isEmptyExperiencesOptions}
          isLoading={isLoading}
          isLoadingServiceOptions={isLoadingServiceOptions}
        />
      )}
    </div>
    {isEmptyServicesAdded && (
      <AddServiceButton
        onAddService={onAddService}
        isRow={true}
        isEmptyExperiencesOptions={isEmptyExperiencesOptions}
        isLoading={isLoading}
        isLoadingServiceOptions={isLoadingServiceOptions}
      />
    )}
    {hasPartnerServices && multiCompanyWarning && (
      <Card customCss={[combinedExperiencePartnerWarning]}>
        <WarningIcon />
        <div>
          <p>
            <b>Aviso</b>: por conter serviços de parceiros, esta experiência combinada não poderá ser vendida por empresas parceiras.
          </p>
          <p>Somente a sua empresa ({currentCompany.name}) poderá vendê-la.</p>
        </div>
      </Card>
    )}
    {sameCompanyWarning && (
      <Card customCss={[combinedExperiencePartnerWarning]}>
        <WarningIcon />
        <div>
          <p>
            <b>Aviso</b>: no momento, experiências combinadas não podem ser vendidas por empresas parceiras.
          </p>
          <p>Somente a sua empresa ({currentCompany.name}) poderá vendê-la.</p>
          <p><em>Regra sujeita a mudanças futuras.</em></p>
        </div>
      </Card>
    )}
    {!isEmptyServicesAdded && (
      <CheckboxInputGroup
        disabled
        // TODO disabled={isLoading || isLoadingServiceOptions}
        checked={isCheckedSameDayServices}
        onChange={toggleSameDayServices}
        css={checkboxStyle}
      >
        Os serviços devem ocorrer no mesmo dia
      </CheckboxInputGroup>
    )}
  </>
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useForm } from '@bonitour/app-functions'
import { identity } from '@bonitour/common-functions'
import { Card, Button, H4, useToast, GhostPrimaryButton } from '@bonitour/components'
import { marginTop, marginBottom, marginRight, positionRelative } from 'assets/styles/global'
import { FormHeader } from 'components/FormHeader'

import { useEffect, useMemo } from 'react'
import { linkBookingSchema } from './LinkingForm.schema'
import { PaxSection } from '../Form/PaxSection/PaxSection'
import { ReservationHeader } from '../Form/ReservationHeader/ReservationHeader'
import { formHasTickets } from 'app/Booking/Booking.utils'
import { LimberSection } from '../Form/LimberSection/LimberSection'
import { useQuery } from 'hooks/useQuery'
import { ExperienceSlots } from '../components'
import { useActivity } from 'contexts/Activity'

const marginTop20 = marginTop(20)
const marginBottom50 = marginBottom(50)
const marginRight20 = marginRight(20)

export const BookingLinkingForm = ({
  reservationCode = '',
  isPartner = false,
  reservationBase = {},
  ticketsList = [],
  partnerList = [],
  onBackClick: emitBackClickEvent = identity,
  onFinish: emitFinishEvent = identity,
  onContinue: emitContinueEvent = identity,
  isSubmitting = false,
  isLoadingTickets = false,
  isCombinedExperience = false,
  limberData = {},
  experienceData = {}
}) => {
  const { add: addToast } = useToast()
  const onValidationError = () => addToast('Preencha corretamente o formulário')

  const {
    activity: {
      type
    },
    isLoadingActivityFetch
  } = useActivity()

  const {
    experiences,
    dates
  } = experienceData

  const formSchema = useMemo(() => linkBookingSchema(type), [type])

  const {
    form,
    errors,
    onSubmit,
    utils: { onInputBlur, onInputChange }
  } = useForm(reservationBase, formSchema)

  useEffect(() => {
    onInputChange('tickets')(ticketsList.map((ticket) => {
      const quantity = form.tickets?.find(
        (formTicket) => formTicket.fee_type_id === ticket.fee_type_id
      )?.quantity || 0
      return {
        ...ticket,
        pickupPlaceId,
        quantity
      }
    }))
    // eslint-disable-next-line
  }, [ticketsList])

  const [{ pickupPlaceId }, { changeQueryParam }] = useQuery()

  useEffect(() => {
    if (
      (form.pickupPlaceId === null || form.pickupPlaceId === undefined) &&
      (pickupPlaceId !== null && pickupPlaceId !== undefined)
    ) {
      onInputChange('pickupPlaceId')(pickupPlaceId)
    }
  }, [form.pickupPlaceId, pickupPlaceId, onInputChange])

  useEffect(() => {
    if (form.pickupPlaceId !== null && form.pickupPlaceId !== undefined) {
      changeQueryParam('pickupPlaceId', form.pickupPlaceId)
    }
  }, [form.pickupPlaceId, changeQueryParam])

  useEffect(() => {
    if (isCombinedExperience && experiences) {
      onInputChange('tickets')(experiences)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [experiences, isCombinedExperience])

  const hasExperiences = useMemo(() => {
    if (Array.isArray(experiences)) {
      return experiences.length > 0
    } else {
      return Object.keys(experiences || {}).length > 0
    }
  }, [experiences])

  const isFormValid = useMemo(
    () => {
      return isCombinedExperience
        ? hasExperiences
        : (!isLoadingActivityFetch && form?.tickets && formHasTickets(form) && !isLoadingTickets)
    },
    [form, isCombinedExperience, isLoadingActivityFetch, isLoadingTickets, hasExperiences]
  )

  const onFinishClick = onSubmit(emitFinishEvent, onValidationError)
  const onContinueClick = onSubmit(emitContinueEvent, onValidationError)

  return (
    <>
      <FormHeader title={`Vincular na reserva ${reservationCode}`} ghostClick={emitBackClickEvent} />
      <Card>
        <ReservationHeader
          isPartner={isPartner}
          reservationHeader={form.reservationHeader}
          reservationHeaderErrors={errors.reservationHeader}
          tickets={(isCombinedExperience || isLoadingActivityFetch) ? [] : form.tickets}
          onChange={onInputChange}
          onBlur={onInputBlur}
          partnerList={partnerList}
          css={marginBottom50}
          isCombinedExperience={isCombinedExperience}
          combinedExperienceData={experienceData}
        />

        {isCombinedExperience
          ? <ExperienceSlots experiences={experiences} dates={dates} onEditClick={emitBackClickEvent}/>
          : (
            <>
              <H4>Vagas</H4>
              <div css={positionRelative}>
                <PaxSection
                  title='Tipificação base'
                  isExpanded
                  tickets={isLoadingActivityFetch ? [] : form.tickets}
                  ticketsErrors={errors.tickets}
                  onChange={onInputChange}
                  onBlur={onInputBlur}
                  isLoadingTickets={isLoadingTickets}
                />
                <LimberSection
                  tickets={form.tickets}
                  pickupPlaceId={form.pickupPlaceId}
                  css={marginBottom50}
                  limberData={limberData}
                  onChange={onInputChange('pickupPlaceId')}
                  onBlur={onInputBlur}
                />
              </div>
            </>
          )}

        <div css={marginTop20}>
          <GhostPrimaryButton css={marginRight20} onClick={onContinueClick} disabled={!isFormValid || isSubmitting}>
            Reservar e Continuar
          </GhostPrimaryButton>
          <Button onClick={onFinishClick} disabled={!isFormValid || isSubmitting}>Finalizar Reserva</Button>
        </div>
      </Card>
    </>
  )
}

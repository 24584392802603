import { colors } from '@bonitour/components'
import { css } from '@emotion/core'

export const selectorContainer = css`
  width: 150px;
`
export const popoverStyle = css`
  margin: 0 20px;
  padding-left: 20px;
  
  text-align: left;

  div {
    top: 25px;
  }
`
export const SellectorTitle = css`
  margin-top: 25px;
  font-size: 18px;
  font-weight: bold;
`

export const dialogContainer = css`
  max-width: 1240px;
  padding: 1.5rem 1.5rem;
  min-height: min(90vh, 25rem);
`

export const selectorStyle = css`
  margin: 0;
  max-height: calc(100vh - 180px);
  overflow-y: auto;
`

export const companyFilterWrapper = css`
  display: flex;
  flex-direction: row;
  button {
    text-transform: uppercase;
    font-size: 0.75rem;
    padding: 0 0.375rem 0.125rem;
    margin: 0.125rem 0 -0.25rem;
    background: none;
    color: ${colors.gray3};
    font-weight: 600;
    border-radius: 0;
    border-bottom: 2px solid ${colors.gray7};

    &.active {
      color: ${colors.primary};
      border-bottom-color: ${colors.primary};
      transition: border-bottom-color 200ms, color 200ms;
    }
  }
`

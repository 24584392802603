import { PassengerParser } from 'core/parsers/passenger'
import { orbCore } from 'services/Orb/Service'
import {
  reservationCreationModel,
  reservationSearchParamsModel,
  ReservationModel,
  reservationConfirmEmailModel,
  reservationInfoModel,
  ekoLocatorModel,
  reservationsVisualizedModel
} from './Model'
import { reservationListParser, ReservationParser } from './Parser'

const { reservationDomain, subordinateDomain, reservationTicketsDomain, reservationTotalizersDomain, allyReservationsDomain } = orbCore

export const ReservationService = {
  list (searchParams, page, perPage) {
    return reservationDomain.get(reservationSearchParamsModel(searchParams), page, perPage)
      .then(reservationListParser)
  },

  addTickets (activityId, companyId, reservationId, payload, type) {
    return reservationDomain.addTickets(reservationId, ReservationModel.onlyTickets(payload, companyId, activityId, type))
  },

  getById (reservationId) {
    return reservationDomain.getById(reservationId)
      .then(ReservationParser.instance)
  },

  getReservationTickets (reservationId) {
    return reservationTicketsDomain.getReservationTickets(reservationId).then(ReservationParser.reservationTickets)
  },

  getByReservationCode (reservationCode) {
    return reservationDomain.getByReservationCode(reservationCode)
      .then(ReservationParser.instance)
  },

  getSubordinates ({ partnerCompanyId }) {
    return subordinateDomain.getSubordinate({ partnerCompanyId })
  },

  cancelTicket (reservationId, ticketId) {
    return reservationDomain.cancelTicket(reservationId, ticketId)
  },

  create (activityId, companyId, payload, type) {
    const reservationPayload = reservationCreationModel(payload, companyId, activityId, type)
    return reservationDomain.create(reservationPayload)
  },

  changeInfo (reservationId, info) {
    return reservationDomain.changeInfo(reservationId, reservationInfoModel(info))
  },

  changeStatus (reservationId, status) {
    return reservationDomain.changeStatus(reservationId, status)
  },

  sendReservationConfirmEmail (emailData) {
    return reservationDomain.sendReservationConfirmEmail(reservationConfirmEmailModel(emailData))
  },

  getPassengers (reservationId) {
    return reservationDomain.getPassengers(reservationId).then(PassengerParser.list)
  },

  changePayer (reservationId, payerId) {
    return reservationDomain.changePayer(reservationId, payerId)
  },

  updateEkoLocator (reservationId, locator) {
    return reservationDomain.updateEkoLocator(reservationId, ekoLocatorModel(locator))
  },

  addAffiliate (reservationId, affiliateCode) {
    return reservationDomain.addAffiliate(reservationId, affiliateCode)
  },

  addFiscalDuty (reservationId, fiscalDuty) {
    return reservationDomain.addFiscalDuty(reservationId, fiscalDuty)
  },
  getVisualizedReservations (queryParams) {
    return allyReservationsDomain.listVisualizedReservations(reservationsVisualizedModel(queryParams))
      .then(ReservationParser.visualizedReservationsReport)
  }
}

export const ReservationTotalizersService = {
  getFinancialTransactionsTotalizers (reservationId) {
    return reservationTotalizersDomain.getFinancialTransactionsTotalizers(reservationId).then(ReservationParser.financialTransactionsTotalizers)
  }
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useCallback, useState } from 'react'
import { Button, Dialog } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'
import { ExperienceListSelector } from 'containers/Activity/ListSelector/ListSelector'
import { dialogContainer, companyFilterWrapper } from './ExperienceSelectorModal.style'

export const ExperienceSelectorModal = ({
  children,
  onExperienceSelected = identity,
  onOptionSelect = identity,
  customCss = [],
  subdomain = null,
  disabledTypes = [],
  fromCurrentCompany = undefined,
  displayCurrentCompanyFilter = false,
  isDisabledOnClick = false,
  ...other
}) => {
  const [isSelectorVisible, setVisibility] = useState(false)
  const onSelectorOpen = useCallback(() => setVisibility(true), [])
  const onSelectorClose = useCallback(() => setVisibility(false), [])

  const onOptionClick = useCallback((id, type) => {
    onSelectorClose()
    onExperienceSelected(id, type)
    onOptionSelect()
  }, [onExperienceSelected, onOptionSelect, onSelectorClose])

  const [currentCompanyFilter, setCurrentCompanyFilter] = useState(fromCurrentCompany || false)
  const onCompanyFilterClick = useCallback((value) => () => setCurrentCompanyFilter(value), [])

  return (
    <>
      <div css={[...customCss]} {...other}>
        <div onClick={isDisabledOnClick ? identity : onSelectorOpen}>{children}</div>
      </div>
      <Dialog customContainercss={[dialogContainer]} title='Selecione uma experiência' isVisible={isSelectorVisible} onClose={onSelectorClose}>
        {displayCurrentCompanyFilter && (
          <div css={companyFilterWrapper}>
            <Button onClick={onCompanyFilterClick(true)} className={currentCompanyFilter ? 'active' : ''}>
              Minhas experiências
            </Button>
            <Button onClick={onCompanyFilterClick(false)} className={!currentCompanyFilter ? 'active' : ''}>
              Experiências de parceiros
            </Button>
          </div>
        )}
        <ExperienceListSelector
          onSelectedExperience={onOptionClick}
          subdomain={subdomain}
          disabledTypes={disabledTypes}
          fromCurrentCompany={displayCurrentCompanyFilter ? currentCompanyFilter : fromCurrentCompany}
        />
      </Dialog>
    </>
  )
}

import { useEffect, useCallback, useState, useMemo } from 'react'
import { FeeService } from 'services/Fee/Service'
import { COMBINED_EXPERIENCE_TYPE, OFFLINE_EXPERIENCE_TYPE } from 'constants/activityTypes'

export const useBookingTickets = (
  activityCompanyId,
  activityId,
  date,
  hour,
  addToast,
  type = '',
  pickupPlaceId
) => {
  const isOfflineOrCombiendExperience = useMemo(() => [OFFLINE_EXPERIENCE_TYPE, COMBINED_EXPERIENCE_TYPE].includes(type), [type])
  const [isLoading, setIsLoading] = useState(!isOfflineOrCombiendExperience)
  const [ticketsList, setTicketsList] = useState([])

  useEffect(() => {
    if (isOfflineOrCombiendExperience) {
      setIsLoading(false)
    }
  }, [isOfflineOrCombiendExperience])

  const updateBookingTicketList = useCallback(() => {
    if (!date || !hour || !activityCompanyId || !activityId) return []
    setIsLoading(true)
    FeeService.getPrices(activityCompanyId, activityId, date, hour, type, pickupPlaceId).then(tickets => {
      if (tickets && tickets.length) {
        setTicketsList(tickets)
      } else {
        setTicketsList([])
        addToast('Não há tarifários cadastrados para esse período')
      }
    }).finally(() => setIsLoading(false))
  }, [activityCompanyId, activityId, date, hour, addToast, type, pickupPlaceId])

  useEffect(() => {
    if (!isOfflineOrCombiendExperience) {
      updateBookingTicketList()
    }

    return () => {
      if (type === OFFLINE_EXPERIENCE_TYPE || !activityCompanyId || !activityId) {
        setTicketsList([])
      }
    }
  }, [type, updateBookingTicketList, activityCompanyId, activityId, isOfflineOrCombiendExperience])

  return { ticketsList, isLoading }
}

/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core'
import {
  splitOption,
  reservationPaymentTypesOptions,
  refundPaymentTypesOptions,
  pinpadOptions
} from 'constants/reservationPaymentTypes'
import {
  Row,
  Column,
  InputFormGroup,
  Select,
  Input,
  UploadInline,
  GhostButton,
  flexHorizontallyCentered,
  LightInfoAlert,
  AsyncButton,
  InfoSwatch,
  flexColumn,
  Label,
  DatePicker,
  InputTimeMask,
  useToast
} from '@bonitour/components'
import {
  useTransactionFormAcquirers as useTransactionAcquirers
} from './hooks/useTransactionFormAcquirers'
import { hidden } from 'assets/styles/global'
import { useForm } from '@bonitour/app-functions'
import { useAcquirersOptions } from './hooks/useAcquirersOptions'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { maxInstallmentsOptions } from './constants/maxInstallmentsOptions'
import { useVendorsList } from 'hooks/domains/useVendorList'
import { useListBankAccountByPaymentMethod } from 'domains/BankAccounts/hooks/useListBankAccountByPaymentMethod'
import { useCompany } from 'contexts/Company'

import {
  alert,
  buttonsContainer,
  cancelButton,
  fullInput,
  link,
  marginBottom10,
  marginTop10,
  maxInstallmentsDrawer,
  multipontoOption,
  selectDrawerOpenToTop
} from './TransactionForm.styles'
import { formatISOTime } from 'utils/time'
import { fullWidth } from 'containers/Invoice/PaymentRegister'
import { usePermission } from 'contexts/Permissions'
import { useFeatureFlag } from 'contexts/Feature'
import { apiPaymentMethods } from 'constants/paymentMethods'
import { useReservation } from 'hooks/context/Reservation'

export const TransactionForm = ({
  onCloseClick,
  formData,
  formSchema,
  onSuccesValidation,
  onFailedValidation,
  isRefund,
  hasForm = true,
  hasAutDoc = true,
  hasInstallments = true,
  hasMaxInstallments = false,
  isPaymentLink = false,
  isPaymentLinkMultiponto = false,
  isDisabled = false,
  transactionFormLoading = false,
  isLinkCreateLoading = false,
  isPinpadPayment = false
}) => {
  const [isMultipontoPaymentEnabled, multipontoPaymentVariables] = useFeatureFlag('orb-multiponto-payment')
  const formDataDefault = useMemo(() => ({
    id: formData?.id,
    aut: formData?.aut,
    doc: formData?.doc,
    method: formData?.method,
    installments: formData?.installments,
    upload: formData?.upload,
    bankAccount: formData?.bankAccount,
    maxInstallments: formData?.maxInstallments || 10,
    time: formData?.time || formatISOTime(new Date(), 'HH:mm'),
    acquirer: formData?.acquirerId,
    vendorId: formData?.customVendorId,
    day: formData?.day || formatISOTime(new Date(), 'YYYY-MM-DD')
  }), [
    formData?.id, formData?.aut, formData?.doc,
    formData?.method, formData?.installments,
    formData?.upload, formData?.bankAccount,
    formData?.maxInstallments, formData?.time,
    formData?.acquirerId, formData?.customVendorId,
    formData?.day
  ])
  const {
    form,
    errors,
    onSubmit,
    utils: { onInputBlur, onInputChange }
  } = useForm(formDataDefault, formSchema)

  const { add: addToast } = useToast()

  const { id: companyId } = useCompany()

  const {
    method = '',
    installments = '',
    upload = [],
    aut = '',
    doc = '',
    acquirer = '',
    maxInstallments = 10,
    vendorId = '',
    bankAccount = '',
    day = formatISOTime(new Date(), 'YYYY-MM-DD'),
    time = formatISOTime(new Date(), 'HH:mm')
  } = form

  const { isCredit, isDebit, isPayCredit, isPix } = useMemo(() => ({
    isCredit: method === apiPaymentMethods.credit,
    isDebit: method === apiPaymentMethods.debit,
    isPayCredit: method === apiPaymentMethods.pay_credit,
    isPix: method === apiPaymentMethods.pix,
    isBankBillet: method === apiPaymentMethods.bank_billet,
    isBankTransfer: method === apiPaymentMethods.bank_transfer
  }), [method])

  const handleSuccess = useCallback((...args) => {
    if (isPix && !acquirer && !bankAccount) {
      addToast('Selecione um adquirente ou uma conta bancária para continuar.')
      return
    }
    onSuccesValidation(...args)
  }, [acquirer, addToast, bankAccount, isPix, onSuccesValidation])

  const [submitAttempted, setSubmitAttempted] = useState(false)

  const handleSubmit = useCallback((values) => {
    setSubmitAttempted(true)
    handleSuccess(values)
  }, [handleSuccess, setSubmitAttempted])

  const onFail = useCallback((values) => {
    setSubmitAttempted(true)
    onFailedValidation(values)
  }, [onFailedValidation])

  const onClick = onSubmit(handleSubmit, onFail)

  const { acquirersList, fetchAcquirers } = useAcquirersOptions(companyId)
  const { fetchBankAccountsByPaymentMethod, bankAccountsOptions = [] } = useListBankAccountByPaymentMethod()

  const resetNecessaryFieldsOnPaymentMethodChange = useCallback(() => {
    onInputChange('installments')(null)
    onInputChange('acquirer')(undefined)
    onInputChange('bankAccount')(undefined)
    onInputChange('doc')(null)
    onInputChange('aut')(null)
  }, [onInputChange])

  const clearInstallments = useCallback(() => {
    onInputChange('installments')(isDebit ? 1 : 0)
    // eslint-disable-next-line
  }, [isDebit])

  const hasAcquirers = useMemo(() => Boolean(acquirersList?.length > 0 || installments), [acquirersList?.length, installments])

  const { acquirersOptions, installmentsOptions } = useTransactionAcquirers(
    form.method,
    acquirersList,
    form.acquirer,
    clearInstallments
  )

  const { vendorsOptions = [] } = useVendorsList(true, 'Nenhum')

  const methodsAcceptedForBankAccount = useMemo(() => [apiPaymentMethods.pix, apiPaymentMethods.bank_billet, apiPaymentMethods.bank_transfer], [])

  const isMethodAcceptedForBankAccount = useMemo(
    () => methodsAcceptedForBankAccount.includes(method),
    [method, methodsAcceptedForBankAccount]
  )

  const methodOptions = useMemo(() => {
    if (isPinpadPayment) return pinpadOptions
    if (isPayCredit) return splitOption
    if (isRefund && !isDebit) return refundPaymentTypesOptions
    if (isRefund) return [reservationPaymentTypesOptions.find((option) => option.value === method) ?? {}]
    return reservationPaymentTypesOptions
  }, [isDebit, isPayCredit, isRefund, method, isPinpadPayment])

  const { allowed: canCreateMultiponto } = usePermission({
    permission: 'multiponto_payment_link',
    action: 'create'
  })

  const canEditMultiponto = useMemo(
    () =>
      isMultipontoPaymentEnabled && canCreateMultiponto && multipontoPaymentVariables?.allowedCompanies?.companies?.includes(companyId),
    [canCreateMultiponto, companyId, isMultipontoPaymentEnabled, multipontoPaymentVariables?.allowedCompanies?.companies]
  )

  useEffect(() => {
    if (!form.installments && formData?.installments && installmentsOptions?.length) {
      onInputChange('installments')(String(formData.installments))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.installments, formData?.installments, installmentsOptions])

  const hasAcquirerSelected = useMemo(() => Boolean(acquirer), [acquirer])

  const hasBankAccountSelected = useMemo(() => Boolean(bankAccount), [bankAccount])

  const bankAccountColumnStyle = useMemo(() => {
    return (!isMethodAcceptedForBankAccount || (hasAcquirerSelected && isPix)) ? hidden : ''
  }, [isMethodAcceptedForBankAccount, hasAcquirerSelected, isPix])

  const bankAccountLabel = useMemo(() => (isRefund ? 'Conta de origem' : 'Conta de destino'), [isRefund])

  const handleOnSelectMethod = useCallback((value) => {
    resetNecessaryFieldsOnPaymentMethodChange()
    if (value === apiPaymentMethods.pix) {
      fetchAcquirers({ companyId, transactionType: apiPaymentMethods.pix })
    }
    onInputChange('method')(value)
  }, [companyId, fetchAcquirers, onInputChange, resetNecessaryFieldsOnPaymentMethodChange])
  const { getSubordinates, subordinatesLoading: isLoadingSubordinates } = useReservation()

  useEffect(() => {
    if (methodsAcceptedForBankAccount.includes(method)) fetchBankAccountsByPaymentMethod({ paymentMethod: method, companyId })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [method])

  const handleOnSelectVendor = useCallback(async (vendorId) => {
    onInputChange('vendorId')(vendorId)

    const isSubordinates = await getSubordinates(vendorId)

    if (!isSubordinates) {
      addToast('O fornecedor selecionado não está apto ao pagamento multiponto')
      return onInputChange('vendorId')('')
    }
  }, [addToast, getSubordinates, onInputChange])

  const hasValidVendorId = useMemo(() => !isPaymentLinkMultiponto ? true : Boolean(form?.vendorId), [form?.vendorId, isPaymentLinkMultiponto])

  const isAsyncButtonDisabled = useMemo(() => {
    return isDisabled ||
      transactionFormLoading ||
      isLinkCreateLoading ||
      isLoadingSubordinates ||
      !hasValidVendorId
  }, [
    isDisabled,
    transactionFormLoading,
    isLinkCreateLoading,
    isLoadingSubordinates,
    hasValidVendorId
  ]
  )

  const onBankAccountChange = useCallback((value) => {
    if (method === apiPaymentMethods.pix) {
      onInputChange('acquirer')('')
    }
    onInputChange('bankAccount')(value)
  }, [method, onInputChange])

  const onAcquirerChange = useCallback((value) => {
    if (method === apiPaymentMethods.pix) {
      onInputChange('bankAccount')('')
    }
    onInputChange('acquirer')(value)
  }, [method, onInputChange])

  const autDocColumnStyle = useMemo(() => {
    if (!hasAcquirers || !acquirer) return [hidden]
    if (!(isCredit || isDebit || isPix)) return [hidden]
    if (!(hasForm && hasAutDoc) && !isRefund) return [hidden]
    return []
  }, [acquirer, hasAcquirers, hasAutDoc, hasForm, isCredit, isDebit, isPix, isRefund])

  return (
    <>
      <Row>
        <Column phone={12} desktop={3} css={!hasForm && hidden}>
          <InputFormGroup label="Forma de pagamento" css={fullInput}>
            <Select
              options={methodOptions}
              value={method}
              css={selectDrawerOpenToTop}
              error={errors.method}
              onChange={handleOnSelectMethod}
              onBlur={onInputBlur('method')}
              disabled={isPayCredit}
            />
          </InputFormGroup>
        </Column>
        {!isPaymentLink && !isPaymentLinkMultiponto && !isPinpadPayment && (
          <>
            <Column phone={7} desktop={2} css={!hasForm && hidden}>
              <InputFormGroup label={isRefund ? 'Data do reembolso' : 'Data do pagamento'} css={fullInput}>
                <DatePicker
                  customCss={[fullWidth]}
                  value={day}
                  onChange={onInputChange('day')}
                  onBlur={onInputBlur('day')}
                  maxDate={new Date()}
                  error={errors.day}
                  allowsEmpty
                />
              </InputFormGroup>
            </Column>
            <Column phone={5} desktop={1} css={!hasForm && hidden}>
              <InputFormGroup label="Horário" css={fullInput}>
                <InputTimeMask
                  value={time}
                  error={errors.time}
                  onChange={onInputChange('time')}
                  onBlur={onInputBlur('time')}
                />
              </InputFormGroup>
            </Column>
          </>
        )}
        <Column phone={12} desktop={2} css={bankAccountColumnStyle}>
          <InputFormGroup label={bankAccountLabel} css={fullInput} errorMessage={submitAttempted && errors.bankAccount}>
            <Select
              options={bankAccountsOptions}
              value={bankAccount}
              css={selectDrawerOpenToTop}
              error={submitAttempted && errors.bankAccount}
              onChange={onBankAccountChange}
              onBlur={onInputBlur('bankAccount')}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={2} css={[!(isCredit || isDebit || isPix) && hidden, !hasAcquirers && hidden, hasBankAccountSelected && !hasAcquirerSelected && hidden]}>
          <InputFormGroup label="Adquirente e bandeira" errorMessage={submitAttempted && errors.acquirer}>
            <Select
              options={acquirersOptions}
              value={acquirer}
              css={selectDrawerOpenToTop}
              error={submitAttempted && errors.acquirer}
              onChange={onAcquirerChange}
              onBlur={onInputBlur('acquirer')}
            />
          </InputFormGroup>
        </Column>
        <Column
          phone={12}
          desktop={1}
          css={[!hasAcquirers && hidden, !(isCredit && hasForm) && hidden, !hasInstallments && !isRefund && hidden]}
        >
          <InputFormGroup label="Parcelas" errorMessage={errors.installments}>
            <Select
              options={installmentsOptions}
              value={installments}
              css={selectDrawerOpenToTop}
              error={errors.installments}
              onChange={onInputChange('installments')}
              onBlur={onInputBlur('installments')}
            />
          </InputFormGroup>
        </Column>

        {!isPinpadPayment && (
          <>
            <Column
              phone={12}
              desktop={2}
              css={autDocColumnStyle}
            >
              <InputFormGroup label="Aut" errorMessage={errors.aut}>
                <Input
                  value={aut}
                  css={fullInput}
                  error={errors.aut}
                  onChange={onInputChange('aut')}
                  onBlur={onInputBlur('aut')}
                />
              </InputFormGroup>
            </Column>

            <Column
              phone={12}
              desktop={2}
              css={autDocColumnStyle}
            >
              <InputFormGroup label="Doc" errorMessage={errors.doc}>
                <Input
                  value={doc}
                  css={fullInput}
                  error={errors.doc}
                  onChange={onInputChange('doc')}
                  onBlur={onInputBlur('doc')}
                />
              </InputFormGroup>
            </Column>

            <Column desktop={3} css={!hasForm && hidden}>
              <InputFormGroup label="Anexar comprovante (max 15mb)">
                <UploadInline
                  files={upload}
                  limitMbSize={15}
                  accept="image/png, image/jpeg, application/pdf"
                  error={errors.upload}
                  onChange={onInputChange('upload')}
                  onBlur={onInputBlur('upload')}
                />
              </InputFormGroup>
            </Column>
          </>
        )}

        {hasMaxInstallments && (
          <Column phone={12} desktop={3}>
            <div css={flexColumn}>
              <div css={[flexHorizontallyCentered, marginBottom10]}>
                <Label>Limite máximo de parcelas</Label>
                <InfoSwatch
                  tooltip="Esse será o número máximo de parcelas permitidas para o viajante realizar a compra no pagamento do link"
                  size={280}
                />
              </div>
              <InputFormGroup errorMessage={errors.maxInstallments}>
                <Select
                  options={maxInstallmentsOptions}
                  value={maxInstallments}
                  css={[selectDrawerOpenToTop, maxInstallmentsDrawer]}
                  error={errors.maxInstallments}
                  onChange={onInputChange('maxInstallments')}
                  onBlur={onInputBlur('maxInstallments')}
                />
              </InputFormGroup>
            </div>
          </Column>
        )}

        {isPaymentLinkMultiponto && (
          <Column phone={12} desktop={3}>
            <InputFormGroup label="Fornecedor multiponto" css={[fullInput, multipontoOption]}>
              <Select
                options={vendorsOptions}
                value={vendorId}
                css={[selectDrawerOpenToTop]}
                error={errors.vendorId}
                onChange={handleOnSelectVendor}
                onBlur={onInputBlur('vendorId')}
              />
            </InputFormGroup>
          </Column>
        )}
        {!isPaymentLink && !isRefund && (isCredit || isDebit) && !isPinpadPayment && (
          <Column phone={12} desktop={3}>
            {
              (canEditMultiponto || vendorId) && (
                <InputFormGroup label="Fornecedor multiponto" css={[fullInput, multipontoOption]}>
                  <Select
                    options={vendorsOptions}
                    value={vendorId}
                    css={[selectDrawerOpenToTop]}
                    error={errors.vendorId}
                    onChange={onInputChange('vendorId')}
                    onBlur={onInputBlur('vendorId')}
                    disabled={!canEditMultiponto || isLoadingSubordinates}
                  />
                </InputFormGroup>
              )
            }
          </Column>
        )}
      </Row>

      <Row css={[!(isCredit || isDebit || isPix) && hidden, acquirersOptions.length && hidden]}>
        <Column css={marginTop10}>
          <LightInfoAlert customCss={alert}>
            <span>Você ainda não possui adquirentes cadastrados para registrar pagamentos com cartões.</span>
            <Link to="/acquirers" target="_blank" rel="noopener noreferrer" css={link}>
              Cadastrar adquirente
            </Link>
          </LightInfoAlert>
        </Column>
      </Row>

      <div css={buttonsContainer}>
        <GhostButton css={cancelButton} onClick={onCloseClick}>
          Voltar
        </GhostButton>
        <AsyncButton onClick={onClick} disabled={isAsyncButtonDisabled}>
          {isPinpadPayment ? 'Cobrar' : isPaymentLink ? 'Gerar link' : 'Registrar'}
        </AsyncButton>
      </div>
    </>
  )
}

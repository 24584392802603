import { useQuery } from 'react-query'
import { ActivityService } from 'core/services/Activity'
import { useActivity } from 'contexts/Activity'
import { useCallback, useMemo } from 'react'
import { COMBINED_EXPERIENCE_TYPE } from 'constants/activityTypes'

const LOCKED_INTERVAL_TIME = 1 * 1000 * 60 // 1 minute
const UNLOCKED_INTERVAL_TIME = 5 * 1000 * 60 // 5 minutes

export const useIsLockedService = () => {
  const activityData = useActivity()

  const {
    id: serviceId,
    activity: {
      experiences,
      type
    },
    isLoadingActivityFetch
  } = activityData

  const isCombinedExperience = useMemo(() => type === COMBINED_EXPERIENCE_TYPE, [type])

  const dataHasSomeLock = useCallback((data) => {
    if (Array.isArray(data)) {
      return data.some(({ isLocked }) => isLocked)
    }
    return data?.isLocked
  }, [])

  const {
    data,
    isLoading,
    isFetching,
    isRefetching,
    refetch: refetchGetIsLockedService
  } = useQuery({
    queryKey: ['isLockedService', serviceId, isCombinedExperience.toString()],
    queryFn: () => {
      if (isCombinedExperience) {
        return Promise.all(experiences.map(({ serviceId }) => ActivityService.getIsLockedService(serviceId)))
      }

      return ActivityService.getIsLockedService(serviceId)
    },
    refetchInterval: data =>
      dataHasSomeLock(data) ? LOCKED_INTERVAL_TIME : UNLOCKED_INTERVAL_TIME,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: false,
    enabled: Boolean(!isLoadingActivityFetch && type)
  })

  const lockedData = useMemo(() => {
    if (isCombinedExperience) {
      return {
        experiences: data?.reduce((acc, { isLocked }, index) => {
          if (isLocked) {
            acc.push({ isLocked, serviceType: experiences?.[index].type, activityTitle: experiences?.[index].serviceTitle })
          }
          return acc
        }, [])
      }
    }
    return data || { isLocked: false }
  }, [data, experiences, isCombinedExperience])

  return {
    refetchGetIsLockedService,
    lockedData,
    isLoading: isLoading || isFetching || isRefetching
  }
}

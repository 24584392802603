/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { flexRow, GhostButton, Button, Timeline, useToast } from '@bonitour/components'
import { jsx } from '@emotion/core'
import { useEffect, useState, useCallback, useMemo } from 'react'
import { FeeService } from 'services/Fee/Service'
import { marginTop, marginRight, resetLink, marginBottom } from 'assets/styles/global'
import { NavLink, useHistory } from 'react-router-dom'
import { AddLabel } from 'components/AddLabel'
import { PeriodListParser } from 'services/PeriodList/Parser'
import { ActivityFeeList } from 'containers/Activity/ActivityFeeList/ActivityFeeList'
import { identity } from '@bonitour/common-functions'
import { useActivity } from 'contexts/Activity'
import { getCorrectPath } from 'hooks/useRouteByType'

const marginBottom40 = marginBottom(40)

export const ListActivityFee = ({
  onBackStep = identity,
  onNextStep = identity
}) => {
  const [list, setList] = useState([])
  const { add: addToast } = useToast()
  const [periodList, setPeriodList] = useState([])
  const { id: activityId, activity: { type } } = useActivity()
  const history = useHistory()

  const getFees = useCallback(() =>
    FeeService.get(activityId).then(feeList => {
      setList(feeList)
      setPeriodList(PeriodListParser(feeList))
    }), [activityId])

  const removeFee = feeId => () => FeeService.remove(activityId, feeId).then(() => {
    getFees()
    addToast('Tarifário excluído com sucesso', 'success')
  })

  useEffect(() => {
    getFees()
  }, [getFees])

  const baseNavigationPath = useMemo(() => {
    return getCorrectPath(type, `${activityId}`)
  }, [activityId, type])

  const navigateToFee = feeId => () => history.push(`${baseNavigationPath}/fee/${feeId}/edit`)

  return (
    <>
      <ActivityFeeList activityFeeList={list} onDelete={removeFee} onNavigation={navigateToFee} />

      <NavLink css={resetLink} to={`${baseNavigationPath}/edit/fee/create`}>
        <AddLabel>Nova tarifa</AddLabel>
      </NavLink>

      {Boolean(periodList.length) && <Timeline css={marginBottom40} periodList={periodList} />}

      <div css={[flexRow, marginTop(20)]}>
        <GhostButton onClick={onBackStep} css={marginRight(20)}>
          Voltar
        </GhostButton>
        <Button onClick={onNextStep}>Continuar</Button>
      </div>
    </>
  )
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { useState } from 'react'
import { jsx, css } from '@emotion/core'
import { Loading, Card, Row, Column, HeaderPage } from '@bonitour/components'
import { useHistory } from 'react-router-dom'
import { useTicketsCheckin } from './hooks/useTicketsCheckIn'
import { TicketCheckinList } from './TicketList'
import { TicketCheckinReader } from './TicketReader'
import { TicketSearch } from './TicketSearch'
import { SelectedTicketsDialog } from 'domains/MyDay/SelectedTicketsDialog/SelectedTicketsDialog'

const card = css`
  width: 100%;
  overflow: hidden;
  padding: 20px;
`

const ticketHeadings = [{ title: 'Obtendo ingressos' }]

export const TicketCheckin = () => {
  const [isReaderVisible, setReaderVisibility] = useState(false)
  const { isTicketsLoading, isModalVisible, validateCode, onModalClose, ticketForm, ticketList, onValidateClick, onSearch, loading } = useTicketsCheckin()
  const openReader = () => setReaderVisibility(true)
  const closeReader = () => setReaderVisibility(false)
  const { push } = useHistory()
  const backToDashboard = () => push('/dashboard')

  return (
    <>
      <HeaderPage onBack={backToDashboard} title='Utilizar ingresso' />

      <TicketSearch formState={ticketForm} onSearch={onSearch} onScan={openReader} />

      <Row>
        <Column phone={12}>
          <Card css={card}>
            <TicketCheckinList ticketList={ticketList} onValidate={onValidateClick} loading={loading} />
          </Card>
        </Column>
      </Row>

      <SelectedTicketsDialog
        isVisible={isModalVisible}
        quantity={1}
        onCloseDialog={onModalClose}
        onSubmitValidation={validateCode}
      />
      <TicketCheckinReader isVisible={isReaderVisible} onSuccess={onSearch} onClose={closeReader} />
      <Loading isLoading={isTicketsLoading} headings={ticketHeadings} />
    </>
  )
}

import { orbCore } from 'services/Orb/Service'
import { ReservationMapParser } from './Parser'

export const ReservationMapService = {
  getMap ({ serviceId, companyId, startDate, endDate, type, pickupPlaceId, experienceId }) {
    return orbCore.reservationMapDomain.list(serviceId, companyId, startDate, endDate, type, pickupPlaceId, experienceId).then(ReservationMapParser.normal)
  },
  getMapDetails ({ serviceId, companyId, startDate, endDate, hour }) {
    return orbCore.reservationMapDomain
      .details(serviceId, companyId, startDate, endDate, hour)
      .then(ReservationMapParser.detailed)
  },
  getHourlyOptions ({ serviceId, companyId, startDate, endDate }) {
    return orbCore.reservationMapDomain
      .getHourly(serviceId, companyId, startDate, endDate)
      .then(ReservationMapParser.hourlyOptions)
  }
}

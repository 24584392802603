/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/react'

import { useCallback, useMemo, useState } from 'react'

import { ActionBarButtons } from './ActionBarButtons'
import { ActionBarErrorLabel } from './ActionBarErrorLabel'
import { ActionBarTotalizer } from './ActionBarTotalizer'

import { container } from './ActionBar.style'
import { identity } from '@bonitour/common-functions'
import { ActionBarDialog } from './ActionBarDialog'
import { useActivity } from 'contexts/Activity'
import { useHistory } from 'react-router-dom'

export const ActionBar = ({
  onNewReservation = identity,
  getInvalidActivities = identity,
  getIsAllServicesInSameDay = identity,
  getExperienceQueryParams = identity,
  isEnabledReservationMode = false,
  totalPaxSelected = 0,
  totalPrice = 0,
  selectedActivities = [],
  isStaticLayout = false,
  isCommonBookingMap = true,
  reservationCode: reservationCodeParam = '',
  pickupPlaceIdByServiceIndex = null
}) => {
  const {
    activity: {
      id: experienceId
    }
  } = useActivity()

  const {
    push
  } = useHistory()

  const [isVisible, setIsVisible] = useState(false)
  const invalidActivities = useMemo(() => isEnabledReservationMode
    ? getInvalidActivities({
      selectedActivities
    })
    : [], [getInvalidActivities, isEnabledReservationMode, selectedActivities])

  const redirectToLinkBooking = useCallback((reservationCode) => {
    const slotsQueryParam = getExperienceQueryParams()
    const pickupIdsQueryParam = pickupPlaceIdByServiceIndex ? `&pickupPlaceIds=${encodeURIComponent(JSON.stringify(pickupPlaceIdByServiceIndex))}` : ''
    push(`/combined-experience/${experienceId}/bookings/link/${reservationCode}${slotsQueryParam}${pickupIdsQueryParam}`)
  }, [experienceId, getExperienceQueryParams, push, pickupPlaceIdByServiceIndex])

  const onBindClick = useCallback(() => {
    if (isCommonBookingMap) {
      setIsVisible(true)
    } else {
      redirectToLinkBooking(reservationCodeParam)
    }
  }, [isCommonBookingMap, redirectToLinkBooking, reservationCodeParam])

  if (!isEnabledReservationMode) return null

  return (
    <div className={isStaticLayout && 'static__layout'} css={container}>
      <ActionBarErrorLabel
        invalidActivities={invalidActivities}
      />
      <ActionBarTotalizer
        totalPaxSelected={totalPaxSelected}
        totalPrice={totalPrice}
      />
      <div className='row'>
        <ActionBarErrorLabel
          invalidActivities={invalidActivities}
        />
        <ActionBarButtons
          onBind={onBindClick}
          onNewReservation={onNewReservation}
          getIsAllServicesInSameDay={getIsAllServicesInSameDay}
          invalidActivitiesTotals={invalidActivities.length}
          isCommonBookingMap={isCommonBookingMap}
        />
      </div>
      <ActionBarDialog
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        redirectToLinkBooking={redirectToLinkBooking}
      />
    </div>
  )
}

import { useState, useCallback, useMemo } from 'react'
import { identity } from '@bonitour/common-functions'
import { useToast } from '@bonitour/components'
import {
  transactionStates,
  CREATE_PAYMENT_STATE,
  CREATE_REFUND_STATE,
  CREATE_REFUND_SPLIT_STATE,
  CREATE_LINK_STATE,
  CREATE_LINK_MULTIPONTO_STATE,
  EDIT_PAYMENT_STATE,
  EDIT_REFUND_STATE,
  CREATE_LINK_PIX_STATE,
  CREATE_PINPAD_PAYMENT_STATE
} from '../Transactions.states'
import { useTickets } from './useTickets'

const buildFormFunctions = (
  createPayment = identity,
  createLinkPayment = identity,
  createLinkPaymentMultiponto = identity,
  createLinkPaymentPix = identity,
  createRefund = identity,
  createRefundPay = identity,
  editPayment = identity,
  editRefund = identity,
  getTicketsToPay = identity,
  getTicketsToPaymentLink = identity,
  getTicketsToRefund = identity,
  createPinpadPayment = identity
) => ({
  [CREATE_PAYMENT_STATE]: {
    view: transactionStates[CREATE_PAYMENT_STATE],
    sucessMessage: 'Pagamento registrado',
    ticketsGetter: getTicketsToPay,
    callback: createPayment
  },
  [EDIT_PAYMENT_STATE]: {
    view: transactionStates[EDIT_PAYMENT_STATE],
    sucessMessage: 'Pagamento editado',
    ticketsGetter: getTicketsToPay,
    callback: editPayment
  },
  [CREATE_LINK_STATE]: {
    view: transactionStates[CREATE_LINK_STATE],
    sucessMessage: 'Link gerado',
    ticketsGetter: getTicketsToPaymentLink,
    callback: createLinkPayment
  },
  [CREATE_LINK_MULTIPONTO_STATE]: {
    view: transactionStates[CREATE_LINK_MULTIPONTO_STATE],
    sucessMessage: 'Link multiponto gerado',
    ticketsGetter: getTicketsToPaymentLink,
    callback: createLinkPaymentMultiponto
  },
  [CREATE_LINK_PIX_STATE]: {
    view: transactionStates[CREATE_LINK_PIX_STATE],
    sucessMessage: 'Link pix gerado',
    ticketsGetter: getTicketsToPaymentLink,
    callback: createLinkPaymentPix
  },
  [CREATE_REFUND_SPLIT_STATE]: {
    view: transactionStates[CREATE_REFUND_SPLIT_STATE],
    sucessMessage: 'Reembolso registrado',
    ticketsGetter: getTicketsToRefund,
    callback: createRefundPay
  },
  [CREATE_REFUND_STATE]: {
    view: transactionStates[CREATE_REFUND_STATE],
    sucessMessage: 'Reembolso registrado',
    ticketsGetter: getTicketsToRefund,
    callback: createRefund
  },
  [EDIT_REFUND_STATE]: {
    view: transactionStates[EDIT_REFUND_STATE],
    sucessMessage: 'Reembolso editado',
    ticketsGetter: getTicketsToRefund,
    callback: editRefund
  },
  [CREATE_PINPAD_PAYMENT_STATE]: {
    view: transactionStates[CREATE_PINPAD_PAYMENT_STATE],
    sucessMessage: 'Aguardando pagamento na maquininha',
    ticketsGetter: getTicketsToPay,
    callback: createPinpadPayment
  }
})

export const useTransactionForm = (
  createPayment,
  createLinkPayment,
  createLinkPaymentMultiponto,
  createLinkPaymentPix,
  createRefund,
  createRefundPay,
  editPayment,
  editRefund,
  createPinpadPayment
) => {
  const { add: addToast } = useToast()
  const [isVisible, setPaymentVisibility] = useState(false)
  const [isButtonEnabled, setButtonStatus] = useState(true)
  const [transactionFormLoading, setTransactionFormLoading] = useState(false)

  const [transactionFormData, setTransactionForm] = useState({})
  const { tickets, clearTickets, getTicketsToPay, getTicketsToPaymentLink, getTicketsToRefund, loading } = useTickets()

  const [formState, setFormState] = useState(CREATE_PAYMENT_STATE)

  const possibleStates = useMemo(
    () => buildFormFunctions(
      createPayment,
      createLinkPayment,
      createLinkPaymentMultiponto,
      createLinkPaymentPix,
      createRefund,
      createRefundPay,
      editPayment,
      editRefund,
      getTicketsToPay,
      getTicketsToPaymentLink,
      getTicketsToRefund,
      createPinpadPayment
    ),
    [
      createPayment,
      createLinkPayment,
      createLinkPaymentMultiponto,
      createLinkPaymentPix,
      createRefund,
      createRefundPay,
      editPayment,
      editRefund,
      getTicketsToPay,
      getTicketsToPaymentLink,
      getTicketsToRefund,
      createPinpadPayment
    ]
  )
  const { view, callback, sucessMessage, formSchema } = useMemo(() => possibleStates[formState], [possibleStates, formState])

  const openTransactionForm = useCallback(
    (state, data = {}) => {
      const { tickets = [] } = data
      const { ticketsGetter = identity } = possibleStates[state]
      setTransactionForm(data)
      ticketsGetter(tickets)
      setFormState(state)
      setPaymentVisibility(true)
    },
    [possibleStates]
  )

  const closeTransactionForm = useCallback(() => {
    setPaymentVisibility(false)
    setTransactionForm({})
    clearTickets()
    setButtonStatus(true)
  }, [clearTickets])

  const onTransactionSubmit = useCallback(async (data) => {
    const hasSomeZeroAmount = !!data?.ticketsIds.filter(({ amount }) => amount <= 0).length

    if (formState === 'createPayment' && hasSomeZeroAmount) {
      addToast('O valor a pagar não pode ser igual ou menor que zero')
      return
    }

    if (!isButtonEnabled) return

    try {
      setTransactionFormLoading(true)
      setButtonStatus(false)
      await callback(data)
      addToast(sucessMessage, 'success')
      closeTransactionForm()
    } catch (error) {
      const hasFiscalDuty = error?.data?.extra_data?.message?.includes('Reservation already has fiscal duty')
      const errorsMessages = error?.data?.errors_msg
      const errorMessagesMap = {
        'uc_create_reservation_payment::overpayment': 'O valor a pagar não pode ser maior que o valor do ingresso',
        'uc_create_reservation_payment::discount_over_than_allowed': 'O valor do desconto não pode ser maior que o permitido',
        'financial::reservation_payment::aut::taken': 'Este Aut já está registrado em outro pagamento',
        'financial::reservation_payment::doc::taken': 'Este Doc já está registrado em outro pagamento',
        default: 'Ocorreu um erro no registro'
      }

      if (hasFiscalDuty) {
        addToast('Edição não aceita quando nota fiscal já está vinculada')

        if (errorsMessages?.length === 1) return
      }

      if (Array.isArray(errorsMessages)) {
        errorsMessages.forEach((errorMessage) => {
          addToast(errorMessagesMap[errorMessage] ?? errorMessagesMap.default)
        })
      } else {
        addToast(errorMessagesMap.default)
      }
    } finally {
      setTransactionFormLoading(false)
      setButtonStatus(true)
    }
  }, [callback, closeTransactionForm, sucessMessage, addToast, isButtonEnabled, formState])

  const updatedView = useMemo(() => ({ ...view, isVisible }), [view, isVisible])

  return {
    view: updatedView,
    tickets,
    transactionFormData,
    openTransactionForm,
    closeTransactionForm,
    formSchema,
    onTransactionSubmit,
    loading,
    transactionFormLoading
  }
}

import { BREAK_POINTS, colors } from '@bonitour/components'
import { css } from '@emotion/core'

export const startReservationButton = (color) => css`
  border-color: ${color};
  color: ${color};
`

export const startReservationButtonContainer = css`
  display: flex;
  place-content: flex-end;
  width: 100%;
  margin: 0.5rem 1rem 0 0.5rem;
  @media screen and (max-width: ${BREAK_POINTS.bigPhone}) {
    place-content: center;
    margin: 0 0 0 0;
    button {
      width: 100%;
      padding: 0.5rem 0 0.5rem 0;
    }
  }
`

export const reservationList = css`
  display: flex;
  flex-direction: column;
  place-content: flex-start;
  width: 100%;
  margin-top: 0.5rem;
  margin-left: 1rem;
  gap: 1rem;
`

export const reservationLabel = (color) => css`
  font-size: 1.05rem;
  text-align: start;

  .totals {
    border-radius: 6px;
    padding: 0.15rem;
    padding: 0.15rem 0.5rem 0.15rem 0.5rem;
    color: ${colors.white};
    background-color: ${color};
    margin-right: 0.5rem;
  }
`

export const emptyLabel = css`
  padding: 0.10rem 0 0.10rem 0;
  color: ${colors.gray6};
  font-size: 1rem;
  font-weight: 500;
  i {
    margin-right: 0.5rem;
  }
`

/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/react'
import { serviceCombinedExperienceCardImage, serviceCombinedExperienceCardItem, serviceCombinedExperienceSubtitle, serviceCombinedExperienceTitle } from '../ServiceCombinedExperienceList.style'
import { SwatchImage } from 'components/SwatchImage'

export const ServiceCombinedExperienceHeader = ({
  experienceInfo = {},
  index = 0,
  color = null
}) => {
  const { serviceImage, serviceTitle, companyName } = experienceInfo
  return (
    <div css={serviceCombinedExperienceCardItem(index, color)}>
      <SwatchImage
        css={serviceCombinedExperienceCardImage}
        image={serviceImage}
        alternativeText={serviceTitle}
      />
      <div>
        <h3 css={serviceCombinedExperienceTitle}>{serviceTitle}</h3>
        {companyName && (
          <h4 css={serviceCombinedExperienceSubtitle}>{companyName}</h4>
        )}
      </div>
    </div>
  )
}

import axios from 'axios'

const BINAMIK_PRINTER_SERVICE_URL = 'http://localhost:3338/api'

// For some unknown reason, Axios is crashing for POST methods to the
// printer service with error `header name must be a non-empty string`
const post = async (path, payload) => {
  const response = await fetch(`${BINAMIK_PRINTER_SERVICE_URL}/${path}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  })

  if (!response.ok) {
    const error = await response.text()
    throw new Error(`Request failed: ${response.status} ${response.statusText} - ${error}`)
  }
  return true
}

export function BinamikPrinter () {
  return {
    about: async () => {
      return await axios({
        method: 'get',
        baseURL: BINAMIK_PRINTER_SERVICE_URL,
        url: 'v1/about'
      }).then(({ data }) => data)
    },
    health: async ({ printerName }) => {
      return await axios({
        method: 'get',
        baseURL: BINAMIK_PRINTER_SERVICE_URL,
        url: `v1/health${printerName ? `?printerName=${printerName}` : ''}`
      }).then(({ data }) => data)
    },
    print: {
      tickets: async ({ payload, printerName }) =>
        await post(
          'v1/print/orb-tickets',
          { printerName, ...payload }
        ),
      reservation: async ({ payload, printerName }) =>
        await post(
          'v1/print/reservation-success',
          { printerName, ...payload }
        )
    }
  }
}

import { useHistory, useLocation } from 'react-router-dom'
import { getQueryParams } from '@bonitour/common-functions'
import { useCallback, useMemo } from 'react'

const stringfyQueryParams = (params) => {
  return new URLSearchParams(params).toString()
}

export const useQuery = () => {
  const { replace } = useHistory()
  const { search = '' } = useLocation()
  const queryParams = useMemo(() => getQueryParams(search), [search])

  const changeQueriesParam = useCallback((params = {}) => {
    const newParams = { ...queryParams, ...params }
    replace({ search: stringfyQueryParams(newParams) })
  }, [replace, queryParams])

  const changeQueryParam = useCallback((key, value) => {
    const newParams = { ...queryParams, [key]: value }
    replace({ search: stringfyQueryParams(newParams) })
  }, [replace, queryParams])

  const removeQueriesParam = useCallback((params = []) => {
    const newParams = { ...queryParams }

    for (const param of params) {
      delete newParams[param]
    }

    replace({ search: stringfyQueryParams(newParams) })
  }, [replace, queryParams])

  const removeQueryParam = useCallback((key) => {
    const newParams = { ...queryParams }
    delete newParams[key]
    replace({ search: stringfyQueryParams(newParams) })
  }, [replace, queryParams])

  return [queryParams, { changeQueryParam, removeQueryParam, changeQueriesParam, removeQueriesParam }]
}

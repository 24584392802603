/** @jsxRuntime classic */
/** @jsx jsx */
import { identity } from '@bonitour/common-functions'
import { jsx, css } from '@emotion/core'
import { TableBodyRow } from '@bonitour/components'
import { TableBodyHourCell } from '../BodyCell/TableBodyHourCell'
import { useCallback, useMemo } from 'react'
import VacancyTableCell, { MemoizedVacancyTableCell } from './VacancyTableCell'

const cellIsNotSelected = css`
  td:not(:first-of-type) {
    opacity: 0.6;
    backdrop-filter: blur(21px);
  }
`

export const VacancyTableRow = ({
  onExpand: emitExpandEvent = identity,
  getFeesByDate = identity,
  dateColumns = [],
  activitiesList = [],
  datesData = {},
  tableDetailedData = {},
  tablePrices = {},
  VacancySummaryPopoverContent,
  isExpanded = false,
  isExpandable = true,
  isCombinedExperience = false,
  rowHour = '',
  pickupPlaceId,
  ...other
}) => {
  const onExpand = () => emitExpandEvent(rowHour)
  const hasExpandedData = useMemo(() => Object.keys(tableDetailedData).length > 0, [tableDetailedData])

  const getActivitiesByDate = useCallback((date) => {
    return activitiesList.map((activityInfo) => {
      if (!activityInfo) return null
      const { slots } = activityInfo
      return { ...slots[date], ...activityInfo }
    })
  }, [activitiesList])

  const activitiesByDate = useMemo(() => dateColumns.reduce((acc, currDate) => ({
    ...acc,
    [currDate]: getActivitiesByDate(currDate)
  }), {}), [dateColumns, getActivitiesByDate])

  if (isCombinedExperience) {
    return (
      <TableBodyRow {...other}>
        <TableBodyHourCell isExpandable={isExpandable} rowHour={rowHour} />
        {dateColumns.map(date => (
          <MemoizedVacancyTableCell
            key={`combined-experience-${date}`}
            date={date}
            activitiesData={activitiesByDate[date]}
            isCombinedExperience={isCombinedExperience}
            dateColumns={dateColumns}
            getFeesByDate={getFeesByDate}
            rowHour={rowHour}
          />
        ))}
      </TableBodyRow>
    )
  }

  return (
    <TableBodyRow css={[!isExpanded && hasExpandedData && cellIsNotSelected]} {...other}>
      <TableBodyHourCell
        isExpandable={isExpandable}
        rowHour={rowHour}
        isExpanded={isExpanded}
        onClick={onExpand}
      />

      {
        dateColumns.map(date =>
          <VacancyTableCell
            key={date}
            date={date}
            pickupPlaceId={pickupPlaceId}
            vacancies={datesData[date]?.vacancies || {}}
            rowHour={rowHour}
            hasExpandedData={hasExpandedData}
            detailedReservations={tableDetailedData[date]?.reservations || {}}
            reservations={datesData[date]?.reservations || {}}
            onExpand={onExpand}
            isExpanded={isExpanded}
            isExpandable={isExpandable}
            VacancySummaryPopoverContent={VacancySummaryPopoverContent}
            ticketTariff={tablePrices?.[date]}
          />)
      }
    </TableBodyRow>
  )
}

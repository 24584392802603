import { object, string, array } from 'yup'
import { contactPhoneSchema } from 'containers/ContactForm/ContactPhone/ContactPhone.rules'
import { addressSchema } from 'containers/Address/Address.rules'
import { contactEmailSchema } from 'containers/ContactForm/ContactEmail/ContactEmail.rules'

export const activityInfoSchema = (states = [], cities = []) => ({
  activityId: object().shape({
    name: string().required('O nome da atividade é obrigatório')
  }),
  phones: array().of(object().shape(contactPhoneSchema)).min(1, 'Adicione ao menos um telefone'),
  emails: array().of(object().shape(contactEmailSchema)).min(1, 'Adicione ao menos um email'),
  addressInfo: object().shape(addressSchema(states, cities))
})

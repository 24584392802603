/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { H3, Label, Input, Upload, flexColumn, InputFormGroup, UploadFootnote, BREAK_POINTS } from '@bonitour/components'
import { marginBottom } from 'assets/styles/global'
import { identity } from '@bonitour/common-functions'

const marginBottom20 = marginBottom(20)

const width360 = css`
  width: 360px;

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    width: 100%;
  }
`

export const ActivityId = ({
  activityId = {},
  activityIdErrors = {},
  onInputBlur: emitInputBlur = identity,
  onChange: emitChangeEvent = identity,
  onError: emitErrorEvent = identity,
  isImporting = false
}) => {
  const { files = [], name = '' } = activityId

  return (
    <>
      <H3 css={[marginBottom20]}>Identificação</H3>
      <InputFormGroup
        css={marginBottom20}
        label={`Nome ${isImporting ? 'do serviço' : 'da atividade'}`}
        errorMessage={activityIdErrors.name}
        id='activityId.name'
        required
      >
        <Input
          css={width360}
          id='activityId.name'
          maxLength='50'
          value={name}
          onChange={emitChangeEvent('activityId.name')}
          onBlur={emitInputBlur('activityId.name')}
        />
      </InputFormGroup>

      <div css={flexColumn}>
        <Label>Imagem de perfil</Label>
        <Upload
          files={files}
          onChange={emitChangeEvent('activityId.files')}
          onError={emitErrorEvent}
          limitMbSize={1}
          emptyMessage='Adicione uma foto com no máximo 1Mb'
          accept='image/png, image/jpeg'
        />
        <UploadFootnote
          typeList={['.jpg', '.png']}
          extraMessage='Proporção sugerida: 200x200 pixels'
        />
      </div>
    </>
  )
}

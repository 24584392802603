/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/react'

import { useMemo } from 'react'

import { reservationLabel, reservationList as reservationListCss, emptyLabel } from './CombinedExperienceReservationListing.style'

import { Label, TicketsIcon } from '@bonitour/components'

const EmptyReservationList = () => (
  <Label css={emptyLabel}>
    <TicketsIcon/>
    Nenhuma reserva realizada
  </Label>
)

export const CombinedExperienceReservationList = ({
  list: reservationList = [],
  color = null,
  activityId = null
}) => {
  const isEmptyReservationList = useMemo(() => !reservationList.length, [reservationList])

  if (isEmptyReservationList) {
    return <EmptyReservationList/>
  }

  return (
    <div css={reservationListCss}>
      {reservationList.map(({ commercialPartner, total }) => (
        <div key={`${commercialPartner}-${activityId}`} css={reservationLabel(color)}>
          <span className={'totals'}>{total}</span>
          <span>{commercialPartner}</span>
        </div>
      ))}
    </div>
  )
}

import { BREAK_POINTS, colors } from '@bonitour/components'
import { css } from '@emotion/core'

export const dialogStyle = css`
  min-width: 31.25rem;
`

export const singleLineAddButton = css`
  padding: 0.625rem 0.313rem;
  span {
    margin-left: 0;
  }
`
export const cardContainerStyle = css`
  gap: 1.40rem;
  margin-bottom: 0;
  @media screen and (max-width: ${BREAK_POINTS.phone}) {
    place-content: center;
    gap: 1.25rem;
  }
`

export const integrationsLinkStyle = css`
  font-size: 0.875rem;
  color: ${colors.primary};
  margin: 0 0.125rem -0.5rem auto;
`

export const cardOptionStyle = css`
  border: 0.063rem solid ${colors.indigo1};
  display: flex;
  flex-direction: column;
  place-content: center;
  place-items: center;
  border-radius: var(--round-element-radius, 100%);
  width: 10rem;
  height: 5.625rem;
  &, label, i {
    cursor: pointer;
    font-size: 1rem;
    color: ${colors.indigo1};
  }
  transition-duration: 600ms;
  &:hover {
    background-color: ${colors.indigo3};
  }
  div {
    position: relative;
  }
  i {
    font-size: 2rem;
  }
  label {
    width: 100%;
    text-align: center;
    white-space: pre-wrap;
    max-inline-size: 70%;
  }
  @media screen and (max-width: ${BREAK_POINTS.phone}) {
    width: 8rem;
  }
  @media screen and (max-width: ${BREAK_POINTS.smallPhone}) {
    width: 100%;
  }
`

import { css } from '@emotion/core'
import { colors, scrollBar } from '@bonitour/components'

export const selectorContainer = css`
  display: flex;
  flex-direction: column;
  ${scrollBar(5)}
`

export const searchIcon = css`
  font-size: 20px;
`

export const label = css`
  font-size: 14px;
  color: ${colors.gray2};
  margin: 10px 0;
`

export const listContainer = css`
  position: relative;
`

export const smallEmptyResult = css`
  max-height: 2rem;
  padding: 0 0.75rem 0.75rem;
  p {
    color: ${colors.gray6};
  }
`

export const forceUpdateButton = css`
  position: absolute;
  top: 0;
  left: 25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0.625rem;
  height: 2.5625rem;
  border-color: ${colors.gray8};
  transition: padding 300ms ease-in-out;
  background-color: ${colors.white};
  i {
    line-height: 1;
    font-size: 1rem;
    transition: transform 300ms ease-in-out;
    color: ${colors.gray5};
    -webkit-text-stroke: 0.5px ${colors.gray5};
  }
  p {
    white-space: nowrap;
    max-width: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-width 300ms ease-in-out, opacity 300ms ease-in-out;
    color: ${colors.gray3};
    font-size: 0.875rem;
    font-weight: 500; 
    margin: 0 -0.5rem 0 0.5rem;
  }
  &:hover {
    padding-right: 1.25rem;
    p {
      max-width: 6rem;
      opacity: 1;
    }
    i {
      transform: rotate(180deg);
    }
  }
  @media (max-width: 546px) {
    left: auto;
    right: 0;
  }
`

export const loadingUpdateButton = css`
  background-color: ${colors.gray14};
  pointer-events: none;
  i {
    @keyframes spin {
      to { transform: rotate(360deg); }
    }
    animation: spin 1s linear infinite;
  }
`

export const searchWidth = css`
  max-width: 100%;
  width: 24rem;
  @media (max-width: 546px) {
    width: calc(100% - 2.75rem);
  }
`

export const listSelector = css`
  overflow-y: auto;
  scrollbar-width: thin;
  height: calc(100dvh - 16rem);
  position: relative;
  padding: 0.5rem 1rem 0.5rem 0;
  margin: 0.5rem -1rem -0.5rem 0;

  @media (max-width: 546px) {
    height: calc(100dvh - 18rem);
  }
  @media (max-height: 390px) {
    height: calc(100dvh - 10rem) !important;
  }
`

export const menuScroll = css`
  margin-bottom: 0.25rem;
  width: 100%;
  height: auto !important;
  @media (max-height: 390px) {
    height: auto !important;
  }
`

export const noScroll = css`
  overflow: hidden;
  height: auto;
  max-height: none !important;
  height: auto !important;
`

export const smallLoading = css`
  margin: 0.25rem 0;
  transform: scale(0.8);
  opacity: 0.5;
`

export const gridContainer = css`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(11rem, 1fr));
  grid-gap: 1rem;

  @media (max-width: 546px) {
    grid-template-columns: repeat(auto-fill, minmax(8rem, 1fr));
  }
`

export const menuGrid = css`
  grid-gap: 0.5rem;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr)) !important;
`

export const gridItem = (showActionLabel = false, smallCards = false) => css`
  padding: 0;
  ${smallCards
    ? ''
    : `min-height: ${showActionLabel ? 14 : 10}rem;`}
  
  > div {
    margin: 0;
    padding: ${smallCards ? '0.25rem 0.5rem' : '0.75rem 0'};
    box-sizing: border-box;
  }
`

export const offlineSearchRow = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  > * {
    width: calc(calc(100% / 3) - 5px);
  }
`

export const hideSmallHeight = css`
  @media screen and (max-height: 420px) {
    display: none;
  }
`

export const customPillContainerCss = css`
  @media (max-width: 800px) {
    flex-wrap: wrap;
    width: 30.0625rem;
  }
  @media (max-width: 546px) {
    width: 16.0625rem;
  }
`

export const compactPillContainerCss = css`
  flex-wrap: wrap;
  width: 17.3125rem;
`

export const customPillCss = css`
  width: auto;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  text-align: center;
  flex-direction: row !important;

  &:not(:last-of-type) {
    border-right: 1px solid ${colors.gray10};
  }

  i {
    font-size: 1.25rem;
  }
  @media (max-width: 960px) {
    max-width: 7.5rem;
  }
  @media (max-width: 800px) {
    max-width: 13rem;
    width: 13rem;
    &:nth-of-type(2n) {
      border-right: none;
    }
    &:not(:nth-last-of-type(1), :nth-last-of-type(2):nth-of-type(2n+1)) {
      border-bottom: 1px solid ${colors.gray10};
    }
    &:nth-of-type(2n+1):last-of-type {
      margin: 0 auto;
    }
  }
  @media (max-width: 546px) {
    width: 6rem;
  }
`

export const compactPillCss = css`
  ${customPillCss}
  &:nth-of-type(2n) {
    border-right: none;
  }
  &:not(:nth-last-of-type(1), :nth-last-of-type(2):nth-of-type(2n+1)) {
    border-bottom: 1px solid ${colors.gray10};
  }
  &:nth-of-type(2n+1):last-of-type {
    margin: 0 auto;
  }
  flex-direction: row;
  width: 6.625rem !important;
`

export const customActivePillCss = css`
  background-color: ${colors.indigo2};
  color: ${colors.white};
`

export const scrollLoadingContainer = (showActionLabel, disableScroll, smallCards) => {
  const cardHeight = smallCards ? 6.5 : showActionLabel ? 14.5 : 10.5
  return css`
    width: 100%;
    height: ${cardHeight}rem;
    margin-top: -${cardHeight}rem;
    background-color: ${disableScroll ? colors.gray13 : colors.white};
  `
}

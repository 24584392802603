/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { identity } from '@bonitour/common-functions'
import { jsx } from '@emotion/react'

import { buttonsContainer, primaryButton } from './ActionBar.style'
import { AddTicketIcon, GhostButton, LinkIcon, useToast } from '@bonitour/components'
import { useCallback } from 'react'
import { hidden } from 'assets/styles/global'

export const ActionBarButtons = ({
  onNewReservation: emitNewReservationClick = identity,
  onBind: emitBindClick = identity,
  getIsAllServicesInSameDay = identity,
  invalidActivitiesTotals = 0,
  isCommonBookingMap = true
}) => {
  const { add: addToast } = useToast()

  const getHasError = useCallback(() => {
    const isAllServicesInSameDay = getIsAllServicesInSameDay()

    if (!isAllServicesInSameDay) {
      addToast('Os serviços devem ocorrer no mesmo dia')
      return true
    }

    if (invalidActivitiesTotals) {
      addToast('Existem atividades com ingressos faltantes')
      return true
    }

    return false
  }, [addToast, getIsAllServicesInSameDay, invalidActivitiesTotals])

  const onNewReservationClick = useCallback(() => {
    const hasError = getHasError()

    if (hasError) return

    emitNewReservationClick()
  }, [emitNewReservationClick, getHasError])

  const onBindClick = useCallback(() => {
    const hasError = getHasError()

    if (hasError) return

    emitBindClick()
  }, [emitBindClick, getHasError])

  return (
    <div css={buttonsContainer} className='buttons__container'>
      <GhostButton css={primaryButton} onClick={onBindClick}>
        <LinkIcon/>
        Vincular
      </GhostButton>
      <GhostButton css={isCommonBookingMap ? primaryButton : hidden} onClick={onNewReservationClick}>
        <AddTicketIcon/>
        Nova reserva
      </GhostButton>
    </div>
  )
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useEffect, useState, useCallback } from 'react'
import { MyDayList } from './MyDayList'
import { useListActivities } from '../hooks/useListActivities'
import { useTicketsCheckin } from 'domains/Ticket/CheckIn/hooks/useTicketsCheckIn'
import { Loading } from '@bonitour/components'
import { isDateInFuture } from 'utils/date'
import { SelectedTicketsModal } from '../SelectedTicketsModal/SelectedTicketsModal'
import { SelectedTicketsDialog } from '../SelectedTicketsDialog/SelectedTicketsDialog'
import { useActivity } from 'contexts/Activity'
import { ticketStatusDictionary } from 'constants/ticketStatus'

const ticketHeadings = [{ title: 'Obtendo ingressos' }]

export const MyDay = () => {
  const {
    filters,
    setFilters,
    activities,
    meta,
    loading,
    pagination,
    totals = {},
    exportColumns,
    exporting,
    fetchActivities,
    exportMyDay,
    changePagination,
    initialFilters
  } = useListActivities()

  const { companyActivities } = useActivity()

  const {
    validateManyCodes,
    loading: ticketValidationLoading
  } = useTicketsCheckin()
  const [isSelectedAll, setSelectedAll] = useState(false)
  const [ticketDialogState, setTicketDialogState] = useState()
  const [selectedTickets, setSelectedTickets] = useState([])
  const isTicketInFuture = isDateInFuture(filters?.date)
  const quantity = selectedTickets?.length

  const isOwner = (companyActivities, serviceId) => companyActivities.filter(({ id }) => id === serviceId)?.length > 0

  const isValidateVisible = useCallback((status, serviceId) => {
    const {
      isValidatable = false,
      isValidatableByServiceType: validatableTypes
    } = ticketStatusDictionary[status] || {}

    const service = companyActivities.find(({ id }) => id === serviceId)
    return isValidatable || (Array.isArray(validatableTypes) && validatableTypes.includes(service?.type))
  }, [companyActivities])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const canValidateTicket = useCallback(
    (state, serviceId) => isOwner(companyActivities, serviceId) && !isTicketInFuture && isValidateVisible(state, serviceId),
    [isTicketInFuture, companyActivities, isValidateVisible]
  )

  const closeDialog = () => setTicketDialogState(null)
  const openManyDialog = () => setTicketDialogState({ type: 'many' })
  const openSingleDialog = ({ code }) => setTicketDialogState({ type: 'single', code })

  const isTicketSelect = (selectedTicketCode) =>
    selectedTickets.some(({ ticketCode }) => ticketCode === selectedTicketCode)

  const isTicketSelectable = (status) => status !== 'canceled'

  const onSelectedAllClick = () => setSelectedAll(!isSelectedAll)

  const onTicketClick = (ticket) =>
    isTicketSelect(ticket?.ticketCode)
      ? setSelectedTickets(selectedTickets.filter(({ ticketCode }) => ticketCode !== ticket.ticketCode))
      : isTicketSelectable(ticket) && setSelectedTickets([...selectedTickets, ticket])

  const onValidateMany = () => {
    closeDialog()
    validateManyCodes(selectedTickets, () => fetchActivities({ pagination, filters }))
    setSelectedTickets([])
  }
  const onValidateSingle = () => {
    validateManyCodes([{ ticketCode: ticketDialogState?.code }], () => fetchActivities({ pagination, filters }))
    closeDialog()
  }

  useEffect(() => {
    isSelectedAll
      ? setSelectedTickets(activities.filter((ticket) => canValidateTicket(ticket?.state, ticket?.serviceId)))
      : setSelectedTickets([])
    // eslint-disable-next-line
  }, [isSelectedAll])

  return (
    <>
      <SelectedTicketsDialog
        isVisible={ticketDialogState?.type === 'many'}
        quantity={quantity}
        onCloseDialog={closeDialog}
        onSubmitValidation={onValidateMany}
      />

      <SelectedTicketsDialog
        isVisible={ticketDialogState?.type === 'single'}
        quantity={1}
        onCloseDialog={closeDialog}
        onSubmitValidation={onValidateSingle}
      />

      <SelectedTicketsModal quantity={quantity} onValidateSubmit={openManyDialog} />

      <MyDayList
        onFilterSubmit={setFilters}
        loading={loading}
        exporting={exporting}
        activities={activities}
        initialFilters={initialFilters}
        pagesQuantity={meta?.totalPages}
        total={meta?.total}
        onPagination={changePagination}
        onValidateClick={openSingleDialog}
        totalizer={totals}
        exportColumns={exportColumns}
        onSubmitExport={exportMyDay}
        filters={filters}
        isTicketInFuture={!isTicketInFuture}
        onSelectedAllClick={onSelectedAllClick}
        onTicketClick={onTicketClick}
        isTicketSelectable={isTicketSelectable}
        canValidateTicket={canValidateTicket}
        isTicketSelect={isTicketSelect}
        isSelectedAll={isSelectedAll}
      />

      <Loading isLoading={ticketValidationLoading} headings={ticketHeadings} />
    </>
  )
}

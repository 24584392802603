import { css } from '@emotion/core'

import { BREAK_POINTS, colors } from '@bonitour/components'

import { marginBottom, marginLeft, marginTop } from 'assets/styles/global'

export const marginTop10 = marginTop(10)
export const marginTop20 = marginTop(20)
export const marginLeft10 = marginLeft(10)
export const marginBottom10 = marginBottom(10)
export const marginBottom20 = marginBottom(20)

export const comissionFormGroupContainer = css`
  gap: 1rem;

  .comission_input__ctn {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 22rem;
    gap: 5px;

    @media (max-width: ${BREAK_POINTS.smallTablet}) {
      min-width: auto;
      flex-direction: column;
    }
  }

  .amount_type__container {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .net_fee__label {
    white-space: nowrap;
  }

  @media (max-width: ${BREAK_POINTS.smallTablet}) {
    flex-direction: column;
  }

`

export const grayColor = css`
  color: ${colors.gray3};
`

export const width160 = css`
  width: 10rem;
`

export const sellViaAllyContainer = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 20rem;

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    width: 100%;
  }
`

export const inverseRow = css`
  flex-direction: row-reverse;
`

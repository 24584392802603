/* eslint-disable camelcase */
/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { colors, LoadingAnimation, TableContainer, TableHeader, TableHeaderRow, TableHeaderCell, TableBody, TableBodyRow, TableBodyCell, TruncateCell, flexHorizontallyCentered, TooltipOnHover, CircleThumbnail, CollapsableSection, InfoSwatch } from '@bonitour/components'
import { marginBottom, loadingContainer, circleColor, hidden } from 'assets/styles/global'
import { AddLabel } from 'components/AddLabel'
import { useTransactions } from 'domains/Reservation/Transactions/hooks/useTransactions'
import { useCallback, useMemo, useState } from 'react'
import { ActionsBodyCell } from 'containers/Reservation/Payment/Actions'
import { formatMoney, formatDate } from '@bonitour/common-functions'
import { useFinanceActions } from 'domains/Reservation/Transactions/hooks/useFinanceActions'
import { collapsableCss, tableContainer, paddingRight5, textOverflow, paymentMethodStyle, infoSwatchContainer } from 'containers/Reservation/Payment/TableRow.style'
import { reservationPaymentStatusMap } from 'constants/reservationPaymentStatus'
import { reservationPaymentTypesMap } from 'constants/reservationPaymentTypes'
import { useFeatureFlag } from 'contexts/Feature'

const noBottom = marginBottom(0)
const greenBtn = css`--add-btn-color: ${colors.green2};`

const PaymentRow = ({ payment, card }) => {
  const {
    state,
    date,
    hour,
    aut,
    doc,
    liableUserName,
    value,
    id,
    liableCompanyId,
    method,
    installments,
    customVendorName
  } = payment

  const status = reservationPaymentStatusMap[state]
  const isSent = state === 'sent'

  const isPaidPaymentLink = useMemo(() => state === 'paid', [state])

  return (
    <TableBodyRow>
      <TableBodyCell data-label='Data e Hora' css={paddingRight5}>
        <span>
          {`${formatDate(date)} - ${hour}`}
        </span>
      </TableBodyCell>
      <TableBodyCell data-label='Valor'>{formatMoney(value)}</TableBodyCell>
      <TableBodyCell data-label='Forma de Pagamento'>
        <span css={paymentMethodStyle}>
          {reservationPaymentTypesMap(method, installments)}
          {customVendorName && <InfoSwatch css={infoSwatchContainer} tooltip={`Multiponto: ${customVendorName}`} size={180} />}
        </span>
      </TableBodyCell>
      <TableBodyCell data-label='Aut/Doc' css={paddingRight5}>{aut}/{doc}</TableBodyCell>
      <TruncateCell data-label='Registrado por' css={paddingRight5} text={liableUserName} size='220px' />
      <TableBodyCell data-label='Status' css={paddingRight5}>
        <div css={flexHorizontallyCentered}>
          {Boolean(status) &&
            <TooltipOnHover text={status.label}>
              <CircleThumbnail size={10} css={circleColor(status.color)} />
              <span css={textOverflow}>{status.label}</span>
            </TooltipOnHover>}
        </div>
      </TableBodyCell>
      <ActionsBodyCell
        id={id}
        payment={payment}
        isSent={isSent}
        responsiblePaymentCompanyId={liableCompanyId}
        card={card}
        isPaidPaymentLink={isPaidPaymentLink}
        reservationPaymentId={id}
      />
    </TableBodyRow>
  )
}

export const PaymentsTable = ({
  subordinatesLoading,
  isCommercialPartner,
  card = false
}) => {
  const [pinpadEnabled] = useFeatureFlag('orb_pinpad_integration')
  const { payments, loading: transactionLoading, getPaymentLinkLoading, loadingPayments } = useTransactions()
  const loading = useMemo(() => transactionLoading || getPaymentLinkLoading || loadingPayments, [transactionLoading, getPaymentLinkLoading, loadingPayments])

  const {
    amount,
    onPaymentCreation,
    onPinpadPaymentCreation
  } = useFinanceActions()

  const [expanded, setExpanded] = useState(true)
  const toggleCollapsable = useCallback(() => setExpanded(v => !v), [])

  return (
    <CollapsableSection
      title='Pagamentos manuais'
      isExpanded={expanded}
      onClick={toggleCollapsable}
      css={collapsableCss(expanded)}
    >
      <div css={tableContainer}>
        <TableContainer card={card}>
          <TableHeader>
            <TableHeaderRow>
              <TableHeaderCell>Data e hora</TableHeaderCell>
              <TableHeaderCell>Valor</TableHeaderCell>
              <TableHeaderCell>Forma de Pagamento</TableHeaderCell>
              <TableHeaderCell>Aut/Doc</TableHeaderCell>
              <TableHeaderCell>Registrado por</TableHeaderCell>
              <TableHeaderCell>Status</TableHeaderCell>
              <TableHeaderCell fixed>Ação</TableHeaderCell>
            </TableHeaderRow>
          </TableHeader>
          <TableBody loading={loading}>
            {payments.map(payment => (
              <PaymentRow
                key={payment.id}
                card={card}
                payment={payment}
              />
            ))}
          </TableBody>
        </TableContainer>
      </div>
      {subordinatesLoading
        ? <LoadingAnimation css={loadingContainer} />
        : (
          <>
            {isCommercialPartner
              ? (
                <>
                  <AddLabel onClick={onPaymentCreation} css={[noBottom]}>
                    Registrar pagamento
                  </AddLabel> <br />
                </>
              )
              : (
                <AddLabel onClick={onPaymentCreation} css={[noBottom, !amount?.due && hidden]}>
                  Registrar pagamento
                </AddLabel>
              )}
            {pinpadEnabled && (
              <AddLabel onClick={onPinpadPaymentCreation} css={[noBottom, greenBtn]}>
              Registrar pagamento com pinpad
              </AddLabel>
            )}
          </>
        )}
    </CollapsableSection>
  )
}

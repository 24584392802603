import React, { useEffect, useState, useCallback, createContext, useMemo, useContext } from 'react'
import { TransactionService } from 'core/services/Transaction'
import { RefundService } from 'core/services/Refund'
import { PaymentService } from 'core/services/Payment'
import { ReservationService } from 'core/services/Reservation'
import { useReservation } from 'hooks/context/Reservation'
import { useToast } from '@bonitour/components'
import { GATEWAYS_MAP } from 'constants/acquirers'
import { ReservationTotalizersService } from 'services/Reservations/Service'

const TransactionsContext = createContext({})

export const useTransactions = () => {
  const context = useContext(TransactionsContext)
  if (!context) {
    throw new Error('useTransactions must be used within a TransactionsProvider')
  }
  return context
}

export const TransactionsProvider = ({ isCommercialPartner = false, isReservationSummaryInstance = false, children }) => {
  const { reservationId } = useReservation()

  const { add: addToast } = useToast()

  const [transactions, setTransactions] = useState([])
  const [paymentLinks, setPaymentLinks] = useState([])
  const [payments, setPayments] = useState([])
  const [refunds, setRefunds] = useState([])
  const [loadingLinks, setLoadingLinks] = useState(false)
  const [loadingPayments, setLoadingPayments] = useState(false)
  const [loadingRefunds, setLoadingRefunds] = useState(false)
  const [amount, setAmount] = useState([])
  const [loading, setLoading] = useState(false)
  const [isLinkCreateLoading, setIsLinkCreateLoading] = useState(false)
  const [getPaymentLinkLoading, setGetPaymentLinkLoading] = useState(false)
  const [pinpadData, setPinpadData] = useState(null)

  const updatePaymentLinks = useCallback(() => {
    if (reservationId) {
      setLoadingLinks(true)
      return TransactionService.paymentLinks(reservationId)
        .then(setPaymentLinks)
        .finally(() => setLoadingLinks(false))
    }
  }, [reservationId])

  const updatePayments = useCallback(() => {
    if (reservationId) {
      setLoadingPayments(true)
      return TransactionService.payments(reservationId)
        .then(setPayments)
        .finally(() => setLoadingPayments(false))
    }
  }, [reservationId])

  const updateRefunds = useCallback(() => {
    if (reservationId) {
      setLoadingRefunds(true)
      return TransactionService.refunds(reservationId)
        .then(setRefunds)
        .finally(() => setLoadingRefunds(false))
    }
  }, [reservationId])

  const updateTransactions = useCallback(() => {
    if (reservationId) {
      updatePaymentLinks()
      updatePayments()
      updateRefunds()
      setLoading(true)

      if (isReservationSummaryInstance) {
        return TransactionService.list(reservationId)
          .then(({ transactions = [], amount = {} }) => {
            setTransactions(transactions)
            setAmount(amount)
          })
          .finally(() => setLoading(false))
      }

      return ReservationTotalizersService.getFinancialTransactionsTotalizers(reservationId).then(({
        totalAmount,
        totalAmountDue,
        totalAmountPaid,
        totalCancellationBalance
      }) => {
        setTransactions([])
        setAmount({
          total: totalAmount,
          paid: totalAmountPaid,
          due: totalAmountDue,
          credit: totalCancellationBalance
        })
      })
        .finally(() => setLoading(false))
    }
  }, [isReservationSummaryInstance, reservationId, updatePaymentLinks, updatePayments, updateRefunds])

  const createRefund = useCallback((refund) => {
    if (isCommercialPartner && reservationId) {
      return RefundService.createRefund(refund, reservationId).then(updateTransactions)
    }
  }, [reservationId, updateTransactions, isCommercialPartner])

  const createRefundPay = useCallback((refund) => {
    const { id: paymentId } = refund

    if (isCommercialPartner && reservationId) {
      return RefundService.createRefundPay(refund, reservationId, paymentId).then(updateTransactions)
    }
  }, [reservationId, updateTransactions, isCommercialPartner])

  const changeRefund = useCallback((refund) => {
    if (isCommercialPartner && reservationId) {
      return RefundService.editRefund(refund, reservationId).then(updateTransactions)
    }
  }, [reservationId, updateTransactions, isCommercialPartner])

  const getRefundById = useCallback((refundId) => {
    if (isCommercialPartner && reservationId) {
      return RefundService.getById(reservationId, refundId)
    }
  }, [reservationId, isCommercialPartner])

  const getRefundPaymentLinkById = useCallback((refundId) => {
    if (isCommercialPartner && reservationId) {
      return RefundService.getRefundLinkById(reservationId, refundId)
    }
  }, [reservationId, isCommercialPartner])

  const removeRefund = useCallback((refundId, param) => {
    if (isCommercialPartner && reservationId) {
      return RefundService.remove(reservationId, refundId, param).then(updateTransactions)
    }
  }, [updateTransactions, reservationId, isCommercialPartner])

  const createPayment = useCallback((payment) => {
    if (reservationId) {
      return PaymentService.createPayment(payment, reservationId).then(updateTransactions)
    }
  }, [reservationId, updateTransactions])

  const requestPinpad = useCallback((payment) => {
    if (!reservationId) return

    const ticketParser = (ticket) => ({
      ticketId: ticket.id,
      companyId: ticket.companyId,
      serviceId: ticket.serviceId,
      amount: ticket.amount,
      price: ticket.value
    })

    const data = {
      reservationId,
      value: payment.value,
      paymentMethod: payment.method,
      installments: Number(payment.installments),
      tickets: payment.ticketsIds.map(ticketParser)
    }

    setPinpadData(data)
  }, [reservationId])

  const cancelPinpadRequest = useCallback(() => {
    setPinpadData(null)
  }, [])

  const createLinkPayment = useCallback((payment) => {
    if (isCommercialPartner && reservationId) {
      setIsLinkCreateLoading(true)
      return PaymentService.createLinkPayment(payment, reservationId).then(updateTransactions).finally(() => setIsLinkCreateLoading(false))
    }
  }, [reservationId, updateTransactions, isCommercialPartner])

  const createLinkPaymentMultiponto = useCallback((payment) => {
    if (isCommercialPartner && reservationId) {
      setIsLinkCreateLoading(true)
      return PaymentService.createLinkPaymentMultiponto(payment, reservationId).then(updateTransactions).finally(() => setIsLinkCreateLoading(false))
    }
  }, [reservationId, updateTransactions, isCommercialPartner])

  const createLinkPaymentPix = useCallback((payment) => {
    if (isCommercialPartner && reservationId) {
      setIsLinkCreateLoading(true)
      return PaymentService.createLinkNonSplitted({
        ...payment,
        gateway: GATEWAYS_MAP.pagar_me
      }, reservationId).then(updateTransactions).finally(() => setIsLinkCreateLoading(false))
    }
  }, [reservationId, updateTransactions, isCommercialPartner])

  const expirePaymentLink = useCallback((paymentId) => {
    if (isCommercialPartner && reservationId) {
      return PaymentService.expirePaymentLink(reservationId, paymentId)
        .then(updateTransactions)
        .catch(() => {
          addToast('Erro inesperado')
        })
        .finally(() => {
          addToast('Link Expirado', 'success')
        })
    }
  }, [addToast, isCommercialPartner, reservationId, updateTransactions])

  const changePayment = useCallback((payment) => {
    if (reservationId) {
      return PaymentService.editPayment(payment, reservationId).then(updateTransactions)
    }
  }, [reservationId, updateTransactions])

  const getPaymentById = useCallback((paymentId) => {
    if (reservationId) {
      return PaymentService.getById(reservationId, paymentId)
    }
  }, [reservationId])

  const getPaymentLinkById = useCallback((paymentId) => {
    if (isCommercialPartner && reservationId) {
      setGetPaymentLinkLoading(true)
      return PaymentService.getPaymentLinkById(reservationId, paymentId).finally(() => setGetPaymentLinkLoading(false))
    }
  }, [reservationId, isCommercialPartner])

  const removePayment = useCallback((paymentId, param) => {
    if (reservationId) {
      return PaymentService.remove(reservationId, paymentId, param).then(updateTransactions)
    }
  }, [updateTransactions, reservationId])

  const getTicketsToRefund = useCallback((paymentId) => {
    if (isCommercialPartner && reservationId) {
      return ReservationService.getTicketsToRefund(reservationId, paymentId)
    }
  }, [reservationId, isCommercialPartner])

  useEffect(() => {
    updateTransactions()
  }, [updateTransactions, isCommercialPartner])

  const contextData = useMemo(() => ({
    transactions,
    amount,
    paymentLinks,
    payments,
    refunds,
    updateTransactions,
    updatePaymentLinks,
    updatePayments,
    createRefund,
    createRefundPay,
    changeRefund,
    removeRefund,
    getRefundById,
    getRefundPaymentLinkById,
    createPayment,
    createLinkPayment,
    createLinkPaymentMultiponto,
    createLinkPaymentPix,
    expirePaymentLink,
    changePayment,
    getPaymentById,
    getPaymentLinkById,
    removePayment,
    getTicketsToRefund,
    pinpadData,
    requestPinpad,
    cancelPinpadRequest,
    loading,
    loadingLinks,
    loadingPayments,
    loadingRefunds,
    isLinkCreateLoading,
    getPaymentLinkLoading
  }), [
    transactions,
    amount,
    paymentLinks,
    payments,
    refunds,
    updateTransactions,
    updatePaymentLinks,
    updatePayments,
    createRefund,
    createRefundPay,
    changeRefund,
    removeRefund,
    getRefundById,
    getRefundPaymentLinkById,
    createPayment,
    createLinkPayment,
    createLinkPaymentMultiponto,
    createLinkPaymentPix,
    expirePaymentLink,
    changePayment,
    getPaymentById,
    getPaymentLinkById,
    removePayment,
    getTicketsToRefund,
    loading,
    loadingLinks,
    loadingPayments,
    loadingRefunds,
    isLinkCreateLoading,
    getPaymentLinkLoading,
    pinpadData,
    requestPinpad,
    cancelPinpadRequest
  ])

  return (
    <TransactionsContext.Provider value={contextData}>
      {children}
    </TransactionsContext.Provider>
  )
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { Accordion, CollapsableSection } from '@bonitour/components'
import { Global, css, jsx } from '@emotion/core'
import { ActivityAbout } from 'app/EditActivity/ActivityAbout'
import { ActivityComplementaryInfo } from 'app/EditActivity/ActivityComplementaryInfo'
import { ActivityBaseForm } from 'containers/ActivityBaseForm/ActivityBaseForm'
import { useActivityForm } from 'containers/ActivityBaseForm/useActivityForm'
import { useQuery } from 'hooks/useQuery'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ActivityCategoryService } from 'services/Activity/ActivityCategory/Service'
import { htmlLinksToMarkdown, stripHtmlTags } from 'utils/string'

const { REACT_APP_ACCOUNTS_URL } = process.env

const bodyStyle = css`
  body {
    background-color: transparent;
  }
`

export const RegisterActivityWithFullForm = () => {
  const activityBaseInitial = useMemo(() => ({
    phones: [{ name: '', number: '' }],
    emails: [{ name: '', email: '' }],
    sellingDeadline: 0
  }), [])
  const activityAboutInitial = useMemo(() => ({
    activityAbout: {
      duration: '',
      workDuration: '',
      difficultyLevel: 'uninformed',
      category: '',
      infrastructure: [],
      generalRules: ''
    },
    activityRules: {
      restrictions: {
        minAge: '0',
        maxWeight: '0.0',
        minHeight: '0'
      }
    }
  }), [])
  const activityComplementaryInfoInitial = useMemo(() => ({
    description: '',
    observation: '',
    isAllyVisible: true,
    sellingDeadline: 0,
    paymentsTypes: [],
    whatToTake: [],
    whatIsIncluded: []
  }), [])

  const addToast = useCallback((...toast) => {
    window.parent.postMessage({
      activityForm: {
        event: {
          type: 'toast',
          value: toast
        }
      }
    }, REACT_APP_ACCOUNTS_URL)
  }, [])

  const { push } = useHistory()
  const [{ accordion = 1 }] = useQuery()
  const [maxAccordion, setMaxAccordion] = useState(1)
  const setIndicatorActive = useCallback((allowNewStep = false) => (accordionValue) => {
    if (accordionValue > maxAccordion) {
      if (!allowNewStep) {
        addToast('Preencha as informações anteriores')
        return
      }
      setMaxAccordion(accordionValue)
    }
    push({ search: `?accordion=${accordionValue}` })
  }, [maxAccordion, push, addToast])
  const onBackStep = useCallback(() => setIndicatorActive(true)(Number(accordion) - 1), [accordion, setIndicatorActive])
  const onNextStep = useCallback(() => setIndicatorActive(true)((Number(accordion) + 1) % 4), [accordion, setIndicatorActive])

  const onActivityBaseSuccess = useCallback((baseForm) => {
    window.parent.postMessage({
      activityForm: {
        change: { baseForm }
      }
    }, REACT_APP_ACCOUNTS_URL)
    setIndicatorActive(true)(2)
  }, [setIndicatorActive])
  const activityFormProps = useActivityForm({
    activityBase: activityBaseInitial,
    onSuccess: onActivityBaseSuccess,
    addToast
  })

  const [activityAbout, setActivityAbout] = useState(activityAboutInitial)
  const onActivityAboutSuccess = useCallback((aboutForm) => {
    setActivityAbout(aboutForm)
    window.parent.postMessage({
      activityForm: {
        change: { aboutForm }
      }
    }, REACT_APP_ACCOUNTS_URL)
    setIndicatorActive(true)(3)
  }, [setIndicatorActive])

  const [activityComplementaryInfo, setActivityComplementaryInfo] = useState(activityComplementaryInfoInitial)
  const onActivityComplementaryInfoSuccess = useCallback((complementaryInfoForm) => {
    setActivityComplementaryInfo(complementaryInfoForm)
    window.parent.postMessage({
      activityForm: {
        change: { complementaryInfoForm }
      }
    }, REACT_APP_ACCOUNTS_URL)
  }, [])

  const containerRef = useRef()
  useEffect(() => {
    if (window.self === window.top || !document.referrer.startsWith(REACT_APP_ACCOUNTS_URL)) {
      window.location.href = `${REACT_APP_ACCOUNTS_URL}/app/integrations`
      return
    }
    if (!containerRef.current) return
    window.parent.postMessage({
      activityForm: {
        event: {
          type: 'loaded'
        }
      }
    }, REACT_APP_ACCOUNTS_URL)
    const { current: container } = containerRef
    const resizeObserver = new ResizeObserver(() => {
      window.parent.postMessage({
        activityForm: {
          event: {
            type: 'update-height',
            value: container.scrollHeight
          }
        }
      }, REACT_APP_ACCOUNTS_URL)
    })

    resizeObserver.observe(container)

    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  const [hasActivity, setHasActivity] = useState(false)
  const handleActivityMessage = useCallback(async (event) => {
    if (event?.origin !== REACT_APP_ACCOUNTS_URL || !event?.data?.selectedActivity) {
      return
    }
    if (event.data.selectedActivity) {
      setHasActivity(true)
      activityFormProps.onInputChange('activityId.name')(event.data.selectedActivity.title || '')
      setActivityComplementaryInfo(info => ({
        ...info,
        description: stripHtmlTags(htmlLinksToMarkdown(event.data.selectedActivity?.description || ''))
      }))
      try {
        const limberData = JSON.parse(event.data.selectedActivity.limberData)
        const imageUrl = limberData?.imagens?.[0]?.urlImg
        if (imageUrl) {
          const response = await fetch(imageUrl)
          const blob = await response.blob()
          const file = new File([blob], imageUrl.match(/[^/]+$/)[0], { type: blob.type })
          const id = `${+new Date()}-${Math.random() * 1000}`
          const src = file.type && file.type.includes('image') && URL.createObjectURL(file)
          activityFormProps.onInputChange('activityId.files')([{ id, file, src, name: file.name }])
        }
      } catch (error) {
        console.error('Error parsing limberData', error)
      }
    }
  }, [activityFormProps])
  useEffect(() => {
    window.addEventListener('message', handleActivityMessage)
    return () => {
      window.removeEventListener('message', handleActivityMessage)
    }
  }, [handleActivityMessage])

  const [categories, setCategories] = useState([])

  useEffect(() => {
    ActivityCategoryService.list().then(setCategories)
  }, [])

  return (
    <div ref={containerRef}>
      <Global styles={bodyStyle} />
      {hasActivity
        ? (
          <Accordion value={Number(accordion)} onClick={setIndicatorActive(false)}>
            <CollapsableSection
              indicator={1}
              title='Informações básicas'
              topics='Registro / Contato / Endereço'
            >
              <ActivityBaseForm
                {...activityFormProps}
                isImporting
              />
            </CollapsableSection>
            <CollapsableSection
              indicator={2}
              title='Informações técnicas'
              topics='Tempo de duração / Tempo de atividade / Nível de dificuldade'
            >
              <ActivityAbout
                categories={categories}
                activityAbout={activityAbout}
                onActivityUpdated={onActivityAboutSuccess}
                onBackStep={onBackStep}
                onNextStep={onNextStep}
                isImporting
                addToast={addToast}
              />
            </CollapsableSection>

            <CollapsableSection
              indicator={3}
              title='Informações Complementares'
              topics='Descrição / Observações / O que levar / Incluso na atividade / Pagamentos aceitos'
              tooltip='Essas informações serão usadas para informar os viajantes sobre a atividade em plataformas de vendas'
            >
              <ActivityComplementaryInfo
                activityComplementaryInfo={activityComplementaryInfo}
                onActivityUpdated={onActivityComplementaryInfoSuccess}
                onBackStep={onBackStep}
                onNextStep={onNextStep}
                isImporting
                addToast={addToast}
              />
            </CollapsableSection>
          </Accordion>
        )
        : null
      }
    </div>
  )
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { CheckIcon, RefreshIcon, CustomAlert, InfoSwatch } from '@bonitour/components'
import { hidden } from 'assets/styles/global'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDebounce } from '@bonitour/app-functions'
import { customInfoSwatch, iconAnimation, label, resetAccordionPadding } from './BookingMapOperationProgress.style'
import { useIsLockedService } from 'hooks/domains/useIsLockedService'
import { useActivity } from 'contexts/Activity'
import { TRANSPORT_TYPE } from 'constants/activityTypes'

export const BookingMapOperationProgress = ({
  customCss = []
}) => {
  const {
    lockedData,
    isLoading
  } = useIsLockedService()

  const {
    activity: {
      type
    }
  } = useActivity()

  const [alertData, setAlertData] = useState({
    canHideAlert: false,
    isAlreadyOperationInProgress: false,
    isFirstLoading: true,
    isOperationCompleted: false
  })

  const isTransport = useMemo(() => type === TRANSPORT_TYPE, [type])

  const {
    hasOperationInProgress,
    isCombinedExperience = false,
    servicesWithOperationInProgress = []
  } = useMemo(() => {
    if (Array.isArray(lockedData?.experiences)) {
      return {
        hasOperationInProgress: lockedData.experiences.some(({ isLocked }) => isLocked),
        isCombinedExperience: true,
        servicesWithOperationInProgress: lockedData.experiences
      }
    }
    return {
      hasOperationInProgress: lockedData.isLocked,
      isCombinedExperience: false,
      servicesWithOperationInProgress: []
    }
  }, [lockedData])

  const {
    canHideAlert,
    isAlreadyOperationInProgress,
    isFirstLoading,
    isOperationCompleted
  } = useMemo(() => alertData, [alertData])

  const setCanHideAlert = useCallback((value) => setAlertData((prev) => ({ ...prev, canHideAlert: value })), [])

  const setIsAlreadyOperationInProgress = useCallback((value) => setAlertData((prev) => ({ ...prev, isAlreadyOperationInProgress: value })), [setAlertData])

  const setIsFirstLoading = useCallback((value) => setAlertData((prev) => ({ ...prev, isFirstLoading: value })), [])

  const setIsOperationCompleted = useCallback((value) => setAlertData((prev) => ({ ...prev, isOperationCompleted: value })), [])

  const timeout = useRef(null)

  const isInProgress = useDebounce(hasOperationInProgress, 1200)

  const canUnmount = useMemo(() => (
    !isInProgress &&
    !isAlreadyOperationInProgress &&
    (isOperationCompleted || isFirstLoading)) ||
    (!isInProgress &&
    !isAlreadyOperationInProgress), [
    isAlreadyOperationInProgress, isFirstLoading,
    isInProgress, isOperationCompleted
  ])

  const canShowSuccessAlert = useMemo(() => isFirstLoading ? false : !isInProgress, [isFirstLoading, isInProgress])

  useEffect(() => {
    if (isLoading || isFirstLoading) return
    if (canShowSuccessAlert && !timeout.current) {
      timeout.current = setTimeout(() => {
        setCanHideAlert(true)
        clearTimeout(timeout.current)
        timeout.current = null
        setIsOperationCompleted(true)
        setIsAlreadyOperationInProgress(true)
      }, 5000)
    }
    if (!canShowSuccessAlert) {
      setIsAlreadyOperationInProgress(true)
      setCanHideAlert(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canShowSuccessAlert])

  useEffect(() => {
    if (isLoading && isFirstLoading) {
      setIsFirstLoading(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirstLoading, isLoading])

  const customIcon = useMemo(() =>
    canShowSuccessAlert
      ? <CheckIcon/>
      : <RefreshIcon css={iconAnimation}/>
  , [canShowSuccessAlert])

  const activityLabel = useMemo(() => {
    if (isTransport) {
      return 'dessa rota de transporte'
    }
    if (isCombinedExperience) {
      return 'de alguma(s) atividade(s) dessa experiência combinada'
    }
    return 'dessa atividade'
  }, [isCombinedExperience, isTransport])

  if (canUnmount) {
    return null
  }

  return <CustomAlert
    icon={customIcon}
    customCss={[resetAccordionPadding, canHideAlert && hidden, ...customCss]}
    isSuccess={canShowSuccessAlert}
  >
    <p css={label}>
      {isCombinedExperience
        ? <InfoSwatch tooltip={(
          <>
            <p className='title'>Lista de atividades:</p>
            {servicesWithOperationInProgress.map(({ activityTitle }, index) => (
              <p className='sub-item' key={`${activityTitle}+#${index}`}>
                &#8226; {activityTitle}
              </p>
            ))}
          </>
        )} customTooltip={[customInfoSwatch]}/>
        : null}
      {canShowSuccessAlert
        ? 'O mapa de vagas foi recalculado com sucesso.'
        : `O mapa de vagas ${activityLabel} está sendo recalculado.`}
    </p>
  </CustomAlert>
}

import { handleOfflineTicketFormSchema } from 'containers/OfflineExperiencesTicketForm/OfflineExperience.schema'
import { object, string, array, number, lazy } from 'yup'
import { reservationTypeSchema } from '../Form/ReservationType/ReservationType.rules'
import { COMBINED_EXPERIENCE_TYPE } from 'constants/activityTypes'

export const bookingTicketsSchema = (isCombinedExperience) => isCombinedExperience
  ? lazy((values) => {
    const stringFields = ['feeId', 'feeTypeId', 'date', 'experienceId', 'companyId', 'serviceId', 'time']
    const numberFields = ['price', 'quantity']

    return object(Object.keys(values).reduce((acc, key) => {
      if (stringFields.includes(key)) {
        acc[key] = string()
      }
      if (numberFields.includes(key)) {
        acc[key] = number()
      }
      return acc
    }, {}))
  })
  : array()
    .of(
      object({
        pickupPlaceId: string(),
        quantity: number()
          .typeError('Deve ser um número')
          .min(0)
      })
    )
    .min(1)

export const bookingSchema = (states = [], cities = [], mustReservationTypeValidation = true, offlineExperienceCategory = '', type) => {
  const isCombinedExperience = type === COMBINED_EXPERIENCE_TYPE

  const reservationType = mustReservationTypeValidation && object(reservationTypeSchema(states, cities))

  const offlineExperienceSchema = {
    ...handleOfflineTicketFormSchema({
      category: offlineExperienceCategory
    }),
    reservationType
  }

  const experienceSchema = {
    reservationHeader: object({
      partner: string().required(),
      agent: string().optional()
    }).required(),
    tickets: bookingTicketsSchema(isCombinedExperience),
    reservationType,
    comments: string(),
    affiliateCode: string().nullable(),
    pickupPlaceId: string().nullable()
  }

  return offlineExperienceCategory ? offlineExperienceSchema : experienceSchema
}

/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { useForm } from '@bonitour/app-functions'
import { AddTicketIcon, colors, flexHorizontallyCentered, Input, LinkIcon } from '@bonitour/components'
import { jsx, css } from '@emotion/core'
import { useQuery } from 'hooks/useQuery'
import { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { string } from 'yup'

const popoverItem = css`
color: ${colors.gray4};
padding: 15px 20px;
font-size: 16px;
border-radius: 10px;
text-decoration: none;
width: 100%;
box-sizing: border-box;
text-align: left;
cursor: pointer;

&:hover {
  background-color: ${colors.gray13};
}
`

const smallInput = css`
width: 150px;
margin-right: 10px;
`

const iconStyle = css`
margin-right: 10px;
font-size: 23px;
`

const schema = {
  id: string().required()
}

export const BookingCreationAndLinkageGuide = ({ hour = '', date = '', pickupPlaceId = '' }) => {
  const [data] = useState({})
  const { push } = useHistory()
  const { form, errors, onSubmit, utils: { onInputChange, onInputBlur } } = useForm(data, schema)
  const [{ email }] = useQuery()

  const reservationsRequest = ({ id: code }) => push(`bookings/link/${code}?hour=${hour}&date=${date}${pickupPlaceId !== '' ? `&pickupPlaceId=${pickupPlaceId}` : ''}`)
  const onClick = onSubmit(reservationsRequest)

  return (
    <>
      <Link to={`bookings/create?hour=${hour}&date=${date}${email ? `&email=${email}` : ''}${pickupPlaceId !== '' ? `&pickupPlaceId=${pickupPlaceId}` : ''}`}
        css={[flexHorizontallyCentered, popoverItem]}>
        <AddTicketIcon css={iconStyle} />
        Nova reserva
      </Link>
      <div css={[flexHorizontallyCentered, popoverItem]}>
        <LinkIcon css={iconStyle} />
        <Input
          value={form.id}
          css={smallInput}
          error={errors.id}
          placeholder='Código Reserva'
          onChange={onInputChange('id')}
          onBlur={onInputBlur('id')}
        />
        <span onClick={onClick}>Vincular</span>
      </div>
    </>
  )
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { formatMoney, identity } from '@bonitour/common-functions'
import { Button, CircleThumbnail, DarkEmptyResult, TableBody, TableBodyCell, TableBodyRow, TableContainer, TableHeader, TableHeaderCell, TableHeaderRow, TooltipOnHover } from '@bonitour/components'
import { formatISOTime } from 'utils/time'
import { ticketStatusDictionary } from 'constants/ticketStatus'
import { capitalize, circleColor } from 'assets/styles/global'
import { useCompany } from 'contexts/Company'
import { isDateInFuture } from 'utils/date'

const container = css`
  overflow-x: auto;
`

const validateButton = css`
  padding: 10px 30px;
`

const width120 = css`
  width: 120px;
`

const shortenFields = css`
  overflow: hidden;
  text-overflow: ellipsis;
`

const bigFields = css`
  ${shortenFields}
  max-width: 80px;
`

const ticketField = css`
  ${shortenFields}
  max-width: 150px;
  font-size: 12px;
`

const resetPosition = css`
  align-items:unset;
  justify-content:unset;
`

export const TicketCheckinListRow = ({ ticket = {}, onValidate = identity }) => {
  const { id: companyId } = useCompany()
  const {
    id,
    code,
    client,
    day,
    hour,
    companyId: ticketCompanyId,
    company,
    activity,
    type,
    price,
    serviceType
  } = ticket
  const {
    label,
    color,
    isValidatable = false,
    isValidatableByServiceType: validatableTypes
  } = ticketStatusDictionary[ticket.state] || {}
  const isOwner = ticketCompanyId => ticketCompanyId === companyId
  const canValidateTicket = (companyId, date) =>
    (isValidatable || (Array.isArray(validatableTypes) && validatableTypes.includes(serviceType))) &&
    isOwner(companyId) &&
    !isDateInFuture(date)

  return (
    <TableBodyRow key={id}>
      <TruncateCell truncateStyle={ticketField} text={code} />
      <TruncateCell truncateStyle={bigFields} text={client} />
      <TableBodyCell data-label='Data e hora'>
        <span>{`${formatISOTime(day, 'DD/MM/YYYY', false)}`}</span>
        <br />
        <span>{`${hour}`}</span>
      </TableBodyCell>
      <TruncateCell truncateStyle={bigFields} text={type} />
      <TruncateCell truncateStyle={bigFields} text={company} />
      <TruncateCell truncateStyle={bigFields} text={activity} />
      <TruncateCell truncateStyle={bigFields} text={formatMoney(price)} />
      <TableBodyCell css={capitalize}>
        <CircleThumbnail size={10} css={circleColor(color)} />
        <span>{label}</span>
      </TableBodyCell>
      <TableBodyCell css={width120} fixed>
        {canValidateTicket(ticketCompanyId, day) && <Button css={validateButton} onClick={() => onValidate(ticket)}>Utilizar</Button>}
      </TableBodyCell>
    </TableBodyRow>)
}

export const TicketCheckinList = ({ ticketList = [], onValidate = identity, loading }) => {
  return (
    <>
      {!ticketList.length
        ? (
          <DarkEmptyResult iconSize={100} title='Não há ingresso selecionado' subtitle='Digite o código no campo de pesquisa acima.' />
        )
        : (
          <div css={container}>
            <TableContainer>
              <TableHeader>
                <TableHeaderRow>
                  <TableHeaderCell>Cod. do Ingresso</TableHeaderCell>
                  <TableHeaderCell>Cliente</TableHeaderCell>
                  <TableHeaderCell>Data e hora</TableHeaderCell>
                  <TableHeaderCell>Tipo</TableHeaderCell>
                  <TableHeaderCell>Empresa</TableHeaderCell>
                  <TableHeaderCell>Experiência</TableHeaderCell>
                  <TableHeaderCell>Valor</TableHeaderCell>
                  <TableHeaderCell>Status</TableHeaderCell>
                  <TableHeaderCell fixed>Ação</TableHeaderCell>
                </TableHeaderRow>
              </TableHeader>
              <TableBody loading={loading}>
                {ticketList?.map(ticket => <TicketCheckinListRow key={ticket.id} ticket={ticket} onValidate={onValidate} />)}
              </TableBody>
            </TableContainer>
          </div>
        )}
    </>
  )
}

const TruncateCell = ({ text, truncateStyle, children }) =>
  <TableBodyCell>
    <TooltipOnHover css={resetPosition} text={text}>
      <div css={truncateStyle}>{children || text}</div>
    </TooltipOnHover>
  </TableBodyCell>

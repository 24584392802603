import styled from '@emotion/styled'

import { EXPERIENCE_VACANCY_BOX_SHADOW } from 'constants/bookingMap'
import { hexToRgba } from 'utils/hexToRgba'

export const CombinedExperienceGuideIndicator = styled.div`
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 0.25rem;
  ${({ color, opacity }) => (color && opacity)
    ? `
    background-color: ${hexToRgba(color, opacity)};
  `
    : ''}
  ${({ canPaintBorder, color, opacity }) => canPaintBorder
    ? `
    background-color: transparent;
    box-shadow: ${EXPERIENCE_VACANCY_BOX_SHADOW} ${hexToRgba(color, opacity)};
  `
    : ''}
`

import { useState, useEffect } from 'react'

export function useOffsetHook (isVisible = true, max = 7) {
  const [tableContainer, setTableContainer] = useState(null)
  const [offset, setOffset] = useState(0)

  useEffect(() => {
    const setDateQuantity = () => {
      const tableWidth = tableContainer && tableContainer.clientWidth
      setOffset(Math.min(Math.floor((tableWidth - 62 - 125) / 136), max))
    }

    if (isVisible && tableContainer) {
      window.addEventListener('resize', setDateQuantity)
      tableContainer.addEventListener('resize', setDateQuantity)
      setDateQuantity()
      return () => {
        tableContainer.removeEventListener('resize', setDateQuantity)
        window.removeEventListener('resize', setDateQuantity)
      }
    }
  }, [tableContainer, isVisible, max])

  return [setTableContainer, offset]
}

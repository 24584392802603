import { colors } from '@bonitour/components'
import { css } from '@emotion/core'

export const thumbnail = css`
  color: ${colors.gray2};
  border: 1px solid ${colors.gray2};
  border-radius: var(--round-element-radius, 100%);
  transform: rotateX(180deg);

  &:hover {
    color: ${colors.white};
    border-color: ${colors.primary};
    background-color: ${colors.primary};
  }
`

export const truncateCell = css`
  display: inline-flex;
  gap: 0.25rem;
  align-items: center;
  line-height: 1.1rem;
  span {
    display: inline-block;
    width: 8rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const truncateColumnCell = css`
  white-space: pre-line;
  width: 4rem;
  line-height: 1.05rem;
`

export const tableContainer = css`
  max-height: calc(100vh - 20rem);
  overflow-y: auto;
`

export const emphasisLabel = css`
  color: ${colors.orange1};
  font-size: 1.1rem;
  display: inline-flex;
  gap: 0.5rem;
`

export const customTooltip = css`
  background-color: ${colors.white};
  color: ${colors.gray4};
  margin-bottom: 0.5rem;

  [class*="TooltipArrow"]::before {
    border-color: transparent ${colors.white} transparent transparent;
  }
`

export const totalizarContainer = css`
  display: inline-flex;
  place-items: center;
`

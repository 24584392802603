const experienceService = ({
  id: service_id,
  companyId: company_id,
  isOptional: optional = false,
  discountPercentage = 0
}) => {
  const parsedPercetange = (discountPercentage / 100).toFixed(4)

  return {
    service_id,
    company_id,
    optional,
    discount_percentage: parsedPercetange
  }
}

const createUpdateModel = ({
  isSameDayServices,
  isEnabled,
  title,
  subtitle,
  description,
  experiences
}) => ({
  title,
  subtitle,
  description,
  enabled: isEnabled,
  services_has_to_be_on_same_day: isSameDayServices,
  services: experiences.map(experienceService)
})

const parsePagination = ({
  page,
  perPage
}) => ({
  page,
  per_page: perPage
})

const parseListData = ({
  title,
  isEnabled,
  page,
  perPage
}) => ({
  title,
  enabled: isEnabled,
  ...parsePagination({
    page,
    perPage
  })
})

export const CombinedExperienceModel = {
  create: createUpdateModel,
  update: createUpdateModel,
  list: parseListData
}

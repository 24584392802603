import { useMemo, useCallback, useEffect, useState, useRef } from 'react'
import { useSellableList } from '../../../contexts/Activity'
import { useToast } from '@bonitour/components'
import { useCompany } from '../../../contexts/Company'
import { OfflineExperienceService } from 'services/OfflineExperience/Service'
import { useCachedPromise } from 'hooks/useCachedPromise'
import { CombinedExperienceService } from 'services/combinedExperience/Service'
import { ACTIVITY_TYPE, COMBINED_EXPERIENCE_TYPE, LIMBER_EXPERIENCE_TYPE, OFFLINE_EXPERIENCE_TYPE, TRANSPORT_TYPE } from 'constants/activityTypes'

// Faro shuffle: a method of interleaving playing cards - see https://en.wikipedia.org/wiki/Faro_shuffle
// example: faroShuffle([1, 2, 3], ['a', 'b', 'c']) => [1, 'a', 2, 'b', 3, 'c']
const faroShuffle = (...arrays) => {
  const maxLength = Math.max(...arrays.map(({ length }) => length))
  return Array.from(
    { length: maxLength },
    (_, index) => arrays
      .map(array => array[index])
      .filter(Boolean)
  ).flat()
}

const EXPERIENCES_PER_PAGE = 50
const SINGLE_PAGE_EXPERIENCES = 8

export const useListExperiences = ({
  disabledTypes = [],
  onlyFirstPage = false,
  fromCurrentCompany = undefined // true, false or undefined
} = {}) => {
  const [searchQuery, setSearchQuery] = useState('')
  const [typesFilter, setTypesFilter] = useState([])

  const [experiencesList, setExperiencesList] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [isOnLastPage, setIsOnLastPage] = useState(false)
  const disabledTypesRef = useRef(disabledTypes)
  const fromCurrentCompanyRef = useRef(fromCurrentCompany)
  const isLoadingRef = useRef(false)

  const totalPages = useRef({})

  useEffect(
    () => {
      const updatedIsOnLastPage = Object
        .entries(totalPages.current)
        .filter(([key, _value]) => (!typesFilter.length || typesFilter.includes(key)) && !disabledTypes.includes(key))
        .every(([_, value]) => currentPage >= value)
      if (isOnLastPage !== updatedIsOnLastPage) {
        setIsOnLastPage(updatedIsOnLastPage)
      }
    },
    [currentPage, totalPages, typesFilter, experiencesList, disabledTypes, isOnLastPage]
  )

  const clearList = useCallback(() => {
    setCurrentPage(1)
    setExperiencesList([])
    totalPages.current = {}
  }, [])

  useEffect(() => {
    if (disabledTypesRef.current !== disabledTypes) {
      disabledTypesRef.current = disabledTypes
    }
    if (fromCurrentCompanyRef.current !== fromCurrentCompany) {
      fromCurrentCompanyRef.current = fromCurrentCompany
      clearList()
    }
  }, [disabledTypes, fromCurrentCompany, clearList])

  useEffect(() => {
    if (currentPage < 1) {
      setCurrentPage(1)
    }
  }, [currentPage])

  const setQuery = useCallback(({ search }) => {
    clearList()
    setSearchQuery(search)
  }, [clearList])

  const setFilter = useCallback(({ types }) => {
    setTypesFilter(types)
  }, [])

  const { add: addToast } = useToast()

  const { id: companyId, company: { name: companyName } } = useCompany()
  const { invalidateCache: invalidateSellableCache, fetchSellableList } = useSellableList()

  const localFilterAndPagination = useCallback(({ title, page, perPage, fromCurrentCompany }) => serviceList => {
    const unpaginatedList = serviceList
      .filter(({ companyId: serviceCompanyId }) => {
        if (typeof fromCurrentCompany === 'boolean') {
          return fromCurrentCompany ? serviceCompanyId === companyId : serviceCompanyId !== companyId
        }
        return true
      })
      .filter(service => {
        if (!title) return true
        const normalizer = (string) => string.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        const searchKeywords = normalizer(title).split(' ').filter(Boolean)
        const serviceName = normalizer(service.name)
        const serviceCompanyName = normalizer(service.companyName)
        return searchKeywords.every((word) => serviceName.includes(word) || serviceCompanyName.includes(word))
      })
      .sort(
        (a, b) => a.companyId === companyId
          ? -1
          : b.companyId === companyId
            ? 1
            : a.companyName.localeCompare(b.companyName) || a.name.localeCompare(b.name)
      )
    return {
      experiences: unpaginatedList.slice((page - 1) * perPage, page * perPage),
      meta: {
        totalEntries: unpaginatedList.length,
        currentPage: page,
        totalPages: Math.ceil(unpaginatedList.length / perPage)
      }
    }
  }, [companyId])

  const fetchActivities = useCallback(({ title, page, perPage, fromCurrentCompany }) => {
    return fetchSellableList({ type: ACTIVITY_TYPE })
      .then(localFilterAndPagination({ title, page, perPage, fromCurrentCompany }))
  }, [fetchSellableList, localFilterAndPagination])

  const fetchLimber = useCallback(({ title, page, perPage, fromCurrentCompany }) => {
    return fetchSellableList({ type: LIMBER_EXPERIENCE_TYPE })
      .then(localFilterAndPagination({ title, page, perPage, fromCurrentCompany }))
  }, [fetchSellableList, localFilterAndPagination])

  const fetchTransports = useCallback(({ title, page, perPage, fromCurrentCompany }) => {
    return fetchSellableList({ type: TRANSPORT_TYPE })
      .then(localFilterAndPagination({ title, page, perPage, fromCurrentCompany }))
  }, [fetchSellableList, localFilterAndPagination])

  const { resolvePromiseWithCache, invalidateCache } = useCachedPromise({ domain: 'experiencesCache' })

  const fetchOfflineExperiences = useCallback(({ page, perPage, title, offlineProviderId, category }) => {
    const pagination = {
      page,
      per_page: perPage
    }
    const searchParams = {
      title: title?.length > 2 ? title : undefined,
      enabled: true,
      offline_provider_id: offlineProviderId,
      category
    }
    return OfflineExperienceService
      .list({ searchParams, pagination })
      .catch(err => {
        console.error(err)
        if (err.status === 403) {
          addToast('Sem permissão para listar experiências offline', 'warning')
        } else {
          addToast('Erro ao carregar experiências offline')
        }
        return { experiences: [], meta: { totalEntries: 0, currentPage: 1, totalPages: 1 } }
      })
  }, [addToast])

  const fetchCombinedExperiences = useCallback(({ page, perPage, title, fromCurrentCompany }) => {
    const params = {
      title: title?.length ? title : undefined,
      enabled: true,
      page,
      perPage
    }
    if (fromCurrentCompany) {
      return CombinedExperienceService
        .list(params)
        .then(({ experiences, meta }) => ({
          experiences: experiences
            .map(experience => ({
              ...experience, companyId, companyName
            })),
          meta
        }))
        .catch(err => {
          console.error(err)
          if (err.status === 403) {
            addToast('Sem permissão para listar experiências combinadas', 'warning')
          } else {
            addToast('Erro ao carregar experiências combinadas')
          }
          return { experiences: [], meta: { totalEntries: 0, currentPage: 1, totalPages: 1 } }
        })
    } else {
      return CombinedExperienceService
        .listSellable(params)
        .then(({ experiences, meta }) => ({
          experiences: experiences
            .filter(
              (experience) => typeof fromCurrentCompany === 'boolean' ? experience.companyId !== companyId : true
            ),
          meta
        }))
    }
  }, [companyId, companyName, addToast])

  useEffect(() => {
    if (!currentPage) {
      setCurrentPage(1)
      return
    }
    const query = JSON.stringify({ searchQuery, currentPage })
    setIsLoading(query)
    isLoadingRef.current = query

    Promise.all(
      Object
        .entries({
          [ACTIVITY_TYPE]: fetchActivities,
          [TRANSPORT_TYPE]: fetchTransports,
          [OFFLINE_EXPERIENCE_TYPE]: fetchOfflineExperiences,
          [COMBINED_EXPERIENCE_TYPE]: fetchCombinedExperiences,
          [LIMBER_EXPERIENCE_TYPE]: fetchLimber
        })
        .map(([experienceType, promise]) => {
          if (disabledTypesRef.current.includes(experienceType) || currentPage > (totalPages.current?.[experienceType] ?? 1)) {
            return new Promise(resolve => resolve({ experiences: [], meta: { totalPages: totalPages.current[experienceType] } }))
          }
          return resolvePromiseWithCache(
            promise,
            experienceType,
            {
              title: searchQuery,
              page: currentPage,
              perPage: EXPERIENCES_PER_PAGE,
              fromCurrentCompany: experienceType === OFFLINE_EXPERIENCE_TYPE
                ? undefined
                : fromCurrentCompany
            }
          )
        })
    )
      .then(([activities, transports, offline, combined, limber]) => {
        if (isLoadingRef.current && isLoadingRef.current !== query) {
          // another request was made while this one was pending
          return
        }
        const newShuffledPage = faroShuffle(
          activities.experiences,
          transports.experiences,
          limber.experiences,
          combined.experiences,
          offline.experiences
        )
        if (onlyFirstPage) {
          newShuffledPage.length = SINGLE_PAGE_EXPERIENCES
        }
        totalPages.current = {
          [ACTIVITY_TYPE]: activities.meta.totalPages,
          [TRANSPORT_TYPE]: transports.meta.totalPages,
          [OFFLINE_EXPERIENCE_TYPE]: offline.meta.totalPages,
          [COMBINED_EXPERIENCE_TYPE]: combined.meta.totalPages,
          [LIMBER_EXPERIENCE_TYPE]: limber.meta.totalPages
        }
        setExperiencesList(current => currentPage === 1 ? newShuffledPage : current.concat(newShuffledPage))
        setIsLoading(false)
        isLoadingRef.current = false
      })
      .catch((err) => {
        setIsLoading(false)
        isLoadingRef.current = false
        addToast('Erro ao carregar experiências')
        console.error(err)
      })
  }, [
    addToast,
    currentPage,
    fetchActivities,
    fetchTransports,
    fetchOfflineExperiences,
    fetchCombinedExperiences,
    fetchLimber,
    searchQuery,
    totalPages,
    fromCurrentCompany,
    resolvePromiseWithCache,
    onlyFirstPage
  ])

  const filteredExperiencesList = useMemo(
    () => experiencesList.filter(({ type }) => !typesFilter?.length || typesFilter.includes(type)),
    [experiencesList, typesFilter]
  )

  const loadNextPage = useCallback(() => {
    if (onlyFirstPage || isOnLastPage || isLoadingRef.current) return
    setCurrentPage(current => current + 1)
  }, [isOnLastPage, onlyFirstPage])

  const forceUpdate = useCallback(() => {
    if (isLoadingRef.current) {
      return
    }
    invalidateCache()
    invalidateSellableCache()
    clearList()
    setCurrentPage(0)
  }, [clearList, invalidateCache, invalidateSellableCache])

  return {
    setQuery,
    setFilter,
    isLoading,
    loadNextPage,
    isOnLastPage,
    forceUpdate,
    experiencesList: filteredExperiencesList
  }
}

import { io } from 'socket.io-client'

const { REACT_APP_PAY_WS_URL } = process.env
const PAY_API_URL = REACT_APP_PAY_WS_URL || ''

export const socket = io(PAY_API_URL, {
  autoConnect: false,
  transports: ['websocket'],
  auth: { role: 'ticket-office' }
})

import { useRef, useState, useCallback } from 'react'

export const useHover = (timeout = 500) => {
  const timer = useRef()
  const [isVisible, setVisibilityState] = useState(false)

  const showOnHover = useCallback(() => {
    clearTimeout(timer.current)
    setVisibilityState(true)
  }, [])

  const hideOnHover = useCallback(() => {
    timer.current = setTimeout(() => {
      setVisibilityState(false)
    }, timeout)
  }, [timeout])

  return [isVisible, showOnHover, hideOnHover, setVisibilityState]
}

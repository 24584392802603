/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useMemo, useCallback, useState } from 'react'
import { useToast } from '@bonitour/components'
import { useHistory } from 'react-router-dom'

import { useQuery } from 'hooks/useQuery'
import { ReservationService } from 'services/Reservations/Service'
import { BookingForm } from 'containers/Booking/CreationForm/BookingForm'

import { useCompany } from 'contexts/Company'
import { useActivity } from 'contexts/Activity'
import { usePartnerList } from 'hooks/domains/usePartnerList'
import { useBookingTickets } from 'hooks/domains/useBookingTickets'
import { formHasTickets } from './Booking.utils'
import { identity } from '@bonitour/common-functions'
import { COMBINED_EXPERIENCE_TYPE, TRANSPORT_TYPE } from 'constants/activityTypes'
import { useUser } from 'contexts/User'
import { useCombinedExperienceBookingStates } from './hooks/useCombinedExperienceBookingStates'

export const CreateBooking = () => {
  const history = useHistory()
  const { add } = useToast()
  const {
    id: activityId,
    activity: { companyId: activityCompanyId, isPartner = false, type },
    isLoadingActivityFetch = false
  } = useActivity()
  const [{ hour, date = '', email = '', slots, slotsKey, pickupPlaceIds }] = useQuery()
  const { id: companyId } = useCompany()
  const { user } = useUser()

  const isCombinedExperience = useMemo(() => type === COMBINED_EXPERIENCE_TYPE, [type])

  const { ticketsList, isLoading } = useBookingTickets(activityCompanyId, activityId, date, hour, add, type)
  const partnerList = usePartnerList(add)
  const [reservationBase] = useState(() => ({
    reservationType: {
      paymentState: 'responsible'
    },
    reservationHeader: {
      hour,
      date,
      partner: companyId,
      agent: user.id
    },
    initialEmail: email
  }))

  const basePath = useMemo(() => {
    if (type === TRANSPORT_TYPE) {
      return `/transport/${activityId}`
    }

    if (isCombinedExperience) {
      return `/combined-experience/${activityId}`
    }

    return `/activity/${activityId}`
  }, [activityId, type, isCombinedExperience])

  if (type && !isCombinedExperience && !(hour || date) && !isLoadingActivityFetch) {
    history.push(`${basePath}/bookings`)
  }

  if (type && isCombinedExperience && !slots && !isLoadingActivityFetch) {
    history.push(`${basePath}/bookings`)
  }

  const returnToBooking = useCallback(() => {
    const query = isCombinedExperience
      ? `?slots=${encodeURIComponent(slots)}&slotsKey=${slotsKey}${
        pickupPlaceIds ? `&pickupPlaceIds=${encodeURIComponent(pickupPlaceIds)}` : ''
      }`
      : `?date=${date}`
    history.push(`${basePath}/bookings${query}`)
  }, [basePath, date, history, isCombinedExperience, slots, slotsKey, pickupPlaceIds])

  const combinedExperienceData = useCombinedExperienceBookingStates({
    slotsQuery: slots,
    slotsKey,
    returnToBooking
  })

  const [isSubmitting, setIsSubmitting] = useState(false)

  const createRedirect = useCallback((redirectTo = identity) => async data => {
    const isFormValid = isCombinedExperience || formHasTickets(data)
    if (isFormValid) {
      try {
        setIsSubmitting(true)
        const { id } = await ReservationService.create(activityId, activityCompanyId, data, type)
        history.push(redirectTo(id))
      } catch (error) {
        setIsSubmitting(false)
        const { parsedErrors = [] } = error
        parsedErrors.length ? parsedErrors.forEach(errorMessage => add(errorMessage, 'error', 10000)) : add('Houve um erro no cadastro da sua reserva')
      }
    } else {
      add('Adicione ingressos na reserva')
    }
  }, [activityCompanyId, activityId, add, history, isCombinedExperience, type])

  const onFinish = createRedirect((reservationId) => `/reservation/${reservationId}?selectedNav=financeiro`)
  const onContinue = createRedirect((reservationId) => `/reservation/${reservationId}?selectedNav=ingressos`)

  return (
    <BookingForm
      isPartner={isPartner}
      reservationBase={reservationBase}
      ticketsList={ticketsList}
      partnerList={partnerList}
      onFinish={onFinish}
      onContinue={onContinue}
      onBackClick={returnToBooking}
      serviceType={type}
      isCombinedExperience={isCombinedExperience}
      isDisabled={isLoadingActivityFetch}
      combinedExperienceData={combinedExperienceData}
      isSubmitting={isSubmitting}
      isLoadingTickets={isLoading}
    />
  )
}

import { css } from '@emotion/core'

export const experienceGuideRoot = css`
  display: flex;
  margin-right: 1.25rem;
  align-items: center;
`

export const experienceGuideContainer = css`
  display: inline-flex;
  flex-wrap: wrap;
  max-width: 2rem;
  gap: 0.15rem;
  margin-right: 0.625rem;
  justify-content: end;
`

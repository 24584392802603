/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/react'

import { useMemo } from 'react'

import { ServiceCombinedExperienceCardInfo, ServiceCombinedExperienceHeader } from './components'

import { serviceCombinedExperienceCard, ServiceCombinedExperienceListContainer, customBgColor } from './ServiceCombinedExperienceList.style'

import { hexToRgba } from 'utils/hexToRgba'

export const OrderedServiceCombinedExperienceList = ({
  experiences = []
}) => {
  const orderedExperiencesByHour = useMemo(() => experiences.sort((a, b) => {
    const [aStartHour, aStartMinute] = a.startTime.split(':')
    const [bStartHour, bStartMinute] = b.startTime.split(':')

    const aTotalMinutes = parseInt(aStartHour) * 60 + parseInt(aStartMinute)
    const bTotalMinutes = parseInt(bStartHour) * 60 + parseInt(bStartMinute)

    return aTotalMinutes - bTotalMinutes
  }), [experiences])

  return (
    <ServiceCombinedExperienceListContainer>
      {orderedExperiencesByHour.map((experience, index) => (
        <div
          css={[serviceCombinedExperienceCard, customBgColor(hexToRgba(experience.color, 0.15))]}
          key={`combined-experience-card-${experience.id}-${index}`}
          className={'pax__totalizer'}
        >
          <ServiceCombinedExperienceHeader
            color={experience.color}
            experienceInfo={experience}
            index={index}
          />
          <ServiceCombinedExperienceCardInfo
            feeList={experience.feeList}
            startTime={experience.startTime}
            endTime={experience.endTime}
            isPriceTotalizer={false}
            pickupPlaceName={experience.pickupPlaceName}
            index={index}
          />
        </div>
      ))}
    </ServiceCombinedExperienceListContainer>
  )
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { ActivityVacancyForm } from 'containers/ActivityVacancyForm/ActivityVacancyForm'
import { WarningAlert, useToast } from '@bonitour/components'
import { ActivityVacancyService } from 'services/Vacancy/Service'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { useEffect, useState, useCallback, useMemo } from 'react'
import { deepGetOrElse } from 'deep-getter'
import { useActivity } from 'contexts/Activity'

export const EditVacancy = () => {
  const [activityVacancy, setActivityVacancy] = useState({})
  const { add: addToast } = useToast()
  const { id: activityId } = useActivity()
  const { vacancyId } = useParams()
  const history = useHistory()
  const { pathname } = useLocation()

  const returnToEditHref = useMemo(() => {
    if (pathname.includes('/transport')) {
      return `/transport/${activityId}/edit?accordion=4`
    }

    return `/activity/${activityId}/edit?accordion=4`
  }, [activityId, pathname])

  const returnToEdit = useCallback(() =>
    history.push(returnToEditHref), [history, returnToEditHref]
  )

  useEffect(() => {
    ActivityVacancyService.getById(vacancyId).then(setActivityVacancy).catch(() => {
      returnToEdit()
      addToast('Vaga não encontrada')
    })
  }, [addToast, returnToEdit, vacancyId])

  const onValidationError = () => addToast('Preencha corretamente o formulário')

  const onSuccess = async data => {
    try {
      await ActivityVacancyService.update(activityId, vacancyId, data)
      returnToEdit()
      addToast('Vaga atualizada', 'success')
    } catch (errorMessage) {
      if (typeof errorMessage === 'string') {
        return addToast(errorMessage)
      }
      addToast('Houve um erro ao atualizar vaga')
      console.warn('Error updating vacancy', errorMessage)
    }
  }

  const title = deepGetOrElse(activityVacancy, 'activityVacancy.vacancyName', 'Vaga')

  const isLimber = useMemo(() => pathname.includes('/limber-experience'), [pathname])

  return (
    <>
      {isLimber && (
        <WarningAlert>
          <p>
            Não é possível editar vagas de uma Experiência Limber.
          </p>
        </WarningAlert>
      )}
      <ActivityVacancyForm
        title={title}
        activityVacancy={activityVacancy}
        onSuccess={onSuccess}
        onBackClick={returnToEdit}
        onValidationError={onValidationError}
        disabled={isLimber}
      />
    </>
  )
}

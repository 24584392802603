import { BREAK_POINTS, colors } from '@bonitour/components'
import { css } from '@emotion/react'

export const popoverContainerStyle = (size) => css`
  width: ${size}rem;
  flex-direction: column;
  cursor: default;
  user-select: none;
  color: ${colors.gray4};

  @media screen and (max-width: ${BREAK_POINTS.bigPhone}) {
    width: 15rem;
  }

  @media screen and (max-width: ${BREAK_POINTS.smallTablet}) and (max-height: ${BREAK_POINTS.smallTablet}) {
    height: 12rem;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 0;
  }
`

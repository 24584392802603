/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useState, useCallback, useMemo, useEffect } from 'react'
import { GhostPrimaryButton, Dialog, useToast } from '@bonitour/components'
import { useHistory, useParams } from 'react-router-dom'

import { usePartnerList } from 'hooks/domains/usePartnerList'
import { useBookingTickets } from 'hooks/domains/useBookingTickets'
import { BookingLinkingForm } from 'containers/Booking/LinkingForm/LinkingForm'

import { useQuery } from 'hooks/useQuery'
import { useCompany } from 'contexts/Company'
import { useActivity } from 'contexts/Activity'
import { useBookingLink } from './hooks/useBookingLink'
import { useUser } from 'contexts/User'
import { ReservationService } from 'services/Reservations/Service'
import { fiscalDutyDialog } from 'domains/Reservation/Show/Tickets/TicketSummary'
import { OFFLINE_EXPERIENCE_TYPE, TRANSPORT_TYPE } from 'constants/activityTypes'
import { SafeJSONParse } from 'utils/object'

export const LinkBooking = () => {
  const history = useHistory()
  const { add: addToast } = useToast()
  const { reservationCode } = useParams()
  const { user } = useUser()

  const {
    id: activityId,
    activity: { companyId: activityCompanyId, isPartner = false, type, description }
  } = useActivity()

  const limberData = description ? SafeJSONParse(description)?.limber_data : {}

  const [{ hour, date = '', pickupPlaceId = '' }] = useQuery()
  const { id: companyId } = useCompany()

  const partnerList = usePartnerList(addToast)
  const { ticketsList, isLoading } = useBookingTickets(activityCompanyId, activityId, date, hour, addToast, type, pickupPlaceId)

  const { onFinish, onContinue, isSubmitting, experienceData, isCombinedExperience } = useBookingLink()
  const [reservationBase] = useState(() => ({
    reservationHeader: {
      hour,
      date,
      partner: companyId,
      agent: user.id
    }
  }))

  const basePath = useMemo(() => {
    if (type === TRANSPORT_TYPE) {
      return `/transport/${activityId}`
    }

    if (type === OFFLINE_EXPERIENCE_TYPE) {
      return ''
    }

    return `/activity/${activityId}`
  }, [activityId, type])

  const navigateToBack = useCallback(() => {
    if (history.length) {
      history.goBack()
    } else {
      history.push(`${basePath}/bookings?date=${date}`)
    }
  }, [history, basePath, date])

  const [reservation, setReservation] = useState(null)
  useEffect(() => {
    if (!reservation && reservationCode) {
      ReservationService.getByReservationCode(reservationCode)
        .then(setReservation)
    }
  }, [reservation, reservationCode])

  const hasFiscalDuty = useMemo(() => reservation?.fiscalDuty, [reservation])

  return (
    <>
      <BookingLinkingForm
        reservationCode={reservationCode}
        isPartner={isPartner}
        reservationBase={reservationBase}
        ticketsList={ticketsList}
        partnerList={partnerList}
        onFinish={onFinish}
        onContinue={onContinue}
        onBackClick={navigateToBack}
        isSubmitting={isSubmitting}
        isLoadingTickets={isLoading}
        limberData={limberData}
        experienceData={experienceData}
        isCombinedExperience={isCombinedExperience}
      />
      <Dialog
        customContainercss={[fiscalDutyDialog]}
        title='Reserva com NF emitida!'
        isVisible={hasFiscalDuty}
        onClose={navigateToBack}
      >
        <p>
          Não é possível adicionar novos ingressos a esta reserva pois ela possui uma nota fiscal emitida.
        </p>
        <p>
          Para adicionar novos ingressos, é necessário criar uma nova reserva.
        </p>
        <GhostPrimaryButton onClick={navigateToBack}>Voltar ao mapa de vagas</GhostPrimaryButton>
      </Dialog>
    </>
  )
}

export const removeDateFromString = (parsedString = '') => {
  return parsedString.replace(/(0?[1-9]|[12][0-9]|3[01])[/\-.](0?[1-9]|1[012])[/\-.]\d{4}/g, '')
}

export const extractDateFromString = (parsedString = '') => {
  const extractedArray = parsedString.match('[0-9]{2}([-/ .])[0-9]{2}[-/ .][0-9]{4}')

  return extractedArray[0]
}

export const removeSpecialCharactersFromString = (parsedString = '') => parsedString.replace(/[^\w\s]/gi, '')

export const htmlLinksToMarkdown = (parsedString = '') => parsedString
  .replace(/<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1[^>]*>(.*?(?=<\/a>))<\/a>/g, '[$3]($2)')

export const stripHtmlTags = (parsedString = '') => parsedString.replace(/<[^>]*>/g, '')

export const isUUID = (string = '') => /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i.test(string)

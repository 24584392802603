/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { identity, head } from '@bonitour/common-functions'
import { TransactionsProvider } from 'domains/Reservation/Transactions/hooks/useTransactions'
import { useReservation } from 'hooks/context/Reservation'

import { EditReservation } from './Edit/EditReservation'
import { EditAffiliate } from './Edit/EditAffiliate'
import { ActivitiesList } from './List/ActivitiesList'
import { PaymentsList } from './List/PaymentsList'
import { ReservationAccountable } from './ReservationAccountable'
import { usePassenger } from 'domains/Reservation/Passenger/hooks/usePassenger'
import { useAddressLocation } from 'containers/Address/Address.hooks'
import { useToast } from '@bonitour/components'

export const ReservationInfo = ({
  reservationInfo = {},
  reservationId = '',
  reservationInfoErrors = {},
  isCommercialPartner = false,
  editStatusReservationLoading = false,
  onChange = identity,
  onBlur = identity,
  setForm = identity,
  onStatusEdition = identity,
  onPayerInfoEdition = identity,
  reservationTotalPrice = '',
  activities = []
}) => {
  const { add: addToast } = useToast()
  const { sendReservationConfirmEmail, sendEmailLoading, updateReservation, changePayer, changePayerLoading, reservationTickets, fetchReservationTickets } = useReservation()
  const { passengersFound, onSearchPassengers, searchPassengerLoading, onCreateLinkPayer, onEditLinkPayer } = usePassenger()
  const { countries, states, cities, updateAvailableStates, updateAvailableCities } = useAddressLocation(addToast, reservationInfo?.country, reservationInfo?.state)

  const activeActivities = activities.filter((activity) => {
    const { tickets } = activity
    const isActive = tickets.reduce((accumulator, currentValue) => (accumulator && (currentValue.status === 'canceled' || currentValue.status === 'no-show')), head(tickets)) === false
    return isActive
  })

  const isActivitiesActive = activeActivities.length > 0

  return (
    <>
      <EditReservation
        onStatusEdition={onStatusEdition}
        editStatusReservationLoading={editStatusReservationLoading}
        isCommercialPartner={isCommercialPartner}
        reservationInfo={reservationInfo}
        reservationInfoErrors={reservationInfoErrors}
        reservationId={reservationId}
        reservationTotalPrice={reservationTotalPrice}
        onChange={onChange}
        onBlur={onBlur}
        setForm={setForm}
        onPayerInfoEdition={onPayerInfoEdition}
        sendReservationConfirmEmail={sendReservationConfirmEmail}
        sendEmailLoading={sendEmailLoading}
        isActivitiesActive={isActivitiesActive}
        passengersFound={passengersFound?.passengers}
        onSearchPassengers={onSearchPassengers}
        searchPassengerLoading={searchPassengerLoading}
        updateReservation={updateReservation}
        changePayer={changePayer}
        onCreateLinkPayer={onCreateLinkPayer}
        onEditLinkPayer={onEditLinkPayer}
        changePayerLoading={changePayerLoading}
        countries={countries}
        states={states}
        cities={cities}
        updateAvailableStates={updateAvailableStates}
        updateAvailableCities={updateAvailableCities}
      />

      <ReservationAccountable reservationInfo={reservationInfo} />

      <EditAffiliate
        affiliate={reservationInfo?.affiliateCode}
        onChange={onChange}
        reservation={reservationInfo}
        reservationId={reservationId}
        fiscalDuty={reservationInfo?.fiscalDuty}
        reservationTickets={reservationTickets}
        fetchReservationTickets={fetchReservationTickets}
        updateReservation={updateReservation}
      />

      {isActivitiesActive && (
        <ActivitiesList
          activeActivities={activeActivities}
          reservationInfo={reservationInfo}
        />
      )}
      <TransactionsProvider isReservationSummaryInstance isCommercialPartner={isCommercialPartner}>
        <PaymentsList/>
      </TransactionsProvider>
    </>
  )
}

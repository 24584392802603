/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/react'
import { ActivityModalHeader } from '../ActivityModalParts'
import { CombinedExperienceReservationList } from './CombinedExperienceReservationList'
import { GhostButton } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'
import { startReservationButton, startReservationButtonContainer } from './CombinedExperienceReservationListing.style'

export const ReservationListTooltipContent = ({
  experience = null,
  color = null,
  startTime = null,
  endTime = null,
  reservationsDetails = null,
  activityIndex = 0,
  onStartReservation = identity
}) => {
  return (
    <>
      <ActivityModalHeader
        experience={experience}
        color={color}
        startTime={startTime}
        endTime={endTime}
        activityIndex={activityIndex}
      />
      <CombinedExperienceReservationList
        list={reservationsDetails}
        activityId={experience?.id}
        color={color}
      />
      <div css={startReservationButtonContainer}>
        <GhostButton css={startReservationButton(color)} onClick={onStartReservation}>
              Iniciar reserva
        </GhostButton>
      </div>
    </>
  )
}

import { BREAK_POINTS, colors } from '@bonitour/components'
import { css } from '@emotion/core'

export const container = css`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${colors.white};
  box-shadow: 0px -1px 0.375rem #00000029;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0 0.5rem 0;
  z-index: 10;

  &.static__layout {
    position: initial;
    box-shadow: 0;
    padding: 1rem 0 1rem 0;
    border-radius: 0 0 var(--round-element-radius) var(--round-element-radius);
  }

  > .totalizer__container {
    &:first-of-type {
      display: none;
    }
  }

  .row {
    display: flex;
    align-items: center;

    @media (max-width: ${BREAK_POINTS.desktop}) {
      flex-direction: column;
      align-items: flex-end;
      gap: 1rem;
      .totalizer__container {
        margin-right: 0.5rem;
      }
    }
  }

  @media (max-width: ${BREAK_POINTS.tablet}) {
    justify-content: space-around;
    flex-direction: column;

    .totalizer__container {
      &:first-of-type {
        display: inline-flex;
        margin-left: 0.5rem;
      }
    }

    .row {
      align-items: initial;
      margin-top: 0.5rem;
      .totalizer__container {
        display: none;
      }
      .buttons__container {
        margin: 0.25rem 0 0 0;
        padding: 0 0.5rem 0 0.5rem;
        justify-content: space-between;
        flex-direction: row-reverse;
      }
    }
  }
`

export const buttonsContainer = css`
  display: flex;
  gap: 0.75rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
`

export const primaryButton = css`
  border-color: ${colors.indigo1};
  color: ${colors.indigo1};
  display: inline-flex;

  i {
    margin-top: 0.20rem;
    margin-right: 0.5rem;
    display: inline-block;
  }
`

export const actionBarTotalizerContainer = css`
  margin-left: 0.5rem;
  display: inline-flex;
  align-items: center;

  label {
    font-size: 1.1rem;
    color: ${colors.indigo1};
    font-weight: bolder;
  }
`

export const actionBarTotalsContainer = css`
  display: inline-flex;
  font-size: 1rem;

  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.4);
  background-color: ${colors.gray12};
  text-align: left;
  font-weight: bold;
  border-radius: var(--round-element-radius);
  padding-right: 0.5rem;

  label {
    &:first-of-type {
      padding: 0.20rem 0.40rem 0.25rem 0.40rem;
      margin-right: 0.25rem;
      background-color: ${colors.gray2};
      color: ${colors.white};
      border-radius: var(--round-element-radius);
    }
    &:last-of-type {
      padding: 0.25rem 0 0.25rem 0;
      color: ${colors.gray4};
    }
  }
`

/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/react'

import { CombinedExperienceGuideIndicator } from '../CombinedExperienceGuideIndicator'
import { CircleThumbnail, InfoIcon, Label, TableBody, TableBodyCell, TableBodyRow, TableContainer, TableHeader, TableHeaderCell, TableHeaderRow, Tooltip, WarningIcon } from '@bonitour/components'

import { thumbnail, truncateCell, customTooltip, emphasisLabel, truncateColumnCell, tableContainer, totalizarContainer } from './ActionBarErrorLabel.style'
import { TooltipWrapper } from '../TooltipWrapper'
import { useHover } from 'hooks/useHover'

const InvalidActivitiesTable = ({ invalidActivities = [] }) => {
  return (
    <div css={tableContainer}>
      <TableContainer>
        <TableHeader>
          <TableHeaderRow>
            <TableHeaderCell>Atividade</TableHeaderCell>
            <TableHeaderCell css={truncateColumnCell}>Ingressos adicionados</TableHeaderCell>
            <TableHeaderCell css={truncateColumnCell}>Total necessário</TableHeaderCell>
          </TableHeaderRow>
        </TableHeader>
        <TableBody>
          {invalidActivities.map(({
            serviceId,
            serviceName,
            color,
            ticketsAdded,
            requiredTotalTickets
          }, idx) => (
            <TableBodyRow
              key={`${serviceId}#${idx}-action-bar-error`}>
              <TableBodyCell css={truncateCell}>
                <CombinedExperienceGuideIndicator
                  color={color}
                  opacity={1}
                />
                <span>
                  {serviceName}
                </span>
              </TableBodyCell>
              <TableBodyCell>{ticketsAdded}</TableBodyCell>
              <TableBodyCell>{requiredTotalTickets}</TableBodyCell>
            </TableBodyRow>
          ))}
        </TableBody>
      </TableContainer>
    </div>
  )
}

export const ActionBarErrorLabel = ({
  invalidActivities = []
}) => {
  const [isVisible, showOnHover, hideOnHover] = useHover()

  if (!invalidActivities.length) {
    return null
  }

  return (
    <div css={totalizarContainer} className='totalizer__container'>
      <Label css={emphasisLabel}>
        <WarningIcon/>
        Ingressos faltantes
      </Label>
      <TooltipWrapper
        workByHover
        onHoverStart={showOnHover}
        onHoverStop={hideOnHover}
        isVisible={isVisible}
        referenceChildren={(
          <CircleThumbnail size={20} css={thumbnail}>
            <InfoIcon size={10}/>
          </CircleThumbnail>
        )}>
        {isVisible
          ? <Tooltip
            customCss={[customTooltip]}
            position='bottom'
          >
            <InvalidActivitiesTable invalidActivities={invalidActivities}/>
          </Tooltip>
          : null}
      </TooltipWrapper>
    </div>
  )
}

import { css } from '@emotion/core'

export const resetAccordionPadding = css`
  width: max-content;
  margin: 0;
  padding: 0;
  transition: color 2s background-color 2s !important;
  overflow: visible;
  > div {
    margin: 1rem;
    &:first-of-type {
      transition: background-color 2s !important;
    }
  }
`
export const iconAnimation = css`
  animation: spin 2s linear infinite;
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`

export const label = css`
  display: inline-flex;
  align-items: center;
  > div {
    margin-right: 5px;
  }
`

export const customInfoSwatch = css`
  width: 10rem;
  text-align: left;
  .title {
    margin-bottom: 0.25rem;
  }
  .sub-item {
    display: block;
    margin-top: 0.05rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 10rem;
    line-height: 1.10rem;
  }
`

import { refundSchema, paymentSchema, linkSchema, linkMultipontoSchema, pinpadPaymentSchema } from './Transaction.schema'

export const CREATE_PAYMENT_STATE = 'createPayment'
export const EDIT_PAYMENT_STATE = 'editPayment'
export const CREATE_LINK_STATE = 'createLink'
export const CREATE_LINK_MULTIPONTO_STATE = 'createLinkMultiponto'
export const CREATE_LINK_PIX_STATE = 'createLinkPix'
export const CREATE_REFUND_SPLIT_STATE = 'createRefundSplit'
export const CREATE_REFUND_STATE = 'createRefund'
export const EDIT_REFUND_STATE = 'editRefund'
export const CREATE_PINPAD_PAYMENT_STATE = 'createPaymentPinpad'

export const transactionStates = {
  [CREATE_PAYMENT_STATE]: {
    title: 'Registrar pagamento',
    ticketTitle: 'Ingressos a pagar',
    valueLabel: 'Valor pago',
    formSchema: paymentSchema
  },
  [CREATE_PINPAD_PAYMENT_STATE]: {
    title: 'Registrar pagamento com pinpad',
    ticketTitle: 'Ingressos a pagar',
    valueLabel: 'Valor a ser pago',
    formSchema: pinpadPaymentSchema,
    isPinpadPayment: true
  },
  [EDIT_PAYMENT_STATE]: {
    title: 'Editar pagamento',
    ticketTitle: 'Ingressos pagos',
    valueLabel: 'Valor pago',
    formSchema: paymentSchema,
    hasTicketEdition: false,
    selectTickets: true
  },
  [CREATE_LINK_STATE]: {
    title: 'Gerar link de pagamento do viajante',
    ticketTitle: 'Ingressos a pagar',
    formSchema: linkSchema,
    valueLabel: 'Valor a ser pago',
    hasForm: false,
    hasMaxInstallments: true,
    isPaymentLink: true,
    allowPartialTicketValue: false
  },
  [CREATE_LINK_MULTIPONTO_STATE]: {
    title: 'Gerar link de pagamento multiponto',
    ticketTitle: 'Ingressos a pagar',
    formSchema: linkMultipontoSchema,
    valueLabel: 'Valor a ser pago',
    hasForm: false,
    hasMaxInstallments: true,
    isPaymentLink: true,
    isPaymentLinkMultiponto: true,
    allowPartialTicketValue: true
  },
  [CREATE_LINK_PIX_STATE]: {
    title: 'Gerar link de pagamento pix',
    ticketTitle: 'Ingressos a pagar',
    valueLabel: 'Valor a ser pago',
    hasForm: false,
    hasMaxInstallments: false,
    isPaymentLink: true,
    isPaymentLinkMultiponto: false,
    allowPartialTicketValue: true
  },
  [CREATE_REFUND_SPLIT_STATE]: {
    title: 'Reembolsar pagamento',
    ticketTitle: 'Ingressos a reembolsar',
    valueLabel: 'Valor a reembolsar',
    labels: 'pagamento',
    formSchema: refundSchema,
    hasInstallments: false,
    hasAutDoc: false,
    isRefund: true,
    isRefundSplit: true
  },
  [CREATE_REFUND_STATE]: {
    title: 'Reembolsar pagamento',
    ticketTitle: 'Ingressos a reembolsar',
    valueLabel: 'Valor a reembolsar',
    labels: 'pagamento',
    formSchema: refundSchema,
    hasInstallments: false,
    isRefund: true,
    hasAutDoc: false
  },
  [EDIT_REFUND_STATE]: {
    title: 'Editar reembolso',
    ticketTitle: 'Ingressos reembolsados',
    valueLabel: 'valor reembolsado',
    labels: 'pagamento',
    formSchema: refundSchema,
    hasTicketEdition: false,
    hasInstallments: false,
    selectTickets: true,
    hasAutDoc: false,
    isRefund: true
  }
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css, Global } from '@emotion/core'
import { Header } from './Header/Header'
import { Content, Footer } from '@bonitour/components'
import '@bonitour/font/style.css'
import 'reset-css'
import { useLocation } from 'react-router-dom'
import { useMemo } from 'react'

const body = css`
  @import url("https://fonts.googleapis.com/css2?family=Mulish:wght@400;500;700&display=swap");

  body {
    overflow-x: hidden;
    font-family: "Mulish", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

    /* Rounded Square appearence: */
    --round-element-radius: 10px;
    --small-round-element-radius: 6px;
  }
`

const header = css`
  flex: 0 1 auto;
`

export const Layout = ({ children }) => {
  const { pathname } = useLocation()
  const hideHeaderAndFooter = useMemo(
    () => (/\/integrations\/[^/]*\/[^/]*\/register-with-full-form/).test(pathname),
    [pathname]
  )

  return (
    <>
      <Global styles={body} />
      {!hideHeaderAndFooter && <Header css={header} />}
      <Content>
        {children}
      </Content>
      {!hideHeaderAndFooter && <Footer />}
    </>
  )
}

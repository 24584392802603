export const SOCKET_EVENTS = {
  emitPing: 'ping',
  emitRequestAnOrbPayment: 'charge_request_orb',

  onPaymentConfirmationAlertToOrb: 'orb_charge__alert_success',
  onPaymentFailureAlertToOrb: 'orb_charge__alert_failure',
  onAlert: 'alert',
  onDisconnect: 'disconnect',
  onPing: 'pong'
}

import { identity } from '@bonitour/common-functions'
import { useToast } from '@bonitour/components'
import { useActivity } from 'contexts/Activity'
import { useQuery } from 'hooks/useQuery'
import { useEffect, useState, useMemo, useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { ReservationService } from 'services/Reservations/Service'
import { formHasTickets } from '../Booking.utils'
import { COMBINED_EXPERIENCE_TYPE, OFFLINE_EXPERIENCE_TYPE, TRANSPORT_TYPE } from 'constants/activityTypes'
import { useCombinedExperienceBookingStates } from './useCombinedExperienceBookingStates'
import { isUUID } from 'utils/string'

export const useBookingLink = () => {
  const [reservationId, setReservationId] = useState()
  const {
    id: activityId,
    activity: { companyId: activityCompanyId, type },
    isLoadingActivityFetch
  } = useActivity()

  const history = useHistory()
  const [params] = useQuery()
  const { add: addToast } = useToast()
  const { reservationCode } = useParams()

  const { hour, date = '', slots, slotsKey } = params

  const isCombinedExperience = useMemo(() => type === COMBINED_EXPERIENCE_TYPE, [type])

  const basePath = useMemo(() => {
    if (type === TRANSPORT_TYPE) {
      return `/transport/${activityId}`
    }

    if (type === OFFLINE_EXPERIENCE_TYPE) {
      return ''
    }

    return `/activity/${activityId}`
  }, [activityId, type])

  const returnToBooking = useCallback(() => {
    const queryParams = Object.keys(params).reduce((acc, currKey, index) => {
      if (index === 0) {
        acc += `?${currKey}=${params[currKey]}`
        return acc
      }
      acc += `&${currKey}=${params[currKey]}`
      return acc
    }, '')
    history.push(`${basePath}/bookings${queryParams}`)
  }, [basePath, history, params])

  useEffect(() => {
    if (!isUUID(reservationCode)) {
      ReservationService.getByReservationCode(reservationCode)
        .then(({ id }) => setReservationId(id))
        .catch(() => {
          addToast('Reserva não encontrada')
          returnToBooking()
        })
    } else {
      setReservationId(reservationCode)
    }
  }, [reservationCode, addToast, date, history, basePath, returnToBooking])

  useEffect(() => {
    if (!(hour || date) && !isLoadingActivityFetch && type && !isCombinedExperience) {
      returnToBooking()
    }
  }, [hour, date, history, basePath, isLoadingActivityFetch, type, isCombinedExperience, returnToBooking])

  const [isWaitingResponse, setWaitingResponse] = useState(false)

  const combinedExperienceData = useCombinedExperienceBookingStates({
    slotsQuery: slots,
    slotsKey,
    returnToBooking
  })

  const createRedirect = useCallback((redirectTo = identity) => async data => {
    if (isWaitingResponse) {
      return
    }

    const isFormValid = isCombinedExperience || formHasTickets(data)

    if (isFormValid) {
      try {
        setWaitingResponse(true)
        await ReservationService.addTickets(activityId, activityCompanyId, reservationId, data, type)
        addToast('Vinculação realizada com sucesso', 'success')
        setWaitingResponse(false)
        history.push(redirectTo())
      } catch (error) {
        setWaitingResponse(false)
        const { parsedErrors = [] } = error
        parsedErrors.length ? parsedErrors.forEach(errorMessage => addToast(errorMessage, 'error', 10000)) : addToast('Houve um erro no cadastro da sua reserva')
      }
    } else {
      addToast('Adicione ingressos na reserva')
    }
  }, [
    activityCompanyId,
    activityId,
    addToast,
    history,
    isCombinedExperience,
    isWaitingResponse,
    reservationId,
    type
  ])

  const onFinish = createRedirect(() => `/reservation/${reservationId}?selectedNav=financeiro`)
  const onContinue = createRedirect(() => `/reservation/${reservationId}?selectedNav=ingressos`)

  return {
    onFinish,
    onContinue,
    isSubmitting: isWaitingResponse,
    experienceData: combinedExperienceData,
    isCombinedExperience
  }
}

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { identity } from '@bonitour/common-functions'
import { AngleThickRightIcon, BusIcon, CombinedExperienceIcon, HelpIcon, LimberLimIcon, MountainIcon, OfflineExperienceIcon, TooltipOnHover } from '@bonitour/components'
import { useActivity } from 'contexts/Activity'
import { useHistory } from 'react-router-dom'
import { SwatchImage } from 'components/SwatchImage'
import { activityCard, extendedActivityCard, activitiesPageCardImage, title, titleMaxWidth, partnerTitle, activitiesPageCardCompanyInfo, activityName, link, seeMoreIconPosition, labelContainer, titleTooltipContainer, serviceTypeLabel, pointer, serviceTypeLabelTiny, smallCardTitleContainer, tooltipContent, tooltipContentForLeft } from './ActivityCard.style'
import { marginRight } from 'assets/styles/global'
import { memo, useCallback, useMemo } from 'react'
import { ACTIVITY_TYPE, COMBINED_EXPERIENCE_TYPE, OFFLINE_EXPERIENCE_TYPE, TRANSPORT_TYPE, LIMBER_EXPERIENCE_TYPE } from 'constants/activityTypes'

const marginRight10 = marginRight(10)

export const ActivityCard = ({
  activity = {},
  onActivityClick: emitActivityClick = identity,
  subdomain = 'dashboard',
  squareCard,
  hideActionLabel = false,
  hasTitleMaxWidth = true,
  showNameTooltip = false,
  disableLink = false,
  disableOnClick = false,
  email = '',
  hideImage = false,
  hideTypeLabel = false,
  customCss = [],
  ...other
}) => {
  const { push } = useHistory()
  const { id, name, companyName, image, type } = activity
  const { setId } = useActivity()

  const basePath = useMemo(() => {
    switch (type) {
    case TRANSPORT_TYPE:
      return `/transport/${id}`
    case OFFLINE_EXPERIENCE_TYPE:
      return `/offline-experience/${id}`
    case COMBINED_EXPERIENCE_TYPE:
      return `/combined-experience/${id}`
    default:
      return `/activity/${id}`
    }
  }, [id, type])

  const onNavMenuClick = () => {
    setId(id)
    emitActivityClick()
    if (subdomain !== null && !disableLink) {
      push(`${basePath}/${subdomain}${email ? `?email=${email}` : ''}`)
    }
  }

  const companyNameLabel = useMemo(() => {
    return showNameTooltip
      ? <TooltipOnHover css={titleTooltipContainer} text={companyName}>
        <p css={[title, hasTitleMaxWidth && titleMaxWidth, partnerTitle]}>{companyName}</p>
      </TooltipOnHover>
      : <p css={[title, hasTitleMaxWidth && titleMaxWidth, partnerTitle]}>{companyName
      }</p>
  }, [companyName, hasTitleMaxWidth, showNameTooltip])

  const serviceNameLabel = useMemo(() => {
    if (!showNameTooltip) {
      return <p css={[title, hasTitleMaxWidth && titleMaxWidth]}>{name}</p>
    }

    return <TooltipOnHover css={[titleTooltipContainer, hasTitleMaxWidth && tooltipContentForLeft]} text={name}>
      <p css={[title, hasTitleMaxWidth && titleMaxWidth]}>{name}</p>
    </TooltipOnHover>
  }, [hasTitleMaxWidth, name, showNameTooltip])

  const typeLabel = useMemo(() => {
    switch (type) {
    case TRANSPORT_TYPE:
      return 'Transporte'
    case OFFLINE_EXPERIENCE_TYPE:
      return 'Experiência Offline'
    case ACTIVITY_TYPE:
      return 'Atividade'
    case COMBINED_EXPERIENCE_TYPE:
      return 'Experiência Combinada'
    case LIMBER_EXPERIENCE_TYPE:
      return 'Experiência Limber'
    default:
      return 'TIPO DESCONHECIDO'
    }
  }, [type])

  const TypeIcon = useCallback(() => {
    switch (type) {
    case TRANSPORT_TYPE:
      return <BusIcon />
    case OFFLINE_EXPERIENCE_TYPE:
      return <OfflineExperienceIcon />
    case ACTIVITY_TYPE:
      return <MountainIcon />
    case COMBINED_EXPERIENCE_TYPE:
      return <CombinedExperienceIcon />
    case LIMBER_EXPERIENCE_TYPE:
      return <LimberLimIcon />
    default:
      return <HelpIcon />
    }
  }, [type])

  return (
    <div css={[squareCard ? extendedActivityCard : activityCard, !disableOnClick && pointer, ...customCss]} onClick={disableOnClick ? null : onNavMenuClick} {...other}>
      {!hideTypeLabel && (
        <div css={squareCard ? serviceTypeLabel : serviceTypeLabelTiny}>
          <p>{typeLabel}</p>
          <TypeIcon />
        </div>
      )}

      {!hideImage && (<SwatchImage
        css={[marginRight10, squareCard && activitiesPageCardImage]}
        size={30}
        image={image}
        alternativeText={name}
      />)}

      {!squareCard && (
        <div css={smallCardTitleContainer}>
          {serviceNameLabel}
          {companyNameLabel}
        </div>
      )}

      {squareCard && (
        <div css={activitiesPageCardCompanyInfo}>
          <TooltipOnHover text={name} css={tooltipContent}>
            <p css={[title, hasTitleMaxWidth && titleMaxWidth, activityName]}>{name}</p>
          </TooltipOnHover>
          {companyNameLabel}
        </div>
      )}
      {squareCard && !hideActionLabel && (
        <div css={labelContainer}>
          <p css={link}>Ver experiência</p>
          <AngleThickRightIcon css={seeMoreIconPosition} />
        </div>
      )}
    </div>
  )
}

export const MemoizedActivityCard = memo(
  ActivityCard,
  (prevProps, nextProps) =>
    prevProps.activity?.id === nextProps.activity?.id
)

import moment from 'moment'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

export const formatISOTime = (timestring = '', format = 'DD/MM/YY', timezone = true) =>
  moment(timestring).utcOffset(0, timezone).format(format)

dayjs.extend(duration)

export const timeToMinutes = (time) => {
  if (!time) return 0
  const [hours, minutes] = time.split(':')
  return parseInt(hours) * 60 + parseInt(minutes)
}

export const minutesToText = (min) => {
  if (!min) return '0m'

  const duration = dayjs.duration(min, 'minutes')
  const hours = Math.floor(duration.asHours())
  const minutes = duration.minutes()

  if (!hours && !minutes) return '0'

  return `${hours || 0}h` + (minutes ? `${minutes}` : '')
}

export const parseToDuration = (time) => moment.duration(time)

export const calculateTimeDuration = (startTime, duration) => {
  const totalDuration = parseToDuration(startTime)
    .add(parseToDuration(duration))

  return {
    startTime,
    endTime: moment.utc(totalDuration.asMilliseconds()).format('HH:mm')
  }
}

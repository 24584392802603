import { css } from '@emotion/core'
import { colors, flexRowCentered, scrollBar, BREAK_POINTS } from '@bonitour/components'

export const clickable = css`
  cursor: pointer;
`

export const cursorAuto = css`
  cursor: auto;
`

export const infoIconStyle = css`
  margin-left: 5px;
  border: 1px solid black;
  padding: 3px;
  border-radius: 50%;
  height: 10px;
  width: 10px;
`

export const statusIndicator = css`
  display: inline-block;
  height: 10px;
  width: 10px;
  margin-right: 10px;
  border-radius: 50%;
`

export const roundedRightCorners = css`
  border-radius: 0 10px 10px 0;
`

export const roundedLeftCorners = css`
  border-radius: 10px 0 0 10px;
`

export const tableHeaderBase = css`
  height: 70px;
`

export const innerTableHeaderBase = css`
  height: 50px;
`

export const tableHeaderCellBase = css`
  height: 70px;
  padding: 0 0 0 20px;
  font-weight: 500;
  background-color: ${colors.gray12};

  &:last-of-type {
    ${roundedRightCorners};
  }

  &:first-of-type {
    ${roundedLeftCorners};
  }
`

export const innerTableHeaderCellBase = css`
  height: 50px;
  padding: 0 0 0 20px;
  font-weight: 500;
  background-color: ${colors.gray14};

  &:last-of-type {
    ${roundedRightCorners};
  }

  &:first-of-type {
    ${roundedLeftCorners};
  }
`

export const defaultRowHeight = css`
  height: 50px;

  .tooltip-on-hover {
    width: auto;
    min-width: 150px;
    background-color: ${colors.white};
    color: ${colors.gray3};
    border-radius: 5px;
  }
`

export const defaultCellPadding = css`
  padding: 0 0 0 20px;
`

export const valueCell = css`
  display: flex;
  align-items: center;
  span {
    margin-right: 5px;
  }
`

export const resetCellColor = css`
  background-color: ${colors.white} !important;
`

export const arrowOpenIndicator = css`
  transform: rotateZ(90deg);
`

export const openingTransition = css`
  transition: 0.2s;
`

export const margin = (marginTop, marginRight, marginBottom, marginLeft) => css`
  margin: ${marginTop}px ${marginRight}px ${marginBottom}px ${marginLeft}px;
`

export const marginTop = (margin) => css`
  margin-top: ${margin}px;
`

export const marginBottom = (margin) => css`
  margin-bottom: ${margin}px;
`

export const marginLeft = (margin) => css`
  margin-left: ${margin}px;
`

export const marginRight = (margin) => css`
  margin-right: ${margin}px;
`

export const width = (percentage) => css`
  width: ${percentage}%;
`

export const primaryColor = css`
  color: ${colors.primary};
`

export const link = css`
  color: ${colors.primary};
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
`

export const resetLink = css`
  text-decoration: none;
`

export const hidden = css`
  display: none !important;
`

export const backgroundPrimaryVariant = css`
  background-color: ${colors.primaryVariant};
`
export const loadingContainer = css`
  ${flexRowCentered};
  width: 100%;
  margin: 50px 0;
`
export const loadingContainerOverlay = css`
  ${loadingContainer};
  position: absolute;
  inset: 0;
  z-index: 99;
  height: auto;
  background-color: rgba(255, 255, 255, 0.8);
  margin: 0;
`

export const capitalize = css`
  text-transform: capitalize;
`

export const whiteButton = css`
  background-color: ${colors.white};
  color: ${colors.gray3};
  border-color: transparent;
  box-shadow: 0 0 5px 0.02em rgba(0, 0, 0, 0.25);
`

export const cardButton = css`
  width: 100px;
  padding: 10px 20px;
`

export const cardIcon = css`
  width: 22px;
  height: 22px;
  padding: 5px;
  background-color: ${colors.primary};
  color: ${colors.white};
  border-radius: var(--round-element-radius, 100%);
`

export const labelCardButton = css`
  width: 100%;
  cursor: pointer;
`

export const required = css`
  margin-left: 10px;
  color: ${colors.gray4};
  font-weight: 400;
  font-size: 14px;
`
export const alignTrashIconAtEnd = css`
  justify-content: flex-end;

  @media (max-width: ${BREAK_POINTS.tableUp}) {
    position: absolute;
    top: 10px;
    right: 5px;
  }
`

export const circleColor = (color) => css`
  display: inline-flex;
  margin-right: 10px;
  background-color: ${color};
`
export const tableContainer = css`
  max-height: 300px;
  overflow-x: auto;
  ${scrollBar(5)};
`
export const tableXOverflow = css`
  overflow-x: auto;
  ${scrollBar(5)};
`

export const width100 = width(100)

export const mobileButtonContainer = css`
  @media (max-width: ${BREAK_POINTS.tableUp}) {
    flex-direction: column;
  }
`

export const mobileContinueButton = css`
  @media (max-width: ${BREAK_POINTS.tableUp}) {
    margin: 10px 0 0 0;
  }
`

export const mobileBackButton = css`
  @media (max-width: ${BREAK_POINTS.tableUp}) {
    margin: 0;
  }
`

export const positionRelative = css`
  position: relative;
`

export const skeletonCardEffect = css`
  pointer-events: none;
  opacity: 0.8;

  position: relative;

  &, * {
    cursor: default;
  }

  > div:last-of-type {
    display: flex;
  }

  img {
    filter: grayscale(1);
  }

  background-color: ${colors.gray7};
  overflow: hidden;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    transform: translateX(-100%);
    animation: translateBackground 2s infinite;
    content: '';
    background-image: linear-gradient(
      90deg,
      rgba(240, 240, 240, 0) 0,
      rgba(240, 240, 240, 0.25) 15%,
      rgba(240, 240, 240, 0.75) 80%,
      rgba(240, 240, 240, 0)
    );
  }

  @keyframes translateBackground {
    100% {
      transform: translateX(100%);
    }
  }
`

export const fullWSize = css`
  width: 100%;
`

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { HeaderPage } from '@bonitour/components'
import { CombinedExperienceForm } from './form/CombinedExperienceForm'
import { useCallback, useMemo } from 'react'
import { useCombinedExperience } from './hooks/useCombinedExperience'
import { useActivity } from 'contexts/Activity'
import { useBack } from 'hooks/useBack'

const PREVIOUS_PATH = '/activities'

export const CreateCombinedExperience = () => {
  const {
    onSafeBack
  } = useBack()

  const {
    create,
    isLoadingCreation,
    isSuccessCreation
  } = useCombinedExperience()

  const {
    loading: isLoadingExperienceList,
    companyActivities = [],
    partnerActivities = []
  } = useActivity()

  const experienceList = useMemo(
    () => companyActivities.concat(partnerActivities),
    [companyActivities, partnerActivities]
  )

  const onBackClick = useCallback(() => onSafeBack(PREVIOUS_PATH), [onSafeBack])

  return <>
    <HeaderPage
      title={'Experiência combinada'}
      onBack={onBackClick}
    />
    <CombinedExperienceForm
      isLoading={isLoadingCreation}
      onSubmit={create(PREVIOUS_PATH)}
      isLoadingExperienceList={isLoadingExperienceList}
      experienceList={experienceList}
      isSuccessSubmit={isSuccessCreation}
    />
  </>
}

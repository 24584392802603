/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useToast } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'

import { ActivityBaseForm } from 'containers/ActivityBaseForm/ActivityBaseForm'
import { ActivityService } from 'services/Activity/Service'
import { useActivity } from 'contexts/Activity'
import { useActivityForm } from 'containers/ActivityBaseForm/useActivityForm'

export const EditActivityInfo = ({
  activityBase = {},
  onActivityUpdated = identity,
  onNextStep = identity,
  disabled = false
}) => {
  const { id: activityId, updateList } = useActivity()
  const { add: addToast } = useToast()

  const onSuccess = async data => {
    try {
      await ActivityService.updateMainActivity(activityId, data)
      addToast('Atividade atualizada', 'success')
      onActivityUpdated()
      updateList(true)
      onNextStep()
    } catch (error) {
      addToast('Ocorreu um erro na atualização da sua atividade')
    }
  }

  const activityFormProps = useActivityForm({ activityBase, onSuccess, addToast })

  return (
    <ActivityBaseForm
      {...activityFormProps}
      disabled={disabled}
    />
  )
}

import { AuthStore } from 'services/Auth/Store'
import { NewPayService } from './Service'

function Api () {
  async function authenticate () {
    const orbAuthKey = AuthStore.authKey

    return NewPayService.post('/api/v1/socket/session/ticket-office', {}, {
      headers: { Authorization: `${orbAuthKey}` },
      withCredentials: true
    })
  }

  return { authenticate }
}

export const NewPayAuthApi = Api()

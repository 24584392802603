import { parseAttribute, getImageUrl } from 'services/Orb/Service'
import { parseActivityAbout } from 'core/parsers/activityAbout'
import { parseActivityRules } from 'core/parsers/activityRules'
import { parseAddress } from 'core/parsers/address'
import { activityIdParser } from 'core/parsers/activityId'
import { activityBasicInfoParser } from 'core/parsers/activityBasicInfo'
import { activityComplementaryInfoParser } from 'core/parsers/ActivityComplementaryInfo'
import { unmaskPhone } from '@bonitour/common-functions'

const extractInfo = ({
  title: name,
  id,
  image,
  is_partner: isPartner,
  company: { id: companyId, name: companyName } = {},
  restrictions,
  description,
  service_time: duration,
  type,
  offline_category: category
}) => ({
  name,
  id,
  image: getImageUrl(image),
  isPartner,
  companyId,
  companyName,
  description,
  minAge: restrictions?.min_age,
  duration,
  type: type || '',
  category
})

export const parseActivityList = (list = []) => list.map(parseAttribute(extractInfo))

export const phoneParser = (phone = {}) => {
  const { title, number } = phone
  const telephone = unmaskPhone(number || '')
  const phoneNumberWithDDI = telephone?.length <= 10 ? `55${telephone}` : telephone

  return {
    title,
    number: phoneNumberWithDDI
  }
}

const activityParser = (activity = {}) => {
  const activityRegistry = activityBasicInfoParser(activity)
  const activityId = activityIdParser(activity)
  const activityAbout = parseActivityAbout(activity)
  const activityRules = parseActivityRules(activity)
  const activityComplementaryInfo = activityComplementaryInfoParser(activity)
  const addressInfo = parseAddress(activity)
  const { contacts, type } = activity
  const { emails, phones } = contacts
  const telephones = phones.map(phone => phoneParser(phone))

  return {
    activityBase: {
      activityId,
      activityRegistry,
      addressInfo,
      emails,
      phones: telephones
    },
    activityAbout: {
      activityAbout,
      activityRules
    },
    activityComplementaryInfo,
    type
  }
}

export const parseActivity = activity => parseAttribute(activityParser)(activity)

const restrictionsParser = (activity = {}) => {
  const activityRules = parseActivityRules(activity)
  const { restrictions } = activityRules

  return {
    ...restrictions
  }
}

export const ActivityParser = {
  instance ({ service = {} }) {
    return activityParser(service)
  },
  restrictions ({ service = {} }) {
    return restrictionsParser(service)
  },
  list ({ services = [] }) {
    return services.map(extractInfo)
  },
  partners ({ companies = [] }) {
    return companies.map(ActivityParser.getPartner)
  },
  getPartner (company = {}) {
    const {
      company_id: companyId,
      company_name: companyName,
      services
    } = company

    return {
      companyId,
      companyName,
      services
    }
  }
}

/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/react'

import { useCallback } from 'react'

import { Column, Dialog, GhostPrimaryButton, Input, InputFormGroup, LinkIcon, useToast } from '@bonitour/components'
import { column } from './ActionBarDialog.style'
import { identity } from '@bonitour/common-functions'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { LinkReservationSchema } from './schemas/LinkReservationSchema'

export const ActionBarDialog = ({
  setIsVisible = identity,
  redirectToLinkBooking = identity,
  isVisible = false
}) => {
  const {
    add: addToast
  } = useToast()

  const {
    register,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm({
    resolver: yupResolver(LinkReservationSchema, {
      stripUnknown: true,
      abortEarly: false
    })
  })

  const toggleCloseDialog = useCallback(() => {
    setIsVisible((current) => !current)
  }, [setIsVisible])

  const onSuccess = useCallback((data = {}) => {
    redirectToLinkBooking(data.reservationCode)
  }, [redirectToLinkBooking])

  const onFail = useCallback(() => {
    addToast('Preencha corretamente o código da reserva')
  }, [addToast])

  const onSubmit = handleSubmit(onSuccess, onFail)

  return (
    <Dialog title='Código da reserva' isVisible={isVisible} onClose={toggleCloseDialog}>
      <Column phone={12} desktop={12} css={column}>
        <InputFormGroup label='Código da reserva' errorMessage={errors?.reservationCode?.message}>
          <Input
            type='text'
            placeholder='Ex: O2WSZLLFCPNI'
            onChangeWholeEvent
            {...register('reservationCode')}
          />
        </InputFormGroup>
        <GhostPrimaryButton onClick={onSubmit}>
          <LinkIcon/>
          Avançar
        </GhostPrimaryButton>
      </Column>
    </Dialog>
  )
}

/* eslint-disable react/jsx-closing-tag-location */
/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { identity } from '@bonitour/common-functions'
import { Modal, Logo, CloseIcon } from '@bonitour/components'
import { jsx } from '@emotion/core'
import logoOrb from 'assets/svgs/logoOrb.svg'
import { useEffect, useMemo, useState } from 'react'
import { PinpadSocketProvider, usePinpadSocket } from './hooks/usePinpadSocket'
import { NewPayAuthApi } from 'services/NewPay/NewPayAuthApi'
import { useTransactions } from './hooks/useTransactions'
import { pinpadContainer } from './PinpadTransaction.styles'
import { useFeatureFlag } from 'contexts/Feature'

const PinpadIcon = () => {
  return (
    <div aria-hidden="true" className="border pinpad rounded">
      <div className="screen border rounded"/>
      <div className='numpad'>
        {Array.from({ length: 12 }).map((_, i) => (
          <div className="button border rounded" key={`pin_btn_${i}`}/>
        ))}
      </div>
    </div>
  )
}

const Dash = () => <div className="dash" />

const ConectionAnimation = ({ label = '', failed = false }) => {
  return (
    <div className='connection_animation' data-has-failed={failed}>
      <b>{label}</b>
      <div className={failed ? 'failed' : 'dashes'}>
        {failed
          ? (Array.from({ length: 4 }).map((_, i) => <CloseIcon key={`dash_${i}`} />))
          : (Array.from({ length: 5 }).map((_, i) => <Dash key={`dash_${i}`} />))
        }
      </div>
    </div>
  )
}

const PinpadTransactionModalWithCtx = ({ isVisible, onclose }) => {
  const [isPaymentRequested, setIsPaymentRequested] = useState(false)
  const [isAuthenticating, setIsAuthenticating] = useState(true)
  const [pinpadEnabled] = useFeatureFlag('orb_pinpad_integration')
  const [hasFailedAuthentication, setHasFailedAuthentication] = useState(false)
  const { pinpadData } = useTransactions()
  const {
    connect,
    disconnect,
    isConnected,
    emitPaymentRequest,
    hasPaymentError
  } = usePinpadSocket()

  useEffect(() => {
    if (!pinpadEnabled) {
      setIsAuthenticating(false)
      setHasFailedAuthentication(true)
      return
    }

    NewPayAuthApi.authenticate()
      .then(() => connect())
      .catch(() => setHasFailedAuthentication(true))
      .finally(() => setIsAuthenticating(false))
    return () => disconnect()
  }, [connect, disconnect, pinpadEnabled])

  useEffect(() => {
    if (isConnected && !isPaymentRequested) {
      emitPaymentRequest(pinpadData)
      setIsPaymentRequested(true)
    }
  }, [emitPaymentRequest, isConnected, isPaymentRequested, pinpadData])

  const animationLabel = useMemo(() => {
    if (isAuthenticating) return 'Conectando...'
    if (hasFailedAuthentication) return 'Erro ao conectar'
    if (hasPaymentError) return 'Erro ao pagar'
    return 'Aguardando Pagamento'
  }, [hasFailedAuthentication, isAuthenticating, hasPaymentError])

  return (
    <Modal isVisible={isVisible} title={'Processando Pagamento'} onCloseClick={onclose}>
      <div css={pinpadContainer}>
        <Logo src={logoOrb} />
        <ConectionAnimation
          failed={hasFailedAuthentication || hasPaymentError}
          label={animationLabel}
        />
        <PinpadIcon />
      </div>
    </Modal>
  )
}

export const PinpadTransactionModal = ({
  isVisible,
  onclose = identity
}) => {
  return (
    <PinpadSocketProvider>
      <PinpadTransactionModalWithCtx
        isVisible={isVisible}
        onclose={onclose}
      />
    </PinpadSocketProvider>
  )
}

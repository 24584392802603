import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { BREAK_POINTS, colors } from '@bonitour/components'
import { colorCycle } from 'constants/combinedExperience'

export const serviceCombinedExperienceCard = css`
  display: flex;
  flex-direction: column;
  background-color: ${colors.gray10};
  border-radius: var(--round-element-radius);
  box-shadow: 0 0.25rem 0.375rem rgba(0,0,0,0.1);
  width: 13.438rem;
  position: relative;
  &.fake-has-price-size {
    margin-bottom: 2.15rem;
  }
  &.pax__totalizer {
    box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.25);
  }
`

export const customBgColor = (hexColor) => css`
  background-color: ${hexColor};
`

export const serviceCombinedExperienceCardItem = (colorIndex, color) => css`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  background-color: ${color || colorCycle[colorIndex % colorCycle.length]};
  border-radius: var(--round-element-radius);
  padding: 0.5rem;
  overflow: hidden;
`

export const ServiceCombinedExperienceListContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.625rem;
  gap: 0.625rem;
  flex-wrap: wrap;
  @media screen and (max-width: ${BREAK_POINTS.tableUp}){
    justify-content: center;
  }
`

export const serviceCombinedExperienceTitle = css`
  color: white;
  font-weight: bold;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  padding-left: 0.625rem;
  font-size: 1.0625rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: max-content;
  overflow-wrap: break-word;
  word-break: break-word;
  line-height: 1.125;
  text-align: initial !important;
`

export const serviceCombinedExperienceSubtitle = css`
  ${serviceCombinedExperienceTitle};
  font-size: 0.6875rem;
  font-weight: 500;
  -webkit-line-clamp: 1;
`

export const serviceCombinedExperienceCardImage = css`
  object-fit: cover;
  border-radius: var(--round-element-radius);
  height: 3.65rem;
  width: 3.65rem;
  flex-shrink: 0;
`

export const serviceCombinedExperienceCardInfo = css`
  position: relative;
  display: flex;
  padding: 0.25rem;
  border-radius: var(--round-element-radius);
  align-items: center;
  justify-content: center;
  &.pax__totalizer {
    margin: 5px;
  }
`

export const priceAndParticipants = css`
  background-color: ${colors.white};
  display: flex;
  align-items: center;
  font-weight: bold;
  border-radius: var(--round-element-radius);
  color: ${colors.gray4};
  width: 9.5rem;
  gap: 0.313rem;
  > span {
    padding-left: 0.25rem;
  }
`

export const participantCount = (colorIndex) => css`
  width: 2.10rem;
  height: 1.563rem;
  font-size: 1.15rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: ${colorCycle[colorIndex % colorCycle.length]};
  border-radius: 0.35rem;
  position: relative;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.4);

  span {
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: inherit;
    z-index: 1;
    box-shadow: inset 0 0 0.25rem 0.5px ${colorCycle[colorIndex % colorCycle.length]};
  }
`
export const paxSummaryContainer = css`
  display: flex;
  place-items: flex-start;
  flex-direction: column;
  width: 100%;

  .time__label, .pickup__label {
    margin-bottom: 0.375rem;
  }

  .pickup__label {
    display: flex;
    flex-direction: column;
    &::before {
      content: 'Local de Embarque:';
      font-size: 0.625rem;
      text-transform: uppercase;
      font-weight: 700;
      opacity: 0.5;
    }
  }
`

/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'

import { useCallback, useEffect, useMemo } from 'react'

import { useFeatureFlag } from 'contexts/Feature'

import { CustomCommissions } from './CustomCommissions/CustomCommissions'

import { ButtonSelector, H3, HorizontalInputFormGroup, InfoTooltip, Input, InputWithSuffix, ToggleInputGroup } from '@bonitour/components'

import { grayColor, marginBottom10, marginBottom20, marginTop20, sellViaAllyContainer, width160, inverseRow, comissionFormGroupContainer } from './TradeAgreement.styles'

export const TradeAgreement = ({
  formUtils = {},
  tradeAgreement = {},
  errors = {},
  savedCustomCommissions = [],
  isLoading = false,
  isGroupEditable = false,
  isFormDisabled = false,
  canDisableAllForm = false,
  submitCount = 0
}) => {
  const {
    onChange,
    register
  } = formUtils

  const [isNetValueEnabled] = useFeatureFlag('orb-net-value')
  const [isSaleViaAllayEnabled] = useFeatureFlag('orb-sell-via-ally')

  const {
    name = '',
    commissioning = '',
    sellViaAlly = true,
    netFeeEnabled
  } = tradeAgreement

  const toggleSellViaAlly = useCallback(() => onChange('tradeAgreement.sellViaAlly')(!sellViaAlly), [onChange, sellViaAlly])
  const toggleAmountType = useCallback(() => onChange('tradeAgreement.netFeeEnabled')(!netFeeEnabled), [onChange, netFeeEnabled])

  const amountType = useMemo(() => netFeeEnabled ? 'fixed' : 'percentage', [netFeeEnabled])

  const isNonCommissionGroup = (name === 'no_commission_group' || name === 'Meu grupo')

  useEffect(() => {
    if (isNonCommissionGroup) onChange('tradeAgreement.name')('Meu grupo')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name])

  useEffect(() => {
    if (netFeeEnabled) {
      const commissioningValue = String(tradeAgreement.commissioning || '')
      const comissionValueNumberStr = commissioningValue.replace('.', ',').replace(/[^\d,]/g, '')
      const integerPart = comissionValueNumberStr.split(',')[0]
      const decimalPart = (comissionValueNumberStr.split(',')[1] || '').slice(0, 2)
      const formattedCommissioning = `${integerPart}${decimalPart ? `,${decimalPart}` : ''}`
      if (!new RegExp(`^${formattedCommissioning}[.,]$`).test(commissioningValue)) {
        onChange('tradeAgreement.commissioning')(formattedCommissioning)
      }
    }
  }, [netFeeEnabled, tradeAgreement.commissioning, onChange])

  return (
    <div>
      <>
        <H3 css={marginBottom20}>Nome do grupo</H3>
        <HorizontalInputFormGroup errorMessage={errors?.name?.message}>
          <Input
            disabled={(!isGroupEditable || isFormDisabled || isLoading) || canDisableAllForm}
            onChangeWholeEvent={true}
            {...register('tradeAgreement.name')}
          />
        </HorizontalInputFormGroup>
      </>

      <H3 css={[marginTop20, marginBottom10]}>Comissionamento padrão</H3>
      <HorizontalInputFormGroup css={comissionFormGroupContainer} errorMessage={errors?.commissioning?.message}>
        <div className='comission_input__ctn'>
          <span css={grayColor}>A {netFeeEnabled ? 'comissão' : 'porcentagem'} será de</span>

          <InputWithSuffix
            disabled={(!isGroupEditable || isFormDisabled || isLoading || canDisableAllForm)}
            css={[width160]}
            onChangeWholeEvent={true}
            {...register('tradeAgreement.commissioning')}
          >{netFeeEnabled ? 'R$' : '%'}
          </InputWithSuffix>
        </div>

        {isNetValueEnabled
          ? (
            <div className='amount_type__container'>
              <ButtonSelector
                selected={amountType}
                onSelect={() => toggleAmountType()}
                disabled={(!isGroupEditable || isFormDisabled || isLoading || canDisableAllForm)}
                options={[
                  {
                    label: 'Porcentagem',
                    value: 'percentage'
                  },
                  {
                    label: 'Valor fixo',
                    value: 'fixed'
                  }
                ]}
              />
              <InfoTooltip text={'Se o valor da comissão for superior ao valor do ingresso, a comissão será zerada'} size={200}/>
            </div>
          )
          : <div>&nbsp;</div>}
      </HorizontalInputFormGroup>

      {!isLoading
        ? (
          <CustomCommissions
            initialData={savedCustomCommissions}
            onChange={onChange('customCommissions')}
            baseCommission={commissioning}
            isNetFeeDefault={netFeeEnabled}
            isDisabled={isFormDisabled || isLoading || canDisableAllForm}
            isGroupEditable={isGroupEditable}
            submitCount={submitCount}
          />
        )
        : null}

      {
        isSaleViaAllayEnabled
          ? (
            <>
              <H3 css={[marginTop20, marginBottom20]}>Venda no aplicativo Ally</H3>
              <div css={sellViaAllyContainer}>
                <HorizontalInputFormGroup errorMessage={errors?.sellViaAlly?.message}>
                  <ToggleInputGroup
                    disabled={isFormDisabled || isLoading || canDisableAllForm}
                    onChange={toggleSellViaAlly}
                    checked={sellViaAlly}
                    canEmitToggleEventOfRow={true}
                    customCss={[inverseRow]}>
                      Permitir a venda no aplicativo Ally
                  </ToggleInputGroup>
                </HorizontalInputFormGroup>
              </div>
            </>
          )
          : null}
    </div>
  )
}

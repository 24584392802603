/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable indent */
/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core'
import {
  Modal,
  Row,
  Column,
  Card,
  H4,
  useToast,
  flexColumn,
  LoadingAnimation,
  InputFormGroup,
  Label,
  InputWithSuffix,
  Dialog,
  Button,
  GhostButton
} from '@bonitour/components'
import { identity, formatMoney, head } from '@bonitour/common-functions'
import { TicketList } from './TicketList'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { TransactionForm } from './TransactionForm'
import { loadingContainerOverlay } from 'assets/styles/global'
import {
  amountCard,
  amountLabel,
  amountStyle,
  limitContainer,
  reduceAllTicketsButton,
  reduceAllTicketsDialog
} from './TransactionModal.style'
import { DetailTicketList } from 'containers/Reservation/Payment/DetailTicketList'
import { useFeatureFlag } from 'contexts/Feature'
import { parseDecimal } from 'utils/number'

const TicketListFeatureWrapper = ({
  allowPartialTicketValue,
  isSelectable,
  tickets,
  ticketsIds,
  onTicketIdsChange,
  isLoading,
  isRefund,
  isEditing,
  isPayment,
  isPaymentLink,
  isRefundSplit
}) => {
  const [isPartialMultipontoValuesEnabled] = useFeatureFlag('orb-partial-multiponto-values')

  const unpaidTickets = useMemo(
    () => tickets.filter(({ ticketPaymentBalance }) => parseFloat(head(ticketPaymentBalance)?.remainingTicketValue) > 0),
    [tickets]
  )

  const showDetailTicketList = useMemo(() => {
    if (
      isPayment ||
      (isRefund && !isEditing) ||
      (allowPartialTicketValue && isPartialMultipontoValuesEnabled)
    ) {
      return true
    }

    return false
  }, [isEditing, isPartialMultipontoValuesEnabled, isPayment, allowPartialTicketValue, isRefund])

  const visibleTickets = useMemo(
    () => (isPayment || isPaymentLink) ? unpaidTickets : tickets,
    [isPayment, isPaymentLink, unpaidTickets, tickets]
  )

  if (showDetailTicketList) {
    return (
      <DetailTicketList
        isSelectable={isSelectable}
        tickets={visibleTickets}
        ticketsIds={ticketsIds}
        onTicketIdsChange={onTicketIdsChange}
        loading={isLoading}
        isRefund={isRefund}
        isEditing={isEditing}
        isRefundSplit={isRefundSplit}
      />
    )
  } else {
    return (
      <TicketList
        isSelectable={isSelectable}
        tickets={visibleTickets}
        ticketsIds={ticketsIds}
        onTicketIdsChange={onTicketIdsChange}
        loading={isLoading}
        isRefund={isRefund}
        isEditing={isEditing}
      />
    )
  }
}

export const TransactionModal = ({
  view: {
    title = '',
    ticketTitle = '',
    valueLabel = '',
    isVisible = false,
    hasForm = true,
    hasAutDoc = true,
    isButtonEnabled = true,
    hasTicketEdition = true,
    hasInstallments = true,
    selectTickets = false,
    hasMaxInstallments = false,
    isPaymentLink = false,
    isPaymentLinkMultiponto = false,
    allowPartialTicketValue = false,
    isPinpadPayment = false,
    isRefund,
    isRefundSplit = false,
    formSchema
  } = {},
  loading = false,
  transactionFormLoading = false,
  isLinkCreateLoading = false,
  isPayment = false,
  isEditing = false,
  tickets = [],
  ticketsIds = [],
  paymentTotalValue = 0,
  formData,
  setTicketsIds = identity,
  onCloseClick = identity,
  onFormSuccess = identity,
  hasFiscalDuty = false
}) => {
  const { add: addToast } = useToast()

  const [percentage, setPercentage] = useState(null)
  const isPercentageDialogOpen = useMemo(() => percentage !== null, [percentage])
  const openPercentageDialog = useCallback(() => setPercentage(100), [setPercentage])
  const closePercentageDialog = useCallback(() => setPercentage(null), [setPercentage])
  const isPercentageButtonEnabled = useMemo(() => Boolean(ticketsIds?.length), [ticketsIds])

  const [isAllowedTicketOverpayment] = useFeatureFlag('allow-ticket-overpayment')

  const applyPercentage = useCallback(() => {
    setTicketsIds(
      ticketsIds.map((ticketId) => ({
        ...ticketId,
        amount: Number(parseDecimal(((ticketId.amount * percentage) / 100)))
      }))
    )
    closePercentageDialog()
  }, [ticketsIds, setTicketsIds, percentage, closePercentageDialog])

  useEffect(() => {
    if (selectTickets) {
      setTicketsIds(tickets.map(({ id }) => id))
    } else {
      setTicketsIds([])
    }
  }, [tickets, selectTickets, setTicketsIds])

  const onSuccesValidation = useCallback(async (data) => {
    if (ticketsIds.length <= 0) {
      return addToast('Informe ao menos um ticket para continuar')
    }
    onFormSuccess({ ...data, ticketsIds, value: paymentTotalValue })
  }, [addToast, onFormSuccess, paymentTotalValue, ticketsIds])

  const onFailedValidation = () => addToast('Preencha o formulário corretamente')

  const hasValidTotalValue = useMemo(() => {
    if (isEditing && isRefund) return true

    return paymentTotalValue > 0
  }, [isEditing, isRefund, paymentTotalValue])

  const onChangePercentage = useCallback((value) => {
    if (typeof value === 'number') {
      return setPercentage(value)
    }

    const containsDecimalPoint = value.includes('.')
    const decimalPointIndex = value.indexOf('.')

    if (containsDecimalPoint) {
      const newValue = value.slice(0, decimalPointIndex + 3)
      return setPercentage(newValue)
    }

    setPercentage(value)
  }, [])

  return (
    <Modal title={title} isVisible={isVisible} maxWidth="1368px" onCloseClick={onCloseClick}>
      <Row>
        <H4>{ticketTitle}</H4>
        <Column phone={12} css={limitContainer}>
          {loading || isLinkCreateLoading
            ? (
              <LoadingAnimation css={loadingContainerOverlay} />
            )
            : null
          }
              <TicketListFeatureWrapper
                isSelectable={hasTicketEdition}
                tickets={tickets}
                ticketsIds={ticketsIds}
                onTicketIdsChange={setTicketsIds}
                loading={transactionFormLoading}
                isRefund={isRefund}
                isRefundSplit={isRefundSplit}
                isEditing={isEditing}
                allowPartialTicketValue={allowPartialTicketValue}
                isLoading={loading}
                isPaymentLink={isPaymentLink}
                isPayment={isPayment}
              />
        </Column>
      </Row>

      <Row>
        <Column desktop={9} tablet={9} phone={12}>
          <Card css={amountCard}>
            <div css={flexColumn}>
              <p css={amountLabel}>{valueLabel}</p>
              <span css={[amountStyle]}>{formatMoney(paymentTotalValue || 0)}</span>
            </div>
          </Card>
        </Column>
        <Column desktop={3} tablet={3} phone={12}>
          {isPayment
            ? (
              <GhostButton
                type="button"
                onClick={openPercentageDialog}
                disabled={!isPercentageButtonEnabled}
                customCss={[reduceAllTicketsButton]}
              >
                Aplicar porcentagem
              </GhostButton>
            )
            : null}
        </Column>
      </Row>

      <Dialog
        title="Aplicar porcentagem"
        isVisible={isPercentageDialogOpen}
        customContainercss={reduceAllTicketsDialog}
        onClose={closePercentageDialog}
      >
        <p>Esta ação irá alterar o valor a pagar de todos os ingressos atualmente selecionados.</p>
        <p>A porcentagem inserida será aplicada nos valores atuais.</p>
        <InputFormGroup>
          <Label>Porcentagem a ser aplicada:</Label>
          <InputWithSuffix
            type="number"
            min="0"
            max={isAllowedTicketOverpayment && !hasFiscalDuty ? '1000' : '100'}
            value={percentage}
            onChange={onChangePercentage}
          >
            <span>%</span>
          </InputWithSuffix>
        </InputFormGroup>
        <Button type="button" onClick={applyPercentage}>
          Aplicar
        </Button>
        <GhostButton type="button" onClick={closePercentageDialog}>
          Cancelar
        </GhostButton>
      </Dialog>

      <TransactionForm
        formData={formData}
        formSchema={formSchema}
        onCloseClick={onCloseClick}
        onSuccesValidation={onSuccesValidation}
        onFailedValidation={onFailedValidation}
        hasForm={hasForm}
        hasAutDoc={hasAutDoc}
        isRefund={isRefund}
        hasInstallments={hasInstallments}
        hasMaxInstallments={hasMaxInstallments}
        isPaymentLink={isPaymentLink}
        isPaymentLinkMultiponto={isPaymentLinkMultiponto}
        isDisabled={!isButtonEnabled || !hasValidTotalValue}
        transactionFormLoading={transactionFormLoading}
        isLinkCreateLoading={isLinkCreateLoading}
        isPinpadPayment={isPinpadPayment}
      />
    </Modal>
  )
}

import { css } from '@emotion/core'
import { BREAK_POINTS, colors, flexCenteredSpaceBetweenRow, flexRowCentered } from '@bonitour/components'

export const relative = css`
  position: relative;
`

export const container = css`
  ${flexCenteredSpaceBetweenRow};

  @media (max-width: ${BREAK_POINTS.smallTablet}) {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const selectActivityContainer = css`
  ${flexRowCentered};

  @media (max-width: ${BREAK_POINTS.smallTablet}) {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const vacancyTableContainer = css`
  padding: 30px;
  margin: 20px 0;
  position: relative;
  border-radius: 10px;
  border: 1px solid ${colors.gray11};
  background-color: ${colors.white};
`

export const limberSectionWrapper = css`
  max-width: 19rem;
`

export const selectorPadding = css`
  padding-left: 15px;

  @media (max-width: ${BREAK_POINTS.smallTablet}) {
    padding: 0;
    margin: 10px 0;
  }
`

export const datePickerContainer = css`
  align-self: flex-end;

  @media (max-width: ${BREAK_POINTS.smallTablet}) {
    align-self: flex-start;
  }
`

export const transportDetails = css`
  padding: 40px 0 20px;
  color: ${colors.gray4};
  font-size: 12px;
  line-height: 1.666;
  font-weight: 300;
  
  b {
    color: ${colors.gray3};
    font-weight: 700;
  }

  i {
    font-size: 26px;
  }

  > div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    gap: 1ch;
    margin-bottom: 10px;
  }
`

export const configIcon = css`
  margin-left: 20px;
  font-size: 28px;
  cursor: pointer;
  color: ${colors.primary};
`

export const buttonCurrentDate = css`
  margin-right: 10px;
`

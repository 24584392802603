/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { useState, useMemo, useCallback, useEffect } from 'react'
import { jsx } from '@emotion/core'
import {
  GhostButton,
  Button,
  useToast,
  LoadingAnimation
} from '@bonitour/components'
import { handleOfflineTicketFormSchema, offlineExperienceTicketBase } from './OfflineExperience.schema'
import { OfflineTicketService } from 'services/OfflineTicket/Service'
import { OfflineExperienceService } from 'services/OfflineExperience/Service'
import { hidden, loadingContainer } from 'assets/styles/global'
import {
  offlineExperienceTicketFormContainer,
  buttonsContainer,
  label
} from './OfflineExperiencesTicketForm.style'
import { useForm } from '@bonitour/app-functions'
import { identity } from '@bonitour/common-functions'
import { OfflineTicketForm } from './OfflineTicketForm'
import { ActivityCard } from 'containers/Activity/ActivityCard'
import { useUser } from 'contexts/User'
import { ConfirmationModal } from '../../components/ConfirmationModal'

const ExperienceHeader = ({ experience }) => {
  return (
    <div>
      <p css={label}>Experiência offline:</p>
      <div>
        <ActivityCard
          activity={experience}
          hasTitleMaxWidth={false}
          disableLink
          disableOnClick
          hideTypeLabel
        />
      </div>
    </div>
  )
}

export const OfflineExperiencesTicketForm = ({
  onClose = identity,
  onTicketUpdate = identity,
  reservationInfo = {},
  reservationId = null,
  experienceId = null
}) => {
  const [offlineExperience, setOfflineExperience] = useState()
  const [isLoadingExperience, setIsLoadingExperience] = useState(false)

  const { add: addToast } = useToast()
  const { user } = useUser()

  const schema = useMemo(() => handleOfflineTicketFormSchema({
    category: offlineExperience?.category,
    isEditingTicket: false
  }), [offlineExperience])

  const formBase = useMemo(() => ({
    ...offlineExperienceTicketBase, reservationHeader: {
      agent: user.id
    }
  }), [user])

  const {
    form,
    errors,
    utils: {
      onInputBlur,
      onInputChange
    },
    onSubmit
  } = useForm(formBase, schema)

  useEffect(() => {
    if (!experienceId || experienceId === offlineExperience?.id) {
      return
    }

    setIsLoadingExperience(true)
    OfflineExperienceService.get(experienceId).then(({ offlineExperienceBase }) => {
      const { id, category, name, files } = offlineExperienceBase.serviceId
      setOfflineExperience({ id, category, name, image: files?.[0]?.src || undefined })
      onInputChange('serviceId')(offlineExperienceBase.serviceId.id)
    }).catch(() => {
      addToast('Ocorreu um erro ao buscar pela experiência offline')
    }).finally(() => setIsLoadingExperience(false))
  }, [addToast, experienceId, offlineExperience, onInputChange])

  const [isOpenedDialog, setIsOpenedDialog] = useState(false)
  const [isLoadingAddTicket, setIsLoadingAddTicket] = useState(false)

  const toggleDialog = useCallback(() =>
    setIsOpenedDialog(curr => !curr), [])

  const handleAddTicket = useCallback(() => {
    toggleDialog()
    setIsLoadingAddTicket(true)

    const data = {
      ...form,
      reservationId,
      payerParams: {
        payerId: reservationInfo?.payerId,
        email: reservationInfo?.email
      }
    }
    OfflineTicketService.create(data, offlineExperience?.category).then(() => {
      addToast('Ticket criado com sucesso', 'success')
      onTicketUpdate()
      onClose()
    }).catch(({ data }) => {
      onClose()

      if (data?.extra_data?.slot.includes('must be after the current date')) {
        return addToast('A data de checkin precisa ser maior que a data atual')
      }

      addToast('Ocorreu um erro ao criar o ticket')
    }).finally(() => setIsLoadingAddTicket(false))
  }, [
    form,
    reservationId,
    reservationInfo,
    offlineExperience,
    addToast,
    onClose,
    onTicketUpdate,
    toggleDialog
  ])

  const onAddClick = useCallback(() => {
    toggleDialog()
  }, [toggleDialog])

  const onError = useCallback(() => {
    addToast('Ocorreu um erro ao criar o ticket, confira o formulário.')
  }, [addToast])

  const onFormSubmit = onSubmit(onAddClick, onError)

  const TicketForm = (
    <OfflineTicketForm
      form={form}
      errors={errors}
      onInputBlur={onInputBlur}
      onInputChange={onInputChange}
      category={offlineExperience?.category}
      disabled={isOpenedDialog}
      isOpenConfirmationModal={isOpenedDialog}
    />
  )

  const hasSeveralTickets = useMemo(() => form.count > 1, [form])

  return (
    <>
      <div
        css={!isOpenedDialog ? offlineExperienceTicketFormContainer : hidden}
        className={offlineExperience ? 'with_overflow' : ''}
      >
        {isLoadingExperience || isLoadingAddTicket
          ? (
            <LoadingAnimation css={loadingContainer} />
          )
          : (
            <>
              {offlineExperience
                ? (
                  <>
                    <ExperienceHeader experience={offlineExperience} />

                    {TicketForm}

                    <div css={buttonsContainer}>
                      <GhostButton onClick={onClose}>Cancelar</GhostButton>
                      <Button onClick={onFormSubmit}>Adicionar</Button>
                    </div>
                  </>
                )
                : (
                  <>
                    {TicketForm}
                  </>
                )}
            </>
          )}
      </div>
      <ConfirmationModal
        handleAddTicket={handleAddTicket}
        onClose={() => setIsOpenedDialog(false)}
        isVisible={isOpenedDialog}
        isPluralizedLabels={hasSeveralTickets}>
        {TicketForm}
      </ConfirmationModal>
    </>)
}

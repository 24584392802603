/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { ActivityAbout } from './ActivityAbout'
import { Button, GhostButton, flexRow } from '@bonitour/components'
import { marginTop, marginRight, mobileButtonContainer, mobileBackButton, mobileContinueButton } from 'assets/styles/global'
import { identity } from '@bonitour/common-functions'
import { useForm } from '@bonitour/app-functions'
import { ActivityRules } from './ActivityRules'
import { activityAboutSchema } from 'core/schemas/activityAbout'
import { object } from 'yup'
import { useMemo } from 'react'

const marginTop50 = marginTop(50)
const marginRight20 = marginRight(20)

export const ActivityAboutForm = ({
  activityAbout: resetActivityAbout = {},
  onBackClick: emitBackClickEvent = identity,
  onSuccess: emitSuccessEvent = identity,
  onValidationError: emitValidationError = identity,
  categories = [],
  infrastructureList = {},
  isImporting = false,
  disabled = false
}) => {
  const schema = useMemo(() => ({
    activityAbout: object(activityAboutSchema(Boolean(categories?.length))).required()
  }), [categories])
  const {
    form,
    errors,
    onSubmit,
    utils: { onInputBlur, onInputChange }
  } = useForm(resetActivityAbout, schema)
  const onClick = onSubmit(emitSuccessEvent, emitValidationError)

  return (
    <>
      <ActivityAbout
        activityAbout={form.activityAbout}
        infrastructureList={infrastructureList}
        activityAboutErrors={errors.activityAbout}
        categories={categories}
        onChange={onInputChange}
        onInputBlur={onInputBlur}
      />
      <ActivityRules activityRules={form.activityRules} onChange={onInputChange} />
      <div css={[flexRow, marginTop50, mobileButtonContainer]}>
        <GhostButton css={[marginRight20, mobileBackButton]} onClick={emitBackClickEvent}>Voltar</GhostButton>
        <Button css={mobileContinueButton} onClick={disabled ? identity : onClick} disabled={disabled}>
          {isImporting ? 'Continuar' : 'Salvar e continuar'}
        </Button>
      </div>
    </>
  )
}

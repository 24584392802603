import { css } from '@emotion/core'
import { colors } from '@bonitour/components'

export const dateButtonContainer = css`
  position: absolute;
  top: 65px;
  left: 145px;
  z-index: 1;
  padding: 0;
  background-color: transparent;
`

export const dateButton = css`
  z-index: 1;
  outline: none;
  width: 55px;
  height: 55px;
  padding: 0;
  font-size: 20px;
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0px 2px 6px #00000034;
  color: ${colors.gray4};

  &:hover {
    background-color: rgba(255, 255, 255, 1);
  }
`

export const dateButtonRightPosition = css`
  left: auto;
  right: 20px;
`

import { FeeListParser, FeePricesToTickets, parseFee } from './Parser'
import { feeModel } from './Model'
import { orbCore } from 'services/Orb/Service'
import { CompanyStore } from 'core/store/Company'

export const FeeService = {
  get (activityId) {
    return orbCore.feeDomain.get(activityId).then(FeeListParser)
  },

  getById (id, activityId) {
    return orbCore.feeDomain.getById(id, activityId).then(parseFee)
  },

  remove: (activityId, feeId) => {
    return orbCore.feeDomain.remove(activityId, feeId)
  },

  create (activityId, data) {
    const companyId = CompanyStore.getId()
    return orbCore.feeDomain.create(feeModel(activityId, companyId, data), companyId)
  },

  update (activityId, feeId, data) {
    const companyId = CompanyStore.getId()
    return orbCore.feeDomain.update(feeId, feeModel(activityId, companyId, data), companyId)
  },

  getPrices (companyId, activityId, date, hour, type, pickupPlaceId) {
    return orbCore.feeDomain.getPrices(companyId, activityId, date, hour, type, pickupPlaceId).then(FeePricesToTickets(type))
  }
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useEffect, useState, useCallback, useMemo } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { WarningAlert, useToast } from '@bonitour/components'
import { deepGetOrElse } from 'deep-getter'

import { ActivityFeeForm } from 'containers/ActivityFeeForm/ActivityFeeForm'
import { FeeService } from 'services/Fee/Service'
import { ActivityService } from 'services/Activity/Service'
import { TransportService } from 'services/Transport/Service'

import { useActivity } from 'contexts/Activity'

export const EditFee = () => {
  const history = useHistory()
  const { feeId } = useParams()
  const { pathname } = useLocation()
  const { add: addToast } = useToast()
  const { id: activityId } = useActivity()

  const [fetchedRestriction, setFetchedRestriction] = useState('')
  const [activityFee, setActivityFee] = useState(false)
  const [activityRestrictions, setActivityRestrictions] = useState({})

  const isTransportFee = useMemo(() => {
    return pathname.includes('/transport')
  }, [pathname])

  const onValidationError = (errors) => {
    const isMinAgeError = errors?.feePaxTypes?.map(error => Object.keys(error).includes('minAge')).includes(true)
    if (isMinAgeError) {
      const errorMessage = errors?.feePaxTypes[0].minAge?.message
      addToast(errorMessage)
    } else {
      addToast('Preencha corretamente o formulário')
    }
  }

  const returnToEditHref = useMemo(() => {
    return `/${isTransportFee ? 'transport' : 'activity'}/${activityId}/edit?accordion=5`
  }, [activityId, isTransportFee])

  const returnToEdit = useCallback(() =>
    history.push(returnToEditHref), [history, returnToEditHref]
  )

  useEffect(() => {
    if (!pathname) return
    if (fetchedRestriction === activityId) return

    const restrictionPromise = isTransportFee
      ? TransportService.getRestrictions(activityId)
      : ActivityService.getRestrictions(activityId)

    restrictionPromise
      .then(setActivityRestrictions)
      .finally(() => setFetchedRestriction(activityId))
  }, [activityId, fetchedRestriction, isTransportFee, pathname])

  useEffect(() => {
    FeeService.getById(feeId, activityId).then(setActivityFee).catch(() => {
      returnToEdit()
      addToast('Tarifário não encontrado')
    })
  }, [feeId, activityId, returnToEdit, addToast])

  const onSuccess = async data => {
    if (data.feePaxTypes.length) {
      try {
        await FeeService.update(activityId, feeId, data)
        returnToEdit()
        addToast('Tarifa atualizada com sucesso', 'success')
      } catch (error) {
        addToast(error)
      }
    } else {
      addToast('Adicione uma tipificação de Pax')
    }
  }

  const title = deepGetOrElse(activityFee, 'feeBasic.name', '')

  const isLimber = useMemo(() => pathname.includes('/limber-experience'), [pathname])

  return (
    <>
      {isLimber && (
        <WarningAlert>
          <p>
            Não é possível editar tarifário de uma Experiência Limber.
          </p>
        </WarningAlert>
      )}
      <ActivityFeeForm
        title={title}
        activityFee={activityFee}
        activityMinAge={activityRestrictions.minAge}
        onSuccess={onSuccess}
        onBackClick={returnToEdit}
        onValidationError={onValidationError}
        disabled={isLimber}
      />
    </>
  )
}

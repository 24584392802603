/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { useHistory } from 'react-router-dom'
import { useMemo } from 'react'
import { jsx } from '@emotion/core'
import { HeaderPage, Row, TabContainer, UnderlineNavContainer, UnderlineNavItem, UnderlineTabPane } from '@bonitour/components'
import { useQuery } from 'hooks/useQuery'
import { container, underlineNavContainer, tabPaneContainer } from './ActivitiesPage.style'
import NewExperienceButton from './NewExperienceButton'
import { ExperienceListSelector } from 'containers/Activity/ListSelector/ListSelector'
import { INTERNAL_EXPERIENCE_TYPES, EXTERNAL_EXPERIENCE_TYPES, OFFLINE_EXPERIENCE_TYPE } from 'constants/activityTypes'
import { useSellableList } from 'contexts/Activity'

const ActivitiesPage = () => {
  const { push } = useHistory()
  const backToDashboard = () => push('/app')

  const [{
    selectedNav = 'myExperiences'
  }] = useQuery()

  const setSelectedNav = (value) => push(`?selectedNav=${value}`)

  const { hasIntegrations } = useSellableList()
  const hasAnyIntegration = useMemo(() => Object.values(hasIntegrations).some(Boolean), [hasIntegrations])

  return (
    <>
      <HeaderPage onBack={backToDashboard} title='Experiências' />

      <div css={container}>
        <NewExperienceButton />
      </div>
      <Row>
        <UnderlineNavContainer css={underlineNavContainer} selectedNav={selectedNav} onChange={setSelectedNav}>
          {[
            <UnderlineNavItem navId='myExperiences' key='myExperiences'>Minhas experiências</UnderlineNavItem>,
            <UnderlineNavItem navId='partnerExperiences' key='partnerExperiences'>Experiências de parceiros</UnderlineNavItem>,
            <UnderlineNavItem navId='externalExperiences' key='offlineExperiences'>Experiências {hasAnyIntegration ? 'externas' : 'offline'}</UnderlineNavItem>
          ]}
        </UnderlineNavContainer>

        <TabContainer activeTab={selectedNav}>
          <UnderlineTabPane css={[tabPaneContainer]} tabId='myExperiences'>
            <ExperienceListSelector
              subdomain='dashboard'
              disabledTypes={EXTERNAL_EXPERIENCE_TYPES}
              disableScroll
              showActionLabel
              fromCurrentCompany={true}
            />
          </UnderlineTabPane>
          <UnderlineTabPane css={[tabPaneContainer]} tabId='partnerExperiences'>
            <ExperienceListSelector
              subdomain='dashboard'
              disabledTypes={OFFLINE_EXPERIENCE_TYPE}
              disableScroll
              showActionLabel
              fromCurrentCompany={false}
            />
          </UnderlineTabPane>
          <UnderlineTabPane css={[tabPaneContainer]} tabId='externalExperiences'>
            <ExperienceListSelector
              subdomain='dashboard'
              disabledTypes={INTERNAL_EXPERIENCE_TYPES}
              disableScroll
              showActionLabel
            />
          </UnderlineTabPane>
        </TabContainer>
      </Row>
    </>
  )
}

export default ActivitiesPage

import { MetaParser } from './Meta'

export const TicketParser = {
  ticketList ({ ticket_to_uses = [] }) {
    return ticket_to_uses.map(ticket => {
      return {
        id: ticket.id,
        companyId: ticket.company_id,
        company: ticket.company_name,
        client: ticket.payer_name,
        reservationId: ticket.reservation_id,
        activity: ticket.service_name,
        serviceType: ticket.service_type,
        hour: ticket.slot.slice(11, 16),
        day: ticket.slot.slice(0, 10),
        state: ticket.state,
        code: ticket.ticket_code,
        type: ticket.pax_type,
        price: ticket.price
      }
    })
  },
  ticketPrint (ticket) {
    const {
      url,
      success
    } = ticket ?? {}

    return {
      url,
      success
    }
  },
  ticketPrintVoucherTuris (data) {
    return data
  },
  single: (ticket) => {
    const {
      id,
      activity_hour: activityHour,
      ticket_code: ticketCode,
      activity,
      traveller,
      pax_type: paxType,
      reservation_id: reservationId,
      status
    } = ticket

    return {
      id,
      activityHour,
      ticketCode,
      activity,
      traveller,
      paxType,
      reservationId,
      status
    }
  },
  list: ({ meta, tickets = [] }) => {
    return {
      vouchers: tickets ? tickets.map(TicketParser.single) : [],
      meta: MetaParser.meta(meta)
    }
  },
  totals: ({ tickets_report_totalizers: totalizers = {} }) => {
    const {
      integration_tickets_amount: ticketsAmount,
      integration_tickets_price: priceAmount,
      integration_tickets_group_by_pax_name: paxTypes
    } = totalizers

    return {
      ticketsAmount,
      priceAmount,
      paxTypes
    }
  }
}

/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/react'

import { forwardRef } from 'react'

import { Manager, Reference } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'

export const TooltipWrapper = forwardRef((props, ref) => {
  const {
    toggleVisibility = identity,
    onHoverStart = identity,
    onHoverStop = identity,
    isVisible = false,
    referenceChildren = null,
    workByHover = false,
    customCss,
    children
  } = props

  return (
    <div
      ref={ref}
      onMouseEnter={workByHover ? onHoverStart : undefined} onMouseLeave={workByHover ? onHoverStop : undefined}>
      <Manager>
        <Reference>
          {({ ref: referenceRef }) => (
            <div ref={referenceRef} onClick={workByHover ? undefined : toggleVisibility} css={customCss}>
              {referenceChildren}
            </div>
          )}
        </Reference>
        {
          isVisible
            ? children
            : null
        }
      </Manager>
    </div>
  )
})

/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/react'

import { container, timeContainer, durationContainer } from './ActivityModalHeader.style'
import { ClockIcon } from '@bonitour/components'
import { ServiceCombinedExperienceList } from '../ServiceCombinedExperienceList'
import { useMemo } from 'react'

export const ActivityModalHeader = ({
  color = '',
  startTime = '',
  endTime = '',
  experience = {},
  activityIndex = 0
}) => {
  const experienceData = useMemo(() => {
    experience.index = activityIndex
    return experience
  }, [activityIndex, experience])
  return (
    <div css={container}>
      <div>
        <ServiceCombinedExperienceList
          experiences={[experienceData]}
          color={color}/>
      </div>
      <div css={durationContainer}>
        <ClockIcon />
        <div css={timeContainer}>
          <span className='bold'>{startTime}</span>
          <span>até</span>
          <span className='bold'>{endTime}</span>
        </div>
      </div>
    </div>
  )
}

export const salesReportExportOptions = [
  { label: 'Data da experiência', value: 'slot' },
  { label: 'Valor do ticket', value: 'price' },
  { label: 'Status', value: 'state' },
  { label: 'Valor da comissão', value: 'commission_value' },
  { label: 'Valor comissão offline', value: 'commission_value_offline' },
  { label: 'Valor net offline', value: 'offline_net_price' },
  { label: 'Valor extra', value: 'extra_value' },
  { label: 'Valor guia', value: 'guide_value' },
  { label: 'Código do Ticket', value: 'ticket_code' },
  { label: 'No-show', value: 'no_show' },
  { label: 'Data limite', value: 'deadline_date' },
  { label: 'Invoice', value: 'invoiced' },
  { label: 'Canal de venda', value: 'selling_channel' },
  { label: 'Canal de pagamento', value: 'payment_channel' },
  { label: 'Data de venda', value: 'sale_date' },
  { label: 'Nome do Pagador', value: 'payer_name' },
  { label: 'E-mail do Pagador', value: 'payer_email' },
  { label: 'Codigo de Reserva', value: 'reservation_code' },
  { label: 'Passageiro', value: 'passenger_name' },
  { label: 'Empresa', value: 'company_name' },
  { label: 'Experiência', value: 'service_title' },
  { label: 'Viajante', value: 'passager_name' },
  { label: 'Agente responsável', value: 'user_name' }
]

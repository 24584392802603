/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { ActionBar } from './components'

const pendingTicketsData = [
  {
    requiredTotalTickets: 'Ao menos 1 ingresso',
    ticketsAdded: 0,
    color: '#7E7EFC',
    serviceName: 'W H I T E S P A C E A C T I V I T Y',
    serviceId: '67ab5c63-8b19-4beb-b18d-f1197b67a7cc'
  },
  {
    requiredTotalTickets: 'Ao menos 1 ingresso',
    ticketsAdded: 0,
    color: '#56E39F',
    serviceName: 'biigggeesstnameeewithoutspaceeeeeeeeeeeeeeeeeeeeee',
    serviceId: '8da4ed01-d7b3-4426-b16a-8729dac39c8d'
  },
  {
    requiredTotalTickets: 'Ao menos 1 ingresso',
    ticketsAdded: 0,
    color: '#6AB1CE',
    serviceName: 'ACTIVITY WITH BIGGEST NAME FOR TEST',
    serviceId: '3de410dc-15e7-4738-ac04-8e23ec5a131b'
  },
  {
    requiredTotalTickets: 'Ao menos 1 ingresso',
    ticketsAdded: 0,
    color: '#FFC164',
    serviceName: 'Bondin',
    serviceId: '44525864-bb0e-437f-8b78-ce24a5815cac'
  },
  {
    requiredTotalTickets: 'Ao menos 1 ingresso',
    ticketsAdded: 0,
    color: '#C48686',
    serviceName: 'A arvore',
    serviceId: 'a80c9c45-0e5b-4f77-bd93-6d0ad4fd08b4'
  },
  {
    requiredTotalTickets: 'Ao menos 1 ingresso',
    ticketsAdded: 0,
    color: '#6D75AD',
    serviceName: 'Dançarinos anônimos',
    serviceId: 'a690b077-0a39-42ab-93e5-dedb5b777c2e'
  },
  {
    requiredTotalTickets: 'Ao menos 1 ingresso',
    ticketsAdded: 0,
    color: '#B69B89',
    serviceName: 'Atividade 2077',
    serviceId: 'ec33d3c5-e9e7-472e-88be-8c67025fa9bf'
  },
  {
    requiredTotalTickets: 'Ao menos 1 ingresso',
    ticketsAdded: 0,
    color: '#59C9A5',
    serviceName: 'Bluft',
    serviceId: '620e2d66-3d47-450a-84e5-4cd11575dc1b'
  }
]

export const ActionBarCombinedExperienceTest = () => {
  return <ActionBar isEnabledReservationMode getInvalidActivities={() => pendingTicketsData}/>
}

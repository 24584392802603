/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { ActivityComplementaryInfoForm } from 'containers/ActivityComplementaryInfoForm/ActivityComplementaryInfoForm'
import { useToast } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'
import { ActivityService } from 'services/Activity/Service'
import { ActivityAdditionInformationService } from 'services/Activity/ActivityAdditionInformation/Service'
import { useEffect, useMemo, useState } from 'react'

export const ActivityComplementaryInfo = ({
  activityId = '',
  activityComplementaryInfo = {},
  onActivityUpdated = identity,
  onBackStep = identity,
  onNextStep = identity,
  isImporting = false,
  addToast: onAddToast,
  disabled = false
}) => {
  const [paymentsTypeList, setPaymentsTypeList] = useState([])
  const [whatToTakeList, setWhatToTakeList] = useState([])
  const [whatIsIncludedList, setWhatIsIncludedList] = useState([])
  const { add } = useToast()
  const addToast = useMemo(() => onAddToast || add, [add, onAddToast])

  const onValidationError = () => {
    addToast('Preencha corretamente o formulário')
  }

  const onSuccess = async data => {
    try {
      if (!isImporting) {
        await ActivityService.updateActivityComplementaryInfo(data, activityId)
      }
      onActivityUpdated(data)
      onNextStep()
    } catch (error) {
      addToast('Ocorreu um erro ao adicionar as informações')
    }
  }

  useEffect(() => {
    ActivityAdditionInformationService.list({ type: 'activity' }).then(({ whatToTakeList, whatIsIncludedList, paymentsTypeList }) => {
      whatToTakeList && setWhatToTakeList(whatToTakeList)
      whatIsIncludedList && setWhatIsIncludedList(whatIsIncludedList)
      paymentsTypeList && setPaymentsTypeList(paymentsTypeList)
    })
  }, [])

  return (
    <ActivityComplementaryInfoForm
      activityComplementaryInfo={activityComplementaryInfo}
      paymentsTypeList={paymentsTypeList}
      whatToTakeList={whatToTakeList}
      whatIsIncludedList={whatIsIncludedList}
      onBackClick={onBackStep}
      onSuccess={onSuccess}
      onValidationError={onValidationError}
      isImporting={isImporting}
      disabled={disabled}
    />
  )
}

/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/react'

import { AsteriskIcon, PlusIcon, TooltipOnHover } from '@bonitour/components'
import { customTooltip, optionalIndication } from './OptionalIndication.style'

export const OptionalIndication = ({
  isOptional = false,
  index = 0,
  optionalLabel = 'Opcional',
  requiredLabel = 'Obrigatório',
  customCss = []
}) => {
  return (
    <label css={[optionalIndication(index), ...customCss]} className={'optional_service_indication ' + (isOptional ? 'optional' : 'required')}>
      <TooltipOnHover
        text={isOptional ? optionalLabel : requiredLabel}
        tooltipStyle={[customTooltip]}>
        {isOptional
          ? <PlusIcon/>
          : <AsteriskIcon/>}
      </TooltipOnHover>
    </label>
  )
}
